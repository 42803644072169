import React from "react";
import { call, put, all, takeEvery } from "redux-saga/effects";
import { handleApiErrors } from "../../commons/errors/apiErrors";
import { ErrorComponent } from "../../commons/errors/errors";
import { toastr } from "react-redux-toastr";
import {
	configurationControlModal,
	configurationsGetError,
	configurationsGetSuccess,
	configurationsHistoryGetError,
	configurationsHistoryGetSuccess, configurationUpdateError, configurationUpdateSuccess
} from "./actions";
import { auditsClassGetRequesting } from "../audit/actions";
import {
	CONFIGURATION_UPDATE_REQUESTING,
	CONFIGURATIONS_GET_REQUESTING,
	CONFIGURATIONS_HISTORY_GET_REQUESTING
} from "./constants";

const baseUrl = `${process.env.REACT_APP_API_URL}/configurations`;

const showError = (error) => {
	let toastOptions = {
		component: (
			<ErrorComponent message={error}/>
		)
	};
	toastr.error("Error", toastOptions);
};

const configurationsGetApi = () => {
	return fetch(`${baseUrl}`, {
		method: "GET"
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* configurationsGetFlow() {
	try {
		const configurations = yield call(configurationsGetApi);
		yield put(configurationsGetSuccess(configurations));
	} catch (error) {
		yield put(configurationsGetError(error));
	}
}

const configurationsHistoryApi = (token) => {
	return fetch(`${baseUrl}/history`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`
		}
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* configurationsHistoryFlow(action) {
	try {
		const { token } = action;
		const configurations = yield call(configurationsHistoryApi, token);
		yield put(configurationsHistoryGetSuccess(configurations));
	} catch (error) {
		yield put(configurationsHistoryGetError(error));
	}
}

const configurationUpdateApi = (token, values) => {
	let body = {
		value: values.valor
	};
	return fetch(`${baseUrl}/${values.id}`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`
		},
		body: JSON.stringify(body)
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* configurationUpdateFlow(action) {
	try {
		const { token, values } = action;
		const configuration = yield call(configurationUpdateApi, token, values);
		yield put(configurationUpdateSuccess(configuration));
		yield put(configurationControlModal("editModal"));
		toastr.success("Configuración actualizada", "La configuración fue actualizada con exito.");
		yield put(auditsClassGetRequesting(token, "configuration"));
	} catch (error) {
		yield put(configurationUpdateError(error));
		showError(error);
	}
}

function* configurationWatcher() {
	yield all([
		takeEvery(CONFIGURATIONS_GET_REQUESTING, configurationsGetFlow),
		takeEvery(CONFIGURATIONS_HISTORY_GET_REQUESTING, configurationsHistoryFlow),
		takeEvery(CONFIGURATION_UPDATE_REQUESTING, configurationUpdateFlow)
	]);
}

export default configurationWatcher;