import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/core/SvgIcon/SvgIcon";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import notificationsStyle from "./../../../assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
import Slide from "@material-ui/core/Slide";
// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
// core components
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import userProfileStyles from "../../../assets/jss/material-dashboard-pro-react/views/userProfileCompanyStyles";
import { connect } from "react-redux";
import { Form, reduxForm, Field, reset} from "redux-form";
import { customInputText } from "../../../components/inputs/typeText";
import GridItem from "../../../components/formQuote/client";
import { customInputSelect } from "../../../components/inputs/typeSelect";
import {clientUpdateRequesting} from "../../../redux/client/actions";
import {customInputDate} from "../../../components/inputs/inputDate";
import {legalPersonUpdateRequesting} from '../../../redux/legalPerson/actions';
import {naturalPersonUpdateRequesting} from '../../../redux/naturalPerson/actions';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

class InfoPerson extends React.Component{

	constructor(props){
		super(props);
	}

	handleSubmit = (values) => {
		const {
			client: { token },
			clients: {client},
			legalPersonUpdateRequesting,
			naturalPersonUpdateRequesting,
			legalPerson,
			naturalPerson
		} = this.props;
		if (legalPerson.person.hasOwnProperty("id"))
			legalPersonUpdateRequesting(token,values,client.id,legalPerson.person.id);
		if (naturalPerson.person.hasOwnProperty("id"))
			naturalPersonUpdateRequesting(token,values,client.id,naturalPerson.person.id);
	}

	render(){
		const {
			classes,
			open,
			controlModal,
			naturalPerson,
			legalPerson,
			handleSubmit
		} = this.props;
		console.log(this.props);

		return <Dialog
			classes={{
				root: classes.center + " " + classes.modalRoot,
				paper: classes.modal
			}}
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => controlModal("personModal")}
			aria-labelledby="classic-modal-slide-title"
			aria-describedby="classic-modal-slide-description"
		>
			<CardHeader color="primary" icon>
				<CardIcon color="primary">
					<MailOutline/>
				</CardIcon>
			</CardHeader>

			<DialogTitle
				id="classic-modal-slide-title"
				disableTypography
				className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key="close"
					aria-label="Close"
					color="transparent"
					onClick={() => controlModal("personModal")}
				>
					<Close className={classes.modalClose}/>
				</Button>
				<h4 className={classes.modalTitle}>Información personal</h4>
			</DialogTitle>
			{legalPerson.person.hasOwnProperty("id") && 
			<Form onSubmit={handleSubmit(this.handleSubmit)}>
			<DialogContent
				id="classic-modal-slide-description"
				className={classes.modalBody}>

				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody}>
					<div className="Conteiner-table">
						<table className="table-date">
							<p>Datos registrados</p>
							<tbody>
							<tr>
								<th>Nombre de la empresa</th>
							<td>
								<Field
									id={"nombreDeLaEmpresa"}
									name={"nombreDeLaEmpresa"}
									type={"text"}
									autoComplete={"off"}
									component={customInputText}/>
							</td>
							</tr>
							<tr>
								<th>NIT</th>
								<td>
									<Field
										id={"nit"}
										name={"nit"}
										type={"text"}
										autoComplete={"off"}
										component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>Empresa Tip.</th>
								<td>
										<Field
											id={"tipo"}
											name={"tipo"}
											type={"text"}
											autoComplete={"on"} 
											component={customInputText} 
											classes={classes}/>

								</td>
							</tr>
							<tr>
								<th>Actividad economica</th>
								<td>
									<Field
										id={"actividadEconomica"}
										name={"actividadEconomica"}
										type={"text"}
										autoComplete={"off"}
										component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>CIIU</th>
								<td>
									<Field
										id={"ciiu"}
										name={"ciiu"}
										type={"text"}
										autoComplete={"off"}
										component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>Total ingresos mensuales</th>
								<td>
									<Field id={"totalIngresosMensuales"}
										name={"totalIngresosMensuales"}
										type={"text"}
										autoComplete={"on"}
										component={customInputText}
										classes={classes}/>
								</td>
							</tr>
							<tr>
								<th>Total egresos mensuales</th>
								<td>
									<Field id={"totalEgresosMensuales"}
										name={"totalEgresosMensuales"}
										type={"text"}
										autoComplete={"on"}
										component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>Numero de empleados</th>
								<td>
									<Field
										id={"numeroDeEmpleados"}
										name={"numeroDeEmpleados"}
										type={"number"}
									    autoComplete={"off"}
									    component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>Direccion empresa</th>
								<td>
									<Field id={"direccionEmpresa"}
									    name={"direccionEmpresa"}
									    type={"text"}
									    component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>Barrio/Vereda/Localidad</th>
								<td>
									<Field id={"barrio"}
									    name={"barrio"}
									    type={"text"}
									    component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>Departamento</th>
								<td>
									<Field id={"departamento"}
									    name={"departamento"}
									    type={"text"}
									    component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>Ciudad</th>
								<td>
									<Field id={"ciudad"}
									    name={"ciudad"}
									    type={"text"}
									    component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>Telefono</th>
								<td>
									<Field id={"telefono"}
									    name={"telefono"}
									    type={"text"}
									    component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>Celular</th>
								<td>
									<Field id={"celular"}
									    name={"celular"}
									    type={"text"}
									    component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>Correo</th>
								<td>
									<Field id={"correo"}
									    name={"correo"}
									    type={"email"}
									    component={customInputText}/>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
					<DialogActions className={classes.modalFooter}>
						<Button
							type={'submit'}
							color="primary"
						>
							Guardar
						</Button>
						<Button
							onClick={() => controlModal("personModal")}
							color="warning"
						>
							Cerrar
						</Button>
					</DialogActions>
				</DialogContent>
			</DialogContent>
			</Form>}





			{naturalPerson.person.hasOwnProperty("id") && 
			<Form onSubmit={handleSubmit(this.handleSubmit)}>
			<DialogContent
				id="classic-modal-slide-description"
				className={classes.modalBody}>

				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody}>
					<div className="Conteiner-table">
						<table className="table-date">
							<p>Datos registrados</p>
							<tbody>
							<tr>
								<th>Nacionalidad</th>
							<td>
								<Field
									id={"Nacionalidad"}
									name={"nacionalidad"}
									type={"text"}
									autoComplete={"off"}
									component={customInputText}/>
							</td>
							</tr>
							<tr>
								<th>Genero</th>
								<td>
									<Field
										id={"genero"}
										name={"genero"}
										type={"text"}
										autoComplete={"off"}
										component={customInputSelect}
										data={[
											{id: "Masculino", nombre: "Masculino"},
											{id: "Femenino", nombre: "Femenino"}
										]}
										/>
								</td>
							</tr>
							<tr>
								<th>Estado civil</th>
								<td>
										<Field
											id={"estadoCivil"}
											name={"estadoCivil"}
											type={"text"}
											autoComplete={"on"} 
											component={customInputText} 
											classes={classes}/>

								</td>
							</tr>
							<tr>
								<th>Telefono</th>
								<td>
									<Field
										id={"telefono"}
										name={"telefono"}
										type={"text"}
										autoComplete={"off"}
										component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>Fecha de expedición</th>
								<td>
									<Field
										id={"fechaDeExpedicion"}
										name={"fechaDeExpedicion"}
										type={"date"}
										autoComplete={"off"}
										component={customInputDate}/>
								</td>
							</tr>
							<tr>
								<th>Ocupación</th>
								<td>
									<Field
										id={"ocupacion"}
										name={"ocupacion"}
										type={"text"}
										autoComplete={"off"}
										component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>Tipo de vivienda</th>
								<td>
									<Field id={"tipoDeVivienda"}
										name={"tipoDeVivienda"}
										type={"text"}
										autoComplete={"on"}
										component={customInputText}
										classes={classes}/>
								</td>
							</tr>
							<tr>
								<th>Barrio</th>
								<td>
									<Field id={"barrio"}
										name={"barrio"}
										type={"text"}
										autoComplete={"on"}
										component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>Nombre de la empresa</th>
								<td>
									<Field
										id={"nombreDeLaEmpresa"}
										name={"nombreDeLaEmpresa"}
										type={"text"}
									    autoComplete={"off"}
									    component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>Actividad de la empresa</th>
								<td>
									<Field id={"actividadDeLaEmpresa"}
									    name={"actividadDeLaEmpresa"}
									    type={"text"}
									    component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>Cargo</th>
								<td>
									<Field id={"cargoActual"}
									    name={"cargoActual"}
									    type={"text"}
									    component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>Nivel de estudios</th>
								<td>
									<Field id={"nivelDeEstudios"}
									    name={"nivelDeEstudios"}
									    type={"text"}
									    component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>Dirección oficina</th>
								<td>
									<Field id={"directionOficina"}
									    name={"direccionOficina"}
									    type={"text"}
									    component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>Telefono</th>
								<td>
									<Field id={"telefonoOficina"}
									    name={"telefonoOficina"}
									    type={"text"}
									    component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>Total ingresos mensuales</th>
								<td>
									<Field id={"totalIngresosMensuales"}
									    name={"totalIngresosMensuales"}
									    type={"text"}
									    component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>Total egresos mensuales</th>
								<td>
									<Field id={"totalEgresosMensuales"}
									    name={"totalEgresosMensuales"}
									    type={"text"}
									    component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>Total activos</th>
								<td>
									<Field id={"totalActivos"}
									    name={"totalActivos"}
									    type={"text"}
									    component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>Total pasivos</th>
								<td>
									<Field id={"totalPasivos"}
									    name={"totalPasivos"}
									    type={"text"}
									    component={customInputText}/>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
					<DialogActions className={classes.modalFooter}>
						<Button
							type={'submit'}
							color="primary"
						>
							Guardar
						</Button>
						<Button
							onClick={() => controlModal("personModal")}
							color="warning"
						>
							Cerrar
						</Button>
					</DialogActions>
				</DialogContent>
			</DialogContent>
			</Form>}
		</Dialog>;
	}
}

InfoPerson.propTypes = {
	classes: PropTypes.object
};

const formed = reduxForm({
	form: "InfoPerson",
	enableReinitialize: true,
	reset
})(withStyles(userProfileStyles)(InfoPerson));

const mapStateToProps = state => () => {
	let values = {};
	if (state.legalPerson.person.hasOwnProperty("id"))
	    values = state.legalPerson.person;
	if (state.naturalPerson.person.hasOwnProperty("id"))
		values = state.naturalPerson.person;
	return {
	    clients: state.clients, 
		legalPerson: state.legalPerson,
	    naturalPerson: state.naturalPerson,
	    initialValues: values,
	    client: state.client,
	    stateForm: state.form,
	}
};

const connected = connect(mapStateToProps, {
	legalPersonUpdateRequesting,
	naturalPersonUpdateRequesting
})(withStyles(notificationsStyle)(formed));

export default connected