import React from "react";
// MaterialIU
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
// Components Core
import Card from "../Card/Card.jsx";
import CardBody from "../Card/CardBody";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Button from "../CustomButtons/Button.jsx";
// Styles
import userProfileStyles from "../../assets/jss/material-dashboard-pro-react/views/userProfileCompanyStyles";
// Integration
import { connect } from "react-redux";
import { toastr } from 'react-redux-toastr';
import { Form,reduxForm, Field, reset} from "redux-form";
import { customInputText } from "../inputs/typeText";
import DocumentsInput from "../inputs/documents";
import { documentTypesGetRequesting } from "../../redux/documentType/actions";
import { creditCreateRequesting, planPaymentsCredit , creditCleanValues } from "../../redux/credit/actions";
import { configurationsGetRequesting } from "../../redux/configuration/actions";
import { customInputSelect } from "../inputs/typeSelect";
import { Redirect } from "react-router-dom";
import {policyResetFocus,policyDeleteRequesting} from "../../redux/policy/actions";

import { FormattedNumber } from "react-intl";

const formattedNumber = (value) => {
  return (
    <FormattedNumber value={value} style={"currency"} currency={"COP"}
                     minimumFractionDigits={0} maximumFractionDigits={0}/>
  );
};

class FormCredit extends React.Component {

  constructor(props) {
    super(props);
    const {
      documentTypesGetRequesting,
      configurationsGetRequesting
    } = this.props;
    documentTypesGetRequesting();
    configurationsGetRequesting();
    this.state = {
      message: "",
      authorize: true
    };
  }

  componentWillUnmount(){
    this.handleCancelQuote();
  }

  handleChangeAuthorize(){
    this.setState((state) => ({
      authorize: !state.authorize
    }));
  }

  handleSubmit = (values) => {
    const {
      client: { token },
      policy: { policy },
      creditCreateRequesting,
      credit: { success , planPayments }
    } = this.props;
    const client = { name: 'Client test' , id: '123456789' , type: 2 };
    //const initialFeed = values.porcentaje * parseInt(policy.valor);
    const totalCredit = parseInt(policy.valor) - values.coutaInicial;

    if (!this.state.authorize)
      toastr.error('Error','Debe autorizar el seguimiento crediticio.');
    else
      creditCreateRequesting(token, values, policy, values.coutaInicial, totalCredit , client , planPayments);
  };

  handleGetPlanPayments = (fees, totalCredit) => {
    const {
      configuration: { configurations },
      policy: { policy },
      planPaymentsCredit
    } = this.props;
    let pastDueRate = configurations.find((configuration) => configuration.nombre === "Tasa mes vencido");
    let adminFee = configurations.find((configuration) => configuration.nombre === "Tasa administración");
    planPaymentsCredit(fees, totalCredit, pastDueRate.valor, adminFee.valor, policy);
  };

  handleCancelQuote=()=>{
    const {policyResetFocus,policy:{policy},policyDeleteRequesting,client:{ token }} = this.props;
    policyDeleteRequesting(token,policy.id);
    policyResetFocus();
    this.props.reset('policy');
    this.props.reset('credit');
    this.props.creditCleanValues();
  };

  handleFormattedNumber(num){
    try{
      const count = num.toString().split('');
      if (count.length > 3){
        var dots = [], result = '';
        for (let i = count.length , j = 1; i > 0; i-- , j++){
          if (j%3 === 0)
            dots.push('.' + count[i - 1]);
          else
            dots.push(count[i - 1]);
        }

        for (let i = dots.length; i > 0; i--){
          result+=dots[i - 1];
        }

        return '$'+result;
    }
       else
        return '$'+num;
    }catch (error){
      console.log(error);
    }
  }

  render() {
    const {
      classes,
      handleSubmit,
      policy: { policy },
      credit: { requesting, percentages, planPayments, success },
      documentType: { documentTypes },
      configuration: { configurations },
      stateForm
    } = this.props;
    const pastDueRate = configurations.find((configuration) => configuration.nombre === "Tasa mes vencido");
    const adminFee = configurations.find((configuration) => configuration.nombre === "Tasa administración");
    this.formCredit = stateForm ? stateForm.hasOwnProperty("credit") && stateForm.credit.hasOwnProperty("values") ? stateForm.credit.values : {} : {};
    const initialFeed = this.formCredit.hasOwnProperty("coutaInicial") && policy.hasOwnProperty("valor") ? parseInt((this.formCredit.coutaInicial / policy.valor) * 100) : 0;
    const totalCredit = this.formCredit.hasOwnProperty("coutaInicial") && policy.hasOwnProperty("valor") ? (parseInt(policy.valor) - this.formCredit.coutaInicial) : 0;
    const fees = this.formCredit.hasOwnProperty("cuotas") ? parseInt(this.formCredit.cuotas) : 0

    // const fees = 10;
    // const totalCredit = 10000000000;

    if (pastDueRate && adminFee && this.formCredit.hasOwnProperty("cuotas")){
      let tasaMesVencido = (pastDueRate.valor / 100);
      this.quoteValue = /*(adminFee.valor * formCredit.cuotas) / (formCredit.cuotas - 1) + */(tasaMesVencido * Math.pow((1 + tasaMesVencido), (this.formCredit.cuotas -1))) / (Math.pow((1 + tasaMesVencido),(this.formCredit.cuotas -1))-1) * (policy.valor - this.formCredit.coutaInicial);
      
      let intereses = tasaMesVencido;
      let interesesPrimeraCuota = policy?.valor * intereses;
      this.interestFirstValue = interesesPrimeraCuota;
      let abonoCapitalPrimeraCuota = this.formCredit.coutaInicial - interesesPrimeraCuota;
      let saldoDeudaPrimeraCuota = policy?.valor - abonoCapitalPrimeraCuota;
      this.capitalFirstBalance = saldoDeudaPrimeraCuota;
      this.capitalFirstPayment = abonoCapitalPrimeraCuota;
      let numeroDeCuotas = this.formCredit.cuotas;
      let tasaAdministracion = adminFee.valor;
      this.tasaAdministracion = tasaAdministracion;

      this.initialFeed = Math.ceil(((saldoDeudaPrimeraCuota * (intereses/(1-( Math.pow((1+intereses), (numeroDeCuotas-1) * -1) )))  ) + tasaAdministracion) / 1000) * 1000;
    }

    if (fees >= 3 && fees <= 11 && fees !== planPayments.length && configurations.length > 0) {
      this.handleGetPlanPayments(fees, totalCredit);
    }

    /*if (success) {
      return (
        <Redirect to={"/admin/misSolicitudes"}/>
      );
    }*/
    if (policy.hasOwnProperty("id") && policy.fecha_inicio.split("-")[2] <= 15 && this.state.message === "") {
      this.setState({
        message: policy.beneficiario_honeroso ? "La cantidad de cuotas debe ser un numero entre 3 y 10" : "La cantidad de cuotas debe ser un numero entre 3 y 11"
      });
    }
    if (policy.hasOwnProperty("id") && policy.fecha_inicio.split("-")[2] > 15 && this.state.message === "") {
      this.setState({
        message: policy.beneficiario_honeroso ? "La cantidad de cuotas debe ser un numero entre 3 y 10" : "La cantidad de cuotas debe ser un numero entre 3 y 9"
      });
    }
    return (
      <Card profile>
        <CardBody>
          <h1 className={classes.TitleH1Left}>Información crédito</h1>
          <Form onSubmit={handleSubmit(this.handleSubmit)}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                {/*<Field disabled={!policy.hasOwnProperty("id")} id={"porcentaje"}*/}
                {/*       name={"porcentaje"} type={"select"}*/}
                {/*       label={"Porcentaje cuota inicial *"}*/}
                {/*       autoComplete={"on"} component={customInputSelect} classes={classes}*/}
                {/*       data={percentages}/>*/}
                <Field disabled={!policy.hasOwnProperty("id")}
                       id={"coutaInicial"}
                       name={"coutaInicial"}
                       type={"number"}
                       label={"Valor cuota inicial *"}
                       autoComplete={"on"} component={customInputText} classes={classes}
                       onChange={() => {
                          if (fees >= 3 && fees <= 11)
                            this.handleGetPlanPayments(fees,totalCredit);
                       }}
                       data={percentages}/>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Field disabled={!policy.hasOwnProperty("id")} id={"cuotas"} name={"cuotas"}
                       type={"number"}
                       label={"Numero de cuotas *"}
                       autoComplete={"off"}
                       component={customInputText}/>
                <p style={{ marginTop: -15 }}>{this.state.message}</p>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <p>Porcentaje cuota inicial</p>
                {initialFeed + '%'}
                {/*<p>Valor cuota inicial</p>*/}
                {/*{formattedNumber(initialFeed)}*/}
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
               {/* <p>Valor del credito</p>
                {formattedNumber(totalCredit)}*/}
              </GridItem>
              {planPayments.length > 0 && policy.hasOwnProperty("id") && (
                <GridItem xs={12} sm={12} md={12}>
                  <br/>
                  <h1 className={classes.TitleH1Left}>Tabla amortización</h1>
                  <div className="Conteiner-table">
                    <table className="table-pyments-page-price5">
                      <thead>
                      <tr>
                        <th>Cuota</th>
                        <th>Valor cuota</th>
                        <th>Valor interes *</th>
                        <th>Abono capital</th>
                        <th>Saldo capital</th>
                        <th>Fecha de pago</th>
                      </tr>
                      </thead>
                      <tbody>
                      {planPayments.map((planPayment , index) => {
                        let tasaMesVencido = (pastDueRate.valor / 100);
                        if (index === 0){
                          planPayment.valueFee = parseInt(this.formCredit.coutaInicial) /*+ adminFee.valor*/;
                          planPayment.interestValue = this.interestFirstValue;
                          planPayment.capitalValue = this.capitalFirstPayment;
                          planPayment.capitalBalance = this.capitalFirstBalance;
                        }

                        if (index !== 0){
                          planPayment.valueFee = this.initialFeed;
                          planPayment.interestValue = parseInt(planPayments[index - 1].capitalBalance * tasaMesVencido); //+ adminFee.valor; //parseInt(planPayment.interestValue);
                          planPayment.capitalValue = parseInt(planPayment.valueFee - planPayment.interestValue) - this.tasaAdministracion; //parseInt(planPayment.capitalValue);
                          planPayment.capitalBalance = planPayments[index - 1].capitalBalance - planPayment.capitalValue;
                        }

                        if (index === planPayments.length - 1)
                          planPayment.capitalBalance = 0;

                        return (
                            <tr key={index}>
                              <td>{planPayment.fee}</td>
                              <td>{this.handleFormattedNumber(parseInt(planPayment.valueFee))}</td>
                              <td>{this.handleFormattedNumber(parseInt(planPayment.interestValue + this.tasaAdministracion))}</td>
                              <td>{this.handleFormattedNumber(parseInt(planPayment.capitalValue))}</td>
                              <td>{this.handleFormattedNumber(parseInt(planPayment.capitalBalance))}</td>
                              <td>{planPayment.payDayLimit}</td>
                            </tr>
                        )
                      })}
                      </tbody>
                    </table>
                  </div>
                </GridItem>
              )}
              {planPayments.length > 0 &&  policy.hasOwnProperty("id") && (
                <div style={{ textAlign: "left", margin: 20 }}>
                  <p>- El numero de cuotas depende de la vigencia corrida de la poliza.</p>
                  <p>- El monto minimo de financiación son $500.000.</p>
                  <p>- Se pueden ingresar financiaciones maximo 25 dias de vigencia. Se debe radicar la financiacion 5
                    dias antes del vencimiento toda la documentacion completa y debidamente diligenciada.</p>
                  <p>- * En el valor de la cuota esta incluido cobro mensual de comisón por disponibilidad de cupo de
                    crédito, incluido IVA.</p>
                </div>
              )}
              {planPayments.length > 0 && policy.hasOwnProperty("id") && documentTypes.length > 0 && (
                <GridItem xs={12} sm={12} md={12}>
                  <br/>
                  <h1 className={classes.TitleH1Left}>Documentos</h1>
                  <div className="Conteiner-table">
                    <table className="table-pyments-page-price2">
                      <tbody>
                      {documentTypes.map((documentType, index) => (
                        documentType.nombre==='Formato Autorización Centrales de Riesgo' && (
                        <tr key={index}>
                          <td>{documentType.nombre}{documentType.requerido ? " *" : ""}</td>
                          <td>
                            <Field disabled={!policy.hasOwnProperty("id")}
                                   name={`document_${documentType.nombre}`} maxFiles={1}
                                   className={"filepond"}
                                   component={DocumentsInput}
                                   label={"<span class=\"filepond--label-action\">Subir</span>"}/>
                          </td>
                          <td>
                            <input type={'checkbox'} checked={this.state.authorize} onChange={this.handleChangeAuthorize.bind(this)}/> El cliente autorizo consultar en las centrales de riesgo su comportamiento crediticio.
                          </td>
                        </tr>
                        )
                      ))}
                      </tbody>
                    </table>
                  </div>
                </GridItem>
              )}
            </GridContainer>
            <Button disabled={!policy.hasOwnProperty("id") || requesting}
                    type={"submit"}
                    color="primary"
                    className={classes.updateProfileButton}>
              Siguiente
            </Button>
          </Form>
          <Button disabled={!policy.hasOwnProperty("id") || requesting}
                  onClick={()=>this.handleCancelQuote()}
                  color="danger"
                  className={classes.updateProfileButton}>
            Cancelar
          </Button>
        </CardBody>
      </Card>
    );
  }
}

FormCredit.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  client: state.client,
  credit: state.credit,
  documentType: state.documentType,
  policy: state.policy,
  clients: state.clients,
  configuration: state.configuration,
  stateForm: state.form
});

const connected = connect(mapStateToProps, {
  documentTypesGetRequesting,
  creditCreateRequesting,
  creditCleanValues,
  planPaymentsCredit,
  configurationsGetRequesting,
  policyResetFocus,
  policyDeleteRequesting,
  reset
})(withStyles(userProfileStyles)(FormCredit));

const formed = reduxForm({
  form: "credit",
})(connected);

export default formed;
