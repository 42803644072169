import {call, put, all, takeEvery} from 'redux-saga/effects';
import {handleApiErrors} from "../../../commons/errors/apiErrors";
import {meGetError, meGetSuccess} from "./actions";
import {ME_GET_REQUESTING} from "./constants";
import { ErrorComponent } from "../../../commons/errors/errors";
import { toastr } from "react-redux-toastr";
import React from "react";

const meUrl = `${process.env.REACT_APP_API_URL}/me`;

const showError = (error) => {
	let toastOptions = {
		component: (
			<ErrorComponent message={error}/>
		)
	};
	toastr.error("Error", toastOptions);
};

const meGetApi = (token) => {
    return fetch(meUrl, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
	        if (json.code === 422)
		        throw json.data;
	        if (json.code === 400)
		        throw [json.data];
	        if (json.code === 200)
		        return json.data.data;
	        throw json.data;
        })
        .catch((error) => {
            throw error;
        })
};

function* meGetFlow(action) {
    try {
        const {token} = action;
        const user = yield call(meGetApi, token);
        yield put(meGetSuccess(user));
    } catch (error) {
        yield put(meGetError(error));
        showError(error);
    }
}

function* meUserWatcher() {
    yield all([
        takeEvery(ME_GET_REQUESTING, meGetFlow),
    ])
}

export default meUserWatcher;