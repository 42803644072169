// /*!// =========================================================
// * Developer DigitalParchapp V.1
// =========================================================// */
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/core/SvgIcon/SvgIcon";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import notificationsStyle from "../../../assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
import Slide from "@material-ui/core/Slide";
// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
// core components
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
// Integration
import { Form, Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import DocumentsInput from "../../../components/inputs/documents";
import { documentsCreateRequesting , documentFinancingPackageCreateRequesting , documentCheckDigitalSignatureRequesting , documentAuthorizeCreateRequesting, documentSendMailSignatureRequesting } from "../../../redux/document/actions";
import DocuSignModal from './docuSignModal';
import { customInputText } from "../../../components/inputs/typeText";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

class Documents extends React.Component {

	constructor(props){
		super(props);
		this.modal = React.createRef();
		this.handleDocumentFinancingPackageCreate = this.handleDocumentFinancingPackageCreate.bind(this);
		this.handleDocumentCheckDigitalSignature = this.handleDocumentCheckDigitalSignature.bind(this);
		this.handleOpenDigitalSignatureModal = this.handleOpenDigitalSignatureModal.bind(this);
		this.handleCloseDigitalSignatureModal = this.handleCloseDigitalSignatureModal.bind(this);
	}

	componentDidUpdate(prevProps,prevState){
		const { document: { verifyData , id } } = this.props;
		if (id !== prevProps.document.id)
		    this.handleOpenDigitalSignatureModal();
		console.log("verifyData: ", verifyData);
	}

	componentWillUnmount(){
		try{
		    this.handleCloseDigitalSignatureModal();
		}catch(error){}
	}

	handleOpenDigitalSignatureModal(){
		this.modal.current.classList.add("modal-digital-signature-container-active");
	}

	handleCloseDigitalSignatureModal(){
		this.modal.current.classList.remove("modal-digital-signature-container-active");
	}

	handleDocumentCheckDigitalSignature(documentId){
		const {
			client: { token },
			documentCheckDigitalSignatureRequesting
		} = this.props;
		documentCheckDigitalSignatureRequesting(token, documentId);
	}

	handleViewDocument = (url) => {
		window.open(url, "_blank");
	};

	handleSubmit = (values) => {
		const {
			client: { token },
			documentsCreateRequesting,
			credit: { credit }
		} = this.props;
		documentsCreateRequesting(token, "credit", credit.id, values);
	};

	handleDocumentFinancingPackageCreate(){
		const {
			client: { token },
		    credit: { credit }, 
			documentFinancingPackageCreateRequesting 
		} = this.props;
		documentFinancingPackageCreateRequesting(token, credit.id);
	}

	handleDocumentAuthorizeCreate(){
		const {
			client: { token },
			credit: { credit },
			documentAuthorizeCreateRequesting
		} = this.props;
		documentAuthorizeCreateRequesting(token, credit.id);
	}

	handleSentMailSignature(){
		const {
			client: { token },
			credit: { credit },
			documentSendMailSignatureRequesting
		} = this.props;
		documentSendMailSignatureRequesting(token, credit.id);
	}

	render() {
		const {
			classes,
			open,
			controlModal,
			handleSubmit,
			credit: { documents, credit },
			documentType,
			document: { requesting , verifyData , id },
			oneroso,
			stateForm,
		} = this.props;
		const documentTypes = documentType.documentTypes.filter((documentType) => documentType.nombre !== 'Contrato mandato' && documentType.nombre !== "Paquete de financiación");
		return <>
		    {verifyData !== "" && <>
		        <DocuSignModal modalRef={this.modal} handleCloseDigitalSignatureModal={this.handleCloseDigitalSignatureModal}/>
		    </>}<Dialog
			classes={{
				root: classes.center + " " + classes.modalRoot,
				paper: classes.modal
			}}
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => controlModal("documentsModal")}
			aria-labelledby="classic-modal-slide-title"
			aria-describedby="classic-modal-slide-description"
		>
			<CardHeader color="primary" icon>
				<CardIcon color="primary">
					<MailOutline/>
				</CardIcon>
			</CardHeader>

			<DialogTitle
				id="classic-modal-slide-title"
				disableTypography
				className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key="close"
					aria-label="Close"
					color="transparent"
					onClick={() => controlModal("documentsModal")}
				>
					<Close className={classes.modalClose}/>
				</Button>
				<h4 className={classes.modalTitle}>Documentos</h4>
			</DialogTitle>
			<DialogContent
				id="classic-modal-slide-description"
				className={classes.modalBody}>
				<DialogContent id="classic-modal-slide-description"
				               className={classes.modalBody}>
					<div className="Conteiner-table">
						<table className="table-date">
							<tbody>
							{documents.map((document, index) => {
								let sign = "";

								/*if (document.tipo_documento.id < 5 && document.tipo_documento.id > 0){
									sign = <>
									    <Button color={"primary"}
										   onClick={() => { 
										   	if (document.id === id)
										   		this.handleOpenDigitalSignatureModal();
										   	else
										   	    this.handleDocumentCheckDigitalSignature(document.id)
										}}
										   disabled={requesting}
										   style={{width: '45%'}}
										>
										   Firma
										</Button>
									</>;
								}*/

								return (<tr key={index}>
									<th>{document.tipo_documento.nombre}{document.tipo_documento.requerido ? "*" : ""}</th>
									<td>
										<Button color={"warning"}
										        onClick={() => this.handleViewDocument(document.url)}
										        style={{width: '45%'}}>
											Ver
										</Button>
										{sign}
									</td>
								</tr>);
							})}
							</tbody>
						</table>
						<Form onSubmit={handleSubmit(this.handleSubmit)}>
							<div className="Conteiner-table" style={{ marginTop: "0px" }}>
								<table className="table-date">
									<tbody>
									    {!documents.find((document) => document.tipo_documento.id === 2) &&
									   	    <tr>
									   	        <th>Paquete de financiación</th>
									   	        <td>
									   	        	<Button color={"warning"}
									   	        	    disabled={requesting}
										                onClick={() => this.handleDocumentFinancingPackageCreate()}>
											            Generar
										            </Button>
									   	        </td>
									        </tr>
									    }
									{documentTypes.map((documentType, index) => {
										let generateDocumentButton = "";
										let numberPay = "";

										if (!documents.find((document) => document.tipo_documento.id === 1) && documentType.id === 1){
											generateDocumentButton = <>
											   <Button color={"warning"}
											           disabled={requesting}
											           onClick={() => this.handleDocumentAuthorizeCreate()}
											           style={{width: '100%', backgroundColor: '#1798af'}}
											           >
											           Generar
											    </Button>
											</>;
										}

										if (stateForm.hasOwnProperty("addDocument") && stateForm.addDocument.hasOwnProperty("values")){
											if (stateForm.addDocument.values.hasOwnProperty("document_Comprobante de pago")){
											    numberPay = (
													<Field
										                id={"pagare"}
										                name={"pagare"}
										                placeholder={"Pagaré"}
										                type={"text"}
										                autoComplete={"off"}
										                component={customInputText}/>
											    )
											}    
										}

										if (!documents.find((document) => document.tipo_documento.id === documentType.id)) {
											return (
												<tr key={index}>
													<th>{documentType.nombre}{documentType.requerido ? "*" : ""}</th>
													<td>
													    {generateDocumentButton}
														<Field name={`document_${documentType.nombre}`} maxFiles={1}
														       className={"filepond"}
														       component={DocumentsInput}
														       label={"<span class=\"filepond--label-action\">Subir</span>"}/>
														{documentType.id == 6 && numberPay}
													</td>
												</tr>
											);
										}

									})}
									</tbody>
								</table>
							</div>
							<DialogActions className={classes.modalFooter}>
								<Button disabled={requesting} onClick={() => controlModal("documentsModal")} color="warning">
									Cerrar
								</Button>
								{stateForm.hasOwnProperty("addDocument") && stateForm.addDocument.hasOwnProperty("values") && (
									<Button color="primary" disabled={requesting} className={classes.registerButton} type={"submit"}>
										Agregar
									</Button>
								)}

								<Button disabled={requesting} onClick={(e) => {
									e.preventDefault();
									this.handleSentMailSignature();
								}}  color="primary" className={classes.registerButton}>
										Enviar para firmar
								</Button>
							</DialogActions>
						</Form>
					</div>
				</DialogContent>
			</DialogContent>
		</Dialog></>;
	}
}

Documents.propTypes = {
	classes: PropTypes.object
};

const mapStateToProps = state => ({
	client: state.client,
	documentType: state.documentType,
	document: state.document,
	credit: state.credit,
	stateForm: state.form
});

const connected = connect(mapStateToProps, {
	documentsCreateRequesting,
	documentFinancingPackageCreateRequesting,
	documentCheckDigitalSignatureRequesting,
	documentAuthorizeCreateRequesting,
	documentSendMailSignatureRequesting
})(withStyles(notificationsStyle)(Documents));

const formed = reduxForm({
	form: "addDocument"
})(connected);

export default formed;