import {
	CONFIGURATION_CONTROL_MODAL, CONFIGURATION_FOCUS,
	CONFIGURATION_UPDATE_ERROR,
	CONFIGURATION_UPDATE_REQUESTING, CONFIGURATION_UPDATE_SUCCESS,
	CONFIGURATIONS_GET_ERROR,
	CONFIGURATIONS_GET_REQUESTING,
	CONFIGURATIONS_GET_SUCCESS, CONFIGURATIONS_HISTORY_GET_ERROR,
	CONFIGURATIONS_HISTORY_GET_REQUESTING, CONFIGURATIONS_HISTORY_GET_SUCCESS
} from "./constants";

const initialState = {
	requesting: false,
	success: false,
	error: "",
	configurations: [],
	configurationsHistory: [],
	configuration: {},
	editModal: false
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case CONFIGURATIONS_GET_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: ""
			};
		case CONFIGURATIONS_GET_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				configurations: action.configurations,
			};
		case CONFIGURATIONS_GET_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error,
			};
		case CONFIGURATIONS_HISTORY_GET_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: ""
			};
		case CONFIGURATIONS_HISTORY_GET_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				configurationsHistory: action.configurations,
			};
		case CONFIGURATIONS_HISTORY_GET_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error,
			};
		case CONFIGURATION_UPDATE_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: ""
			};
		case CONFIGURATION_UPDATE_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				configurations: state.configurations.map((configuration) => configuration.id === action.configuration.id ? action.configuration : configuration),
			};
		case CONFIGURATION_UPDATE_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error,
			};
		case CONFIGURATION_CONTROL_MODAL:
			return {
				...state,
				editModal: action.modal === "editModal" ? !state.editModal : state.editModal,
			};
		case CONFIGURATION_FOCUS:
			return {
				...state,
				configuration: action.configuration,
			};
		default:
			return state;
	}
};

export default reducer;