export const CONFIGURATIONS_GET_REQUESTING = 'CONFIGURATIONS_GET_REQUESTING';
export const CONFIGURATIONS_GET_SUCCESS = 'CONFIGURATIONS_GET_SUCCESS';
export const CONFIGURATIONS_GET_ERROR = 'CONFIGURATIONS_GET_ERROR';

export const CONFIGURATIONS_HISTORY_GET_REQUESTING = 'CONFIGURATIONS_HISTORY_GET_REQUESTING';
export const CONFIGURATIONS_HISTORY_GET_SUCCESS = 'CONFIGURATIONS_HISTORY_GET_SUCCESS';
export const CONFIGURATIONS_HISTORY_GET_ERROR = 'CONFIGURATIONS_HISTORY_GET_ERROR';

export const CONFIGURATION_UPDATE_REQUESTING = 'CONFIGURATION_UPDATE_REQUESTING';
export const CONFIGURATION_UPDATE_SUCCESS = 'CONFIGURATION_UPDATE_SUCCESS';
export const CONFIGURATION_UPDATE_ERROR = 'CONFIGURATION_UPDATE_ERROR';

export const CONFIGURATION_CONTROL_MODAL = 'CONFIGURATION_CONTROL_MODAL';
export const CONFIGURATION_FOCUS = 'CONFIGURATION_FOCUS';