/*!
=========================================================
* Developer DigitalParchapp V.1
=========================================================
*/
import React from "react";
import PropTypes from "prop-types";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import Create from "@material-ui/icons/Create";
import Find from "@material-ui/icons/FindInPage";
import Supervised from "@material-ui/icons/SupervisedUserCircle";
import Description from "@material-ui/icons/Description";


// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { dataTable } from "variables/general.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// Modals
import InfoClientModal from "./Modal/InfoClient";
import InfoPersonModal from "./Modal/InfoPerson";
import InfoFamilyModal from './Modal/InfoFamily';
import InfoSupplementaryDataModal from './Modal/InfoSupplementaryData';
import DatePolicyModal from "./Modal/datePolicy";
import DateCreditModal from "./Modal/dateCredit";
import DateAdviserModal from "./Modal/dateAdviser";
import DocumentsModal from "./Modal/documents";
import StatesModal from "./Modal/statesModal";
// Integration
import {
  creditsGetRequesting,
  creditFocus,
  creditControlModal,
  creditChangeStateRequesting,
  creditPersonRequesting,
  creditFamilyRequesting,
  creditSupplementaryDataRequesting,
  creditClientRequesting,
  creditPolicyRequesting,
  creditPaymentPlansRequesting,
  creditAgentRequesting,
  creditDocumentsRequesting,
  creditsFilterRequesting,
  creditsSearchError
} from "../../redux/credit/actions";
import { clientCleanValues } from '../../redux/client/actions';
import { creditCleanValues } from '../../redux/credit/actions';
import { documentTypesGetRequesting } from "../../redux/documentType/actions";
import { onerosoGetRequesting } from '../../redux/oneroso/actions';
import { statesCreditGetRequesting } from "../../redux/state/actions";
import { paymentPlanConfirmedPaymentRequesting } from "../../redux/paymentPlan/actions";
import {
  emailNotificationCreateRequesting,
  emailNotificationsGetRequesting
} from "../../redux/emailNotification/actions";
import { connect } from "react-redux";
import { NotFound } from "../../commons/errors/notFound";
import { Redirect } from "react-router-dom";
import { FormattedNumber } from "react-intl";
import DetailCreditModal from "./Modal/detailCredit";
import { toastr } from "react-redux-toastr";

const formattedNumber = (value) => {
  return (
    <FormattedNumber value={value} style={"currency"} currency={"COP"}
                     minimumFractionDigits={0} maximumFractionDigits={0}/>
  );
};

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    fontWeight: 700
  },
  textTable: {
    fontWeight: 600
  }
};

// Cambiar el nombre de la clase segun el que se vaya a usar
class MyRequests extends React.Component {

  constructor(props) {
    super(props);
    const {
      client: { token },
      creditsGetRequesting,
      statesCreditGetRequesting,
      documentTypesGetRequesting,
      onerosoGetRequesting,
      creditsSearchError
    } = this.props;
    creditsSearchError("");
    creditsGetRequesting(token, "Todos", 1);
    statesCreditGetRequesting(token);
    documentTypesGetRequesting();
    onerosoGetRequesting();

    console.log(this.props);
    this.state = {
      dataSearch: [],
      columns: [
        {
          Header: "Fecha",
          accessor: "Fecha",
          width: 200,
          className: "text-center",
          id: "fecha_creacion",
          Filter: ({ filter, onChange }) => <input type="text"
                                                   onChange={event => {
                                                     this.handleFilterTable(event.target.value, "created_at");
                                                   }}
          />
        },
        {
          Header: "Numero de pagaré",
          accessor: "Numerodepagaré",
          width: 200,
          className: "text-center",
          id: "numero_pagare",
          Filter: ({ filter, onChange }) => <input type="text"
                                                   onChange={event => {
                                                     this.handleFilterTable(event.target.value, "numero_pagare");
                                                   }}
          />
        },
        {
          Header: "Numero de crédito",
          accessor: "Numerodecrédito",
          width: 200,
          className: "text-center",
          id: "id",
          Filter: ({ filter, onChange }) => <input type="text"
                                                   onChange={event => {
                                                     this.handleFilterTable(event.target.value, "id");
                                                   }}
          />
        },
        {
          Header: "Valor Poliza",
          accessor: "ValorPoliza",
          sortable: false,
          filterable: false,
          width: 200,
          className: "text-center"
        },
        {
          Header: "Nombre Asesor",
          accessor: "Npmbredelacesor",
          sortable: false,
          filterable: false,
          width: 200,
          className: "text-center"
        },
        {
          Header: "Nombre cliente",
          accessor: "nameClient",
          width: 200,
          className: "text-center",
          Filter: ({ filter, onChange }) => <input type="text"
                                                   onChange={event => {
                                                     this.handleFilterTable(event.target.value, "first_name", true, "client");
                                                   }}
          />
        },
        {
          Header: "Cuota fija",
          accessor: "valueFee",
          sortable: false,
          filterable: false,
          width: 200,
          justifyContent: "center",
          className: "text-center"
        },
        {
          width: 1800,
          Header: "Acciones",
          accessor: "actions",
          sortable: false,
          filterable: false,
          className: "text-center"
        }

      ]
    };
  }

   componentWillUnmount(){
      this.props.clientCleanValues();
      this.props.creditCleanValues();
   }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { credit: { credits, creditsSearch } } = this.props;
    if (prevProps.credit.credits !== credits) {
      this.setState({
        data: credits.map((credit, index) => {
          const { classes } = this.props;
          return {
            id: index,
            Fecha: credit.fecha_creacion,
            Numerodecrédito: credit.id,
            Numerodepagaré: credit.numero_pagare ? credit.numero_pagare : 'No registra',
            Nombrecliente: "",
            Npmbredelacesor: credit.agente.nombre,
            ValorPoliza: formattedNumber(credit.poliza.valor),
            nameClient: `${credit.cliente.nombres} ${credit.cliente.apellidos}`,
            valueFee: formattedNumber(credit.plan_pagos.valor_cuota),
            actions: (
              <div className="actions-right">
                {/* use this button to add a like kind of action */}
                <GridContainer>


                  <GridItem xs={1} sm={1} md={1} onClick={() => {
                    this.handleGetInfo(credit.id, "clientModal");
                    this.handleControlModal("clientModal");
                  }} className={"cursor-pointer"}>
                    <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="like"
                    >
                      <AssignmentInd/>
                    </Button>
                    Info.Cliente
                  </GridItem>
                  <GridItem className={"cursor-pointer"} xs={1} sm={1} md={2} onClick={() => {
                      this.handleGetInfo(credit.id, "personModal");
                      this.handleControlModal("personModal");
                  }}>
                  <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="like"
                  >
                          <AssignmentInd/>
                      </Button>
                      Info Personal
                  </GridItem>

                  <GridItem className={"cursor-pointer"} xs={1} sm={1} md={1} onClick={() => {
                      this.handleGetInfo(credit.id, "familyModal");
                      this.handleControlModal("familyModal");
                  }}>
                      <Button
                          justIcon
                          round
                          simple
                          color="info"
                          className="like"
                      >
                          <AssignmentInd/>
                      </Button>
                      Info Familiar
                  </GridItem>

                  <GridItem className={"cursor-pointer"} xs={1} sm={1} md={1} onClick={() => {
                      this.handleGetInfo(credit.id, "supplementaryDataModal");
                      this.handleControlModal("supplementaryDataModal");
                  }}>
                      <Button
                          justIcon
                          round
                          simple
                          color="info"
                          className="like"
                      >
                          <AssignmentInd/>
                      </Button>
                      Info comp
                  </GridItem>
                  <GridItem xs={1} sm={1} md={1} onClick={() => {
                    this.handleGetInfo(credit.id, "policyModal");
                    this.handleControlModal("policyModal");
                  }} className={"cursor-pointer"}>
                    <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="like"
                    >
                      <Find/>
                    </Button>
                    Dto.Poliza
                  </GridItem>


                  <GridItem xs={1} sm={1} md={1} onClick={() => {
                    this.handleFocus(credit);
                    this.handleGetInfo(credit.id, "paymentPlansModal");
                    this.handleControlModal("paymentPlansModal");
                  }} className={"cursor-pointer"}>
                    <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="like"
                    >
                      <Create/>
                    </Button>
                    Plan.Pagos
                  </GridItem>

                  <GridItem xs={1} sm={1} md={1} onClick={() => {
                    this.handleGetInfo(credit.id, "agentModal");
                    this.handleControlModal("agentModal");
                  }} className={"cursor-pointer"}>
                    <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="like"
                    >
                      <Supervised/>
                    </Button>
                    Info.Asesor
                  </GridItem>

                  <GridItem xs={1} sm={1} md={1} onClick={() => {
                    this.handleFocus(credit);
                    this.handleGetInfo(credit.id, "documentsModal");
                    this.handleControlModal("documentsModal");
                  }} className={"cursor-pointer"}>
                    <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="like"
                    >
                      <Description/>
                    </Button>
                    Documentos
                  </GridItem>
                  <GridItem xs={1} sm={1} md={2} onClick={() => {
                    this.handleFocus(credit);
                    this.handleControlModal("detailCreditModal");
                  }} className={"cursor-pointer"}>
                    <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="like"
                    >
                      <Find/>
                    </Button>
                    Detal.Credito
                  </GridItem>
                  <GridItem xs={1} sm={1} md={1} onClick={() => {
                    this.handleFocus(credit);
                    this.handleControlModal("statesCreditModal");
                  }} className={"cursor-pointer"}>
                    <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="like"
                    >
                      <Create/>
                    </Button>
                    Estados</GridItem>
                </GridContainer>
              </div>
            )
          };
        })
      });
    }
    if (prevProps.credit.creditsSearch !== creditsSearch) {
      this.setState({
        dataSearch: creditsSearch.map((credit, index) => {
          const { classes } = this.props;
          return {
            id: index,
            Fecha: credit.fecha_creacion,
            Numerodecrédito: credit.id,
            Numerodepagaré: credit.numero_pagare ? credit.numero_pagare : 'No registra',
            Nombrecliente: "",
            Npmbredelacesor: credit.agente.nombre,
            ValorPoliza: formattedNumber(credit.poliza.valor),
            nameClient: `${credit.cliente.nombres} ${credit.cliente.apellidos}`,
            valueFee: formattedNumber(credit.plan_pagos.valor_cuota),
            actions: (
              <div className="actions-right">
                {/* use this button to add a like kind of action */}
                <GridContainer>
                  <GridItem xs={3} sm={3} md={1} onClick={() => {
                    this.handleGetInfo(credit.id, "clientModal");
                    this.handleControlModal("clientModal");
                  }} className={"cursor-pointer"}>
                    <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="like"
                    >
                      <AssignmentInd/>
                    </Button>
                    Info cliente
                  </GridItem>
                  <GridItem className={"cursor-pointer"} xs={1} sm={1} md={2} onClick={() => {
                      this.handleGetInfo(credit.id, "personModal");
                      this.handleControlModal("personModal");
                  }}>
                  <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="like"
                  >
                      <AssignmentInd/>
                  </Button>
                      Info Personal
                  </GridItem>

                  <GridItem className={"cursor-pointer"} xs={1} sm={1} md={1} onClick={() => {
                      this.handleGetInfo(credit.id, "familyModal");
                      this.handleControlModal("familyModal");
                   }}>
                  <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="like"
                  >
                          <AssignmentInd/>
                      </Button>
                      Info Familiar
                  </GridItem>

                  <GridItem className={"cursor-pointer"} xs={1} sm={1} md={1} onClick={() => {
                      this.handleGetInfo(credit.id, "supplementaryDataModal");
                      this.handleControlModal("supplementaryDataModal");
                  }}>
                      <Button
                          justIcon
                          round
                          simple
                          color="info"
                          className="like"
                      >
                          <AssignmentInd/>
                      </Button>
                      Info comp
                  </GridItem>
                  <GridItem xs={3} sm={3} md={1} onClick={() => {
                    this.handleGetInfo(credit.id, "policyModal");
                    this.handleControlModal("policyModal");
                  }} className={"cursor-pointer"}>
                    <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="like"
                    >
                      <Find/>
                    </Button>
                    Datos poliza
                  </GridItem>
                  <GridItem xs={3} sm={3} md={1} onClick={() => {
                    this.handleFocus(credit);
                    this.handleGetInfo(credit.id, "paymentPlansModal");
                    this.handleControlModal("paymentPlansModal");
                  }} className={"cursor-pointer"}>
                    <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="like"
                    >
                      <Create/>
                    </Button>
                    Plan pagos
                  </GridItem>
                  <GridItem xs={3} sm={3} md={1} onClick={() => {
                    this.handleGetInfo(credit.id, "agentModal");
                    this.handleControlModal("agentModal");
                  }} className={"cursor-pointer"}>
                    <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="like"
                    >
                      <Supervised/>
                    </Button>
                    Info asesor
                  </GridItem>
                  <GridItem xs={3} sm={3} md={1} onClick={() => {
                    this.handleFocus(credit);
                    this.handleGetInfo(credit.id, "documentsModal");
                    this.handleControlModal("documentsModal");
                  }} className={"cursor-pointer"}>
                    <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="like"
                    >
                      <Description/>
                    </Button>
                    Documentos
                  </GridItem>
                  <GridItem xs={3} sm={3} md={1} onClick={() => {
                    this.handleFocus(credit);
                    this.handleControlModal("detailCreditModal");
                  }} className={"cursor-pointer"}>
                    <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="like"
                    >
                      <Find/>
                    </Button>
                    Detal. Credito
                  </GridItem>
                  <GridItem xs={3} sm={3} md={1} onClick={() => {
                    this.handleFocus(credit);
                    this.handleControlModal("statesCreditModal");
                  }} className={"cursor-pointer"}>
                    <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="like"
                    >
                      <Find/>
                    </Button>
                    Estados
                  </GridItem>
                </GridContainer>
              </div>
            )
          };
        })
      });
    }
  }

  handleGetCsv = () => {
    const {
      auth: { user }
    } = this.props;
    window.open(`${process.env.REACT_APP_API_URL}/credits/export?email=${user.correo}`, "_self");
  };

  handleFocus = (object) => {
    const {
      creditFocus
    } = this.props;
    creditFocus(object);
  };

  handleFilter = (value) => {
    const {
      client: { token },
      credit: { filter },
      creditsGetRequesting
    } = this.props;
    if (value !== filter)
      creditsGetRequesting(token, value, 1);
  };

  handleGetData = (newPaginate) => {
    const {
      client: { token },
      credit: { filter, paginate, hasMore, hasLess },
      creditsGetRequesting
    } = this.props;
    if (newPaginate === 1 && hasMore)
      creditsGetRequesting(token, filter, paginate + newPaginate);
    if (newPaginate === -1 && hasLess && paginate > 1)
      creditsGetRequesting(token, filter, paginate + newPaginate);
  };

  handleChangeCredit = (creditId, e) => {
    const {
      client: { token },
      creditChangeStateRequesting
    } = this.props;
    creditChangeStateRequesting(token, creditId, e.target.name);
  };

  handleControlModal = (modal) => {
    const {
      creditControlModal
    } = this.props;
    creditControlModal(modal);
  };

  handleGetInfo = (creditId, modal) => {
    const {
      client: { token },
      creditClientRequesting,
      creditPolicyRequesting,
      creditPaymentPlansRequesting,
      creditAgentRequesting,
      creditDocumentsRequesting,
      creditPersonRequesting,
      creditFamilyRequesting,
      creditSupplementaryDataRequesting
    } = this.props;
    switch (modal) {
      case "clientModal":
        creditClientRequesting(token, creditId);
        break;
      case "policyModal":
        creditPolicyRequesting(token, creditId);
        break;
      case "paymentPlansModal":
        creditPaymentPlansRequesting(token, creditId);
        break;
      case "agentModal":
        creditAgentRequesting(token, creditId);
        break;
      case "documentsModal":
        creditDocumentsRequesting(token, creditId);
      case "onerosoNotification":
        creditDocumentsRequesting(token, creditId);
      break;
      case "personModal":
        creditPersonRequesting(token, creditId);
      break;
      case "familyModal":
        creditFamilyRequesting(token, creditId);
      case "supplementaryDataModal":
        creditSupplementaryDataRequesting(token, creditId);
      break;
      default:  
    }
  };

  handleConfirmedPayment = (planPayment) => {
    const {
      client: { token },
      paymentPlanConfirmedPaymentRequesting
    } = this.props;
    paymentPlanConfirmedPaymentRequesting(token, planPayment);
  };

  handleSendNotification = (subject, planPayment) => {
    const {
      client: { token },
      emailNotificationCreateRequesting
    } = this.props;
    emailNotificationCreateRequesting(token, subject, planPayment.id);
  };

  handleGetEmailNotification = (planPaymentId) => {
    const {
      client: { token },
      emailNotificationsGetRequesting
    } = this.props;
    emailNotificationsGetRequesting(token, planPaymentId);
  };

  handleFilterTable = (value, column, relationship = false, model = null) => {
    const {
      creditsFilterRequesting,
      client: { token }
    } = this.props;
    creditsFilterRequesting(token, value, model, column, relationship);
  };

  render() {
    const {
      classes,
      state: { states },
      client: { token },
      credit: {
        credits, filter, search, creditsSearch, hasMore, hasLess,
        clientModal, policyModal, paymentPlansModal, agentModal,
        personModal, familyModal, supplementaryDataModal,
        documentsModal, client, policy, paymentPlans, agent, documents,
        detailCreditModal, statesCreditModal, credit, requesting , family
      },
      familyMember: { familyMember },
      supplementaryData: { supplementaryData },
      emailNotification: { warningEmails, pendingEmails, paymentPlanId, cancellationEmail }
    } = this.props;
    const hasPerson = this.props.legalPerson.person.hasOwnProperty("id") || this.props.naturalPerson.person.hasOwnProperty("id");
    if (token === "") {
      return (
        <Redirect to={"/"}/>
      );
    }
    return (
      <GridContainer>
        {clientModal && client.hasOwnProperty("id") && (
          <InfoClientModal
            controlModal={this.handleControlModal.bind(this)}
            open={clientModal}
            client={client}
          />
        )}

        {personModal && hasPerson &&
            <InfoPersonModal
              controlModal={this.handleControlModal.bind(this)}
              open={personModal}
            />
        }


        {familyModal && family.length > 0 &&
            <InfoFamilyModal
              controlModal={this.handleControlModal.bind(this)}
              open={familyModal}
            />
        }


        {supplementaryDataModal && supplementaryData.hasOwnProperty("id") &&
            <InfoSupplementaryDataModal
               controlModal={this.handleControlModal.bind(this)}
               open={supplementaryDataModal}
            />
        }

        {statesCreditModal && (
          <StatesModal
            controlModal={this.handleControlModal.bind(this)}
            open={statesCreditModal}
            credit={credit}
            changeState={this.handleChangeCredit.bind(this)}
            handleGetInfoProp={() => {
              this.handleGetInfo(credit.id , 'onerosoNotification');
              this.handleFocus(credit);
            }}
          />
        )}

        {policyModal && policy.hasOwnProperty("id") && (
          <DatePolicyModal
            controlModal={this.handleControlModal.bind(this)}
            open={policyModal}
          />
        )}

        {paymentPlansModal && paymentPlans.length > 0 && (
          <DateCreditModal
            controlModal={this.handleControlModal.bind(this)}
            open={paymentPlansModal}
            paymentPlans={paymentPlans}
            confirmedPayment={this.handleConfirmedPayment.bind(this)}
            sendNotification={this.handleSendNotification.bind(this)}
            getEmailNotifications={this.handleGetEmailNotification.bind(this)}
            paymentPlanId={paymentPlanId}
            warningEmails={warningEmails}
            pendingEmails={pendingEmails}
            cancellationEmail={cancellationEmail}
          />
        )}

        {agentModal && agent.hasOwnProperty("id") && (
          <DateAdviserModal
            controlModal={this.handleControlModal.bind(this)}
            open={agentModal}
            agent={agent}
          />
        )}

        {documentsModal && (
          <DocumentsModal
            controlModal={this.handleControlModal.bind(this)}
            open={documentsModal}
          />
        )}  


        {detailCreditModal && (
          <DetailCreditModal
            controlModal={this.handleControlModal.bind(this)}
            open={detailCreditModal}
          />
        )}
        {search !== "" && (
          creditsSearch.length > 0
            ? <GridItem xs={12}>
              <Card>
                <CardBody>
                  <ReactTable
                    data={this.state.dataSearch}
                    filterable
                    columns={this.state.columns}
                    defaultPageSize={5}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    previousText={"Anterior"}
                    nextText={"Siguiente"}
                    pageText={"Pagina"}
                    ofText={"de"}
                    rowsText={"datos"}
                    noDataText={"No existen mas datos ..."}
                    loadingText={"Cargando... "}
                    loading={requesting}
                    onFilteredChange={(value) => this.handleFilterTable(value)}
                    //loading para cargar
                  />
                </CardBody>
              </Card>
            </GridItem>
            : <NotFound/>
        )}
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AssignmentInd/>
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Mis solicitudes</h4>
              <br/>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      {filter}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={filter}
                      onChange={(input) => this.handleFilter(input.target.value)}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select"
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="Todos"
                      >
                        Todos
                      </MenuItem>
                      {states.length > 0 && states.map((state, index) => (
                        <MenuItem key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}

                                  value={state.nombre}
                        >
                          {state.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                  <FormControl fullWidth>
                    <Button color="primary" className={classes.marginRight}
                            onClick={() => this.handleGetCsv()}>
                      Descargar csv
                    </Button>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              {credits.length > 0 && (
                <ReactTable
                  data={this.state.data}
                  filterable
                  columns={this.state.columns}
                  defaultPageSize={credits.length}
                  showPaginationBottom={true}
                  className="-striped -highlight"
                  previousText={"Anterior"}
                  nextText={"Siguiente"}
                  pageText={"Pagina"}
                  ofText={"de"}
                  rowsText={"datos"}
                  noDataText={"No existen mas datos ..."}
                  loadingText={"Cargando... "}
                  loading={requesting}
                  onFilteredChange={(value) => this.handleFilterTable(value)}
                  manual
                  NextComponent={(nextText) => {
                    return (
                      <button disabled={!hasMore} className={""}
                              onClick={() => this.handleGetData(+1)}>
                        {nextText.children}
                      </button>
                    );
                  }}
                  PreviousComponent={(previousText) => {
                    return (
                      <button disabled={!hasLess} className={""}
                              onClick={() => this.handleGetData(-1)}>
                        {previousText.children}
                      </button>
                    );
                  }}
                />
              )}
              {credits.length === 0 && (
                <NotFound/>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
      ;
  }
}

//Usar el nombre que se puso arriba aca
MyRequests.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  client: state.client,
  auth: state.auth,
  state: state.state,
  credit: state.credit,
  documentType: state.documentType,
  oneroso: state.oneroso,
  legalPerson: state.legalPerson,
  naturalPerson: state.naturalPerson,
  familyMember: state.familyMember,
  supplementaryData: state.supplementaryData,
  emailNotification: state.emailNotification
});

const connected = connect(mapStateToProps, {
  creditsGetRequesting,
  statesCreditGetRequesting,
  creditFocus,
  creditControlModal,
  creditChangeStateRequesting,
  creditPersonRequesting,
  creditFamilyRequesting,
  creditSupplementaryDataRequesting,
  creditClientRequesting,
  creditPolicyRequesting,
  creditPaymentPlansRequesting,
  creditAgentRequesting,
  creditDocumentsRequesting,
  documentTypesGetRequesting,
  onerosoGetRequesting,
  paymentPlanConfirmedPaymentRequesting,
  emailNotificationCreateRequesting,
  emailNotificationsGetRequesting,
  creditsFilterRequesting,
  creditsSearchError,
  clientCleanValues,
  creditCleanValues
})(withStyles(styles)(MyRequests));
// Y aca ...
export default connected;
