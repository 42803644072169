import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/core/SvgIcon/SvgIcon";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import notificationsStyle from "./../../../assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
import Slide from "@material-ui/core/Slide";
// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
// core components
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import userProfileStyles from "../../../assets/jss/material-dashboard-pro-react/views/userProfileCompanyStyles";
import { connect } from "react-redux";
import { Form, reduxForm, Field, reset} from "redux-form";
import { customInputText } from "../../../components/inputs/typeText";
import { customInputCheckBox } from '../../../components/inputs/checkbox';
import GridItem from "../../../components/formQuote/client";
import { customInputSelect } from "../../../components/inputs/typeSelect";
import {clientUpdateRequesting} from "../../../redux/client/actions";
import {customInputDate} from "../../../components/inputs/inputDate";
import {supplementaryDataUpdateRequesting} from '../../../redux/supplementaryData/actions';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

class InfoSupplementaryData extends React.Component{

	constructor(props){
		super(props);
	}

	handleSubmit = (values) => {
		const {
			client: {token},
			clients: {client},
			supplementaryData: {supplementaryData},
			supplementaryDataUpdateRequesting
		} = this.props;
		supplementaryDataUpdateRequesting(token,values,client.id,supplementaryData.id);
	}

	render(){
		const {
			classes,
			open,
			controlModal,
			handleSubmit
		} = this.props;
		console.log(this.props);

		return <Dialog
			classes={{
				root: classes.center + " " + classes.modalRoot,
				paper: classes.modal
			}}
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => controlModal("supplementaryDataModal")}
			aria-labelledby="classic-modal-slide-title"
			aria-describedby="classic-modal-slide-description"
		>
			<CardHeader color="primary" icon>
				<CardIcon color="primary">
					<MailOutline/>
				</CardIcon>
			</CardHeader>

			<DialogTitle
				id="classic-modal-slide-title"
				disableTypography
				className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key="close"
					aria-label="Close"
					color="transparent"
					onClick={() => controlModal("supplementaryDataModal")}
				>
					<Close className={classes.modalClose}/>
				</Button>
				<h4 className={classes.modalTitle}>Datos complementarios</h4>
			</DialogTitle>
			<Form onSubmit={handleSubmit(this.handleSubmit)}>
			<DialogContent
				id="classic-modal-slide-description"
				className={classes.modalBody}>

				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody}>
					<div className="Conteiner-table">
						<table className="table-date">
							<p>Datos registrados</p>
							<tbody>
							<tr>
								<th>Declaro que mis recursos provienen de </th>
							<td>
								<Field
                                    id={"recursosProvienenDe"}
                                    name={"recursosProvienenDe"}
                                    type={"text"}
                                    label={"Indicar origin de recursos *"}
                                    autoComplete={"on"} 
                                    component={customInputText} classes={classes}
                                />
							</td>
							</tr>
							<tr>
							<th> Ciudad donde se diligencio la poliza </th>
							    <td>
								    <Field
                                        id={"ciudad"}
                                        name={"ciudad"}
                                        type={"text"}
                                        label={"Ciudad *"}
                                        autoComplete={"on"} 
                                        component={customInputText} classes={classes}
                                    />
							    </td>
							</tr>

							<tr>
								<th>Se considera usted una persona de conocimiento publico (PEPS)</th>
								<td>
									<Field
										id={"personaDeConocimientoPublico"}
										name={"personaDeConocimientoPublico"}
										type={"checkbox"}
										autoComplete={"on"}
										component={customInputCheckBox}/>
								</td>
							</tr>
							<tr>
								<th>Ejerce o ha ejercido un cargo de primer o segundo nuvel en una entidad del estado</th>
								<td>
										<Field
											id={"cargoConElEstado"}
											name={"cargoConElEstado"}
											type={"checkbox"}
											autoComplete={"on"} 
											component={customInputCheckBox} 
											classes={classes}/>

								</td>
							</tr>
							<tr>
								<th>Contrata con el estado</th>
								<td>
									<Field
										id={"contrataConElEstado"}
										name={"contrataConElEstado"}
										type={"checkbox"}
										autoComplete={"on"}
										component={customInputCheckBox}/>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
					<DialogActions className={classes.modalFooter}>
						<Button
							type={'submit'}
							color="primary"
						>
							Guardar
						</Button>
						<Button
							onClick={() => controlModal("supplementaryDataModal")}
							color="warning"
						>
							Cerrar
						</Button>
					</DialogActions>
				</DialogContent>
			</DialogContent>
			</Form>
		</Dialog>;
	}
}

InfoSupplementaryData.propTypes = {
	classes: PropTypes.object
};

const formed = reduxForm({
	form: "InfoSupplementaryData",
	enableReinitialize: true,
	reset
})(withStyles(userProfileStyles)(InfoSupplementaryData));

const mapStateToProps = state => ({
	initialValues: state.supplementaryData.supplementaryData,
	client: state.client,
	stateForm: state.form,
	clients: state.clients,
	supplementaryData: state.supplementaryData,
});

const connected = connect(mapStateToProps, {
	supplementaryDataUpdateRequesting
})(withStyles(notificationsStyle)(formed));

export default connected