import {
	DOCUMENTS_CREATE_ERROR,
	DOCUMENTS_CREATE_REQUESTING, DOCUMENTS_CREATE_SUCCESS,
	DOCUMENTS_USER_GET_ERROR,
	DOCUMENTS_USER_GET_REQUESTING,
	DOCUMENTS_USER_GET_SUCCESS,
	DOCUMENT_FINANCING_PACKAGE_CREATE_REQUESTING,
	DOCUMENT_FINANCING_PACKAGE_CREATE_SUCCESS,
	DOCUMENT_FINANCING_PACKAGE_CREATE_ERROR,
	DOCUMENT_CHECK_DIGITAL_SIGNATURE_REQUESTING,
	DOCUMENT_CHECK_DIGITAL_SIGNATURE_SUCCESS,
	DOCUMENT_CHECK_DIGITAL_SIGNATURE_ERROR,
	DOCUMENT_AUTHORIZE_CREATE_REQUESTING,
	DOCUMENT_AUTHORIZE_CREATE_SUCCESS,
	DOCUMENT_AUTHORIZE_CREATE_ERROR,
	DOCUMENT_SENT_MAIL_SIGNATURE_REQUESTING,
	DOCUMENT_SENT_MAIL_SIGNATURE_SUCCESS,
	DOCUMENT_SENT_MAIL_SIGNATURE_ERROR,
} from "./constants";

export const documentsUserGetRequesting = (token, userId) => ({
	type: DOCUMENTS_USER_GET_REQUESTING,
	token, userId,
});

export const documentsUserGetSuccess = (documents) => ({
	type: DOCUMENTS_USER_GET_SUCCESS,
	documents,
});

export const documentsUserGetError = (error) => ({
	type: DOCUMENTS_USER_GET_ERROR,
	error,
});

export const documentsCreateRequesting = (token, objectName, objectId, values) => ({
	type: DOCUMENTS_CREATE_REQUESTING,
	token, objectName, objectId, values,
});

export const documentsCreateSuccess = () => ({
	type: DOCUMENTS_CREATE_SUCCESS,
});

export const documentsCreateError = (error) => ({
	type: DOCUMENTS_CREATE_ERROR,
	error,
});

export const documentFinancingPackageCreateRequesting = (token, creditId) => ({
	type: DOCUMENT_FINANCING_PACKAGE_CREATE_REQUESTING,
	token,
	creditId
});

export const documentFinancingPackageCreateSuccess = () => ({
	type: DOCUMENT_FINANCING_PACKAGE_CREATE_SUCCESS
});

export const documentFinancingPackageCreateError = (error) => ({
	type: DOCUMENT_FINANCING_PACKAGE_CREATE_ERROR,
	error
});


export const documentAuthorizeCreateRequesting = (token, creditId) => ({
	type: DOCUMENT_AUTHORIZE_CREATE_REQUESTING,
	token,
	creditId
}); 

export const documentAuthorizeCreateSuccess = () => ({
	type: DOCUMENT_AUTHORIZE_CREATE_SUCCESS
});

export const documentAuthorizeCreateError = (error) => ({
	type: DOCUMENT_AUTHORIZE_CREATE_ERROR,
	error 
});

export const documentCheckDigitalSignatureRequesting = (token, documentId) => ({
	type: DOCUMENT_CHECK_DIGITAL_SIGNATURE_REQUESTING,
	token,
	documentId
});

export const documentCheckDigitalSignatureSuccess = (verifyData,documentId) => ({
	type: DOCUMENT_CHECK_DIGITAL_SIGNATURE_SUCCESS,
	verifyData,
	documentId
});

export const documentCheckDigitalSignatureError = (error) => ({
	type: DOCUMENT_CHECK_DIGITAL_SIGNATURE_ERROR,
	error
});

export const documentSendMailSignatureRequesting = (token, creditId) => ({
	type: DOCUMENT_SENT_MAIL_SIGNATURE_REQUESTING,
	token,
	creditId
});

export const documentSendMailSignatureSuccess = () => ({
	type: DOCUMENT_SENT_MAIL_SIGNATURE_SUCCESS
});

export const documentSendMailSignatureError = (error) => ({
	type: DOCUMENT_SENT_MAIL_SIGNATURE_ERROR,
	error
}); 