export const CREDITS_USER_GET_REQUESTING = 'CREDITS_USER_GET_REQUESTING';
export const CREDITS_USER_GET_SUCCESS = 'CREDITS_USER_GET_SUCCESS';
export const CREDITS_USER_GET_ERROR = 'CREDITS_USER_GET_ERROR';

export const CREDITS_CLIENT_GET_REQUESTING = 'CREDITS_CLIENT_GET_REQUESTING';
export const CREDITS_CLIENT_GET_SUCCESS = 'CREDITS_CLIENT_GET_SUCCESS';
export const CREDITS_CLIENT_GET_ERROR = 'CREDITS_CLIENT_GET_ERROR';

export const CREDITS_GET_REQUESTING = 'CREDITS_GET_REQUESTING';
export const CREDITS_GET_SUCCESS = 'CREDITS_GET_SUCCESS';
export const CREDITS_GET_ERROR = 'CREDITS_GET_ERROR';

export const CREDITS_SEARCH_REQUESTING = 'CREDITS_SEARCH_REQUESTING';
export const CREDITS_SEARCH_SUCCESS = 'CREDITS_SEARCH_SUCCESS';
export const CREDITS_SEARCH_ERROR = 'CREDITS_SEARCH_ERROR';

export const CREDIT_CHANGE_STATE_REQUESTING = 'CREDIT_CHANGE_STATE_REQUESTING';
export const CREDIT_CHANGE_STATE_SUCCESS = 'CREDIT_CHANGE_STATE_SUCCESS';
export const CREDIT_CHANGE_STATE_ERROR = 'CREDIT_CHANGE_STATE_ERROR';

export const CREDIT_CLIENT_REQUESTING = 'CREDIT_CLIENT_REQUESTING';
export const CREDIT_CLIENT_SUCCESS = 'CREDIT_CLIENT_SUCCESS';
export const CREDIT_CLIENT_ERROR = 'CREDIT_CLIENT_ERROR';

export const CREDIT_POLICY_REQUESTING = 'CREDIT_POLICY_REQUESTING';
export const CREDIT_POLICY_SUCCESS = 'CREDIT_POLICY_SUCCESS';
export const CREDIT_POLICY_ERROR = 'CREDIT_POLICY_ERROR';

export const CREDIT_PAYMENT_PLANS_REQUESTING = 'CREDIT_PAYMENT_PLANS_REQUESTING';
export const CREDIT_PAYMENT_PLANS_SUCCESS = 'CREDIT_PAYMENT_PLANS_SUCCESS';
export const CREDIT_PAYMENT_PLANS_ERROR = 'CREDIT_PAYMENT_PLANS_ERROR';

export const CREDIT_AGENT_REQUESTING = 'CREDIT_AGENT_REQUESTING';
export const CREDIT_AGENT_SUCCESS = 'CREDIT_AGENT_SUCCESS';
export const CREDIT_AGENT_ERROR = 'CREDIT_AGENT_ERROR';

export const CREDIT_DOCUMENTS_REQUESTING = 'CREDIT_DOCUMENTS_REQUESTING';
export const CREDIT_DOCUMENTS_SUCCESS = 'CREDIT_DOCUMENTS_SUCCESS';
export const CREDIT_DOCUMENTS_ERROR = 'CREDIT_DOCUMENTS_ERROR';

export const CREDIT_CREATE_REQUESTING = 'CREDIT_CREATE_REQUESTING';
export const CREDIT_CREATE_SUCCESS = 'CREDIT_CREATE_SUCCESS';
export const CREDIT_CREATE_ERROR = 'CREDIT_CREATE_ERROR';

export const CREDIT_UPDATE_REQUESTING = "CREDIT_UPDATE_REQUESTING";
export const CREDIT_UPDATE_SUCCESS = "CREDIT_UPDATE_SUCCESS";
export const CREDIT_UPDATE_ERROR = "CREDIT_UPDATE_ERROR";

export const CREDIT_PAYMENT_PLAN_UPDATE = "CREDIT_PAYMENT_PLAN_UPDATE";

export const PLAN_PAYMENTS_CREDIT = "PLAN_PAYMENTS_CREDIT";
export const PLAN_PAYMENTS_CREDIT_RESET = "PLAN_PAYMENTS_CREDIT_RESET";

export const CREDITS_FILTER_REQUESTING = "CREDITS_FILTER_REQUESTING";
export const CREDITS_FILTER_SUCCESS = "CREDITS_FILTER_SUCCESS";
export const CREDITS_FILTER_ERROR = "CREDITS_FILTER_ERROR";

export const CREDIT_CONTROL_MODAL = 'CREDIT_CONTROL_MODAL';
export const CREDIT_FOCUS = 'CREDIT_FOCUS';
export const CREDIT_RESET_STATES = 'CREDIT_RESET_STATES';

export const CREDIT_CLEAN_VALUES = "CREDIT_CLEAN_VALUES";
export const CREDIT_PERSON_REQUESTING = "CREDIT_PERSON_REQUESTING";
export const CREDIT_PERSON_SUCCESS = "CREDIT_PERSON_SUCCESS";
export const CREDIT_PERSON_ERROR = "CREDIT_PERSON_ERROR";
export const CREDIT_FAMILY_REQUESTING = "CREDIT_FAMILY_REQUESTING";
export const CREDIT_FAMILY_SUCCESS = "CREDIT_FAMILY_SUCCESS";
export const CREDIT_FAMILY_ERROR = "CREDIT_FAMILY_ERROR";
export const CREDIT_SUPPLEMENTARY_DATA_REQUESTING = "CREDIT_SUPPLEMENTARY_DATA_REQUESTING";
export const CREDIT_SUPPLEMENTARY_DATA_SUCCESS = "CREDIT_SUPPLEMENTARY_DATA_SUCCESS";
export const CREDIT_SUPPLEMENTARY_DATA_ERROR = "CREDIT_SUPPLEMENTARY_DATA_ERROR";