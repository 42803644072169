import {call, put, all, takeEvery} from 'redux-saga/effects';
import {handleApiErrors} from "../../../commons/errors/apiErrors";
import {logoutGetError, logoutGetSuccess} from "./actions";
import {LOGOUT_GET_REQUESTING} from "./constants";
import {clientUnSet} from "../client/actions";
import { ErrorComponent } from "../../../commons/errors/errors";
import { toastr } from "react-redux-toastr";
import React from "react";

const logoutUrl = `${process.env.REACT_APP_API_URL}/logout`;

const showError = (error) => {
	let toastOptions = {
		component: (
			<ErrorComponent message={error}/>
		)
	};
	toastr.error("Error", toastOptions);
};

const logoutGetApi = (token) => {
    return fetch(logoutUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
	        if (json.code === 422)
		        throw json.data;
	        if (json.code === 400)
		        throw [json.data];
	        if (json.code === 200) {
		        localStorage.removeItem('@danko:admin');
		        return json.data.data;
	        }
	        throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* logoutGetFlow(action) {
    try {
        const {token} = action;
        yield call(logoutGetApi, token);
        yield put(logoutGetSuccess());
        yield put(clientUnSet());
    } catch (error) {
        yield put(logoutGetError(error));
        showError(error);
    }
}

function* logoutWatcher() {
    yield all([
        takeEvery(LOGOUT_GET_REQUESTING, logoutGetFlow),
    ])
}

export default logoutWatcher;