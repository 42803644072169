import React from "react";
import { call, put, all, takeEvery } from "redux-saga/effects";
import { handleApiErrors } from "../../commons/errors/apiErrors";
import { ErrorComponent } from "../../commons/errors/errors";
import { toastr } from "react-redux-toastr";
import {
	userChangeStateError,
	userChangeStateSuccess,
	userControlModal,
	userCreateError,
	userCreateSuccess,
	userResetStates, usersFilterTableError, usersFilterTableSuccess,
	usersGetError,
	usersGetSuccess, usersSearchError, usersSearchSuccess, userUpdateError, userUpdateSuccess
} from "./actions";
import {
	USER_CHANGE_STATE_REQUESTING,
	USER_CREATE_REQUESTING,
	USER_UPDATE_REQUESTING, USERS_FILTER_TABLE_REQUESTING,
	USERS_GET_REQUESTING, USERS_SEARCH_REQUESTING
} from "./constants";

const userUrl = `${process.env.REACT_APP_API_URL}/users`;

const showError = (error) => {
	let toastOptions = {
		component: (
			<ErrorComponent message={error}/>
		)
	};
	toastr.error("Error", toastOptions);
};

const usersGetApi = (token, filter, paginate) => {
	return fetch(`${userUrl}/filter/${filter}?page=${paginate}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`
		}
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* usersGetFlow(action) {
	try {
		const { token, filter, paginate } = action;
		const users = yield call(usersGetApi, token, filter, paginate);
		yield put(usersGetSuccess(users));
		yield put(userResetStates());
	} catch (error) {
		yield put(usersGetError(error));
		// showError(error);
	}
}

const userCreateApi = (token, values) => {
	let body = new FormData();
	body.append("name", values.nombre || null);
	body.append("email", values.correo || null);
	body.append("password", values.contraseña || null);
	body.append("identificationType", values.tipo_identificacion || null);
	body.append("identification", values.identificacion || null);
	body.append("phone", values.telefono || null);
	body.append("address", values.direccion || null);
	body.append("city", values.ciudad || null);
	body.append("photos_length", values.hasOwnProperty("photos") ? values.photos.length : 0);
	if (values.hasOwnProperty("photos") && values.photos.length > 0) {
		values.photos.map((fileItem, index) => body.append(`photo_${index}`, fileItem.file));
	}
	Object.keys(values).map(key => {
		if (key.indexOf("document_") >= 0) {
			body.append(key.split("document_")[1].replace(/\s/g, ""), values[key][0].file);
		}
	});
	return fetch(`${userUrl}`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`
		},
		body: body
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* userCreateFlow(action) {
	try {
		const { token, values } = action;
		const user = yield call(userCreateApi, token, values);
		yield put(userCreateSuccess(user));
		yield put(userControlModal("addModal"));
		yield put(userResetStates());
		toastr.success("Usuario creado", "El usuario fue creado con exito.");
	} catch (error) {
		yield put(userCreateError(error));
		showError(error);
	}
}

const userUpdateApi = (token, values) => {
	let body = new FormData();
	body.append("_method", "PUT");
	body.append("name", values.nombre || "");
	body.append("email", values.correo || "");
	body.append("identificationType", values.tipo_identificacion.hasOwnProperty("id") ? values.tipo_identificacion.id : values.tipo_identificacion);
	body.append("identification", values.identificacion || "");
	body.append("phone", values.telefono || "");
	body.append("address", values.direccion || "");
	body.append("city", values.ciudad.hasOwnProperty("id") ? values.ciudad.id : values.ciudad);
	body.append("photos_length", values.hasOwnProperty("photos") ? values.photos.length : 0);
	if (values.hasOwnProperty("photos") && values.photos.length > 0) {
		values.photos.map((fileItem, index) => body.append(`photo_${index}`, fileItem.file));
	}
	// Object.keys(values).map(key => {
	// 	if (key.indexOf('document_') >= 0) {
	// 		body.append(key.split('document_')[1].replace(/\s/g,''), values[key][0].file);
	// 	}
	// });
	if (values.hasOwnProperty("contraseña")) {
		body.append("password", values.contraseña);
	}
	return fetch(`${userUrl}/${values.id}`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`
		},
		body: body
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* userUpdateFlow(action) {
	try {
		const { token, values } = action;
		const user = yield call(userUpdateApi, token, values);
		yield put(userUpdateSuccess(user));
		yield put(userControlModal("editModal"));
		yield put(userResetStates());
		toastr.success("Usuario actualizado", "El usuario fue actualizado con exito.");
	} catch (error) {
		yield put(userUpdateError(error));
		showError(error);
	}
}

const userChangeStateApi = (token, userId) => {
	return fetch(`${userUrl}/state/${userId}`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${token}`
		}
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* userChangeStateFlow(action) {
	try {
		const { token, userId } = action;
		const user = yield call(userChangeStateApi, token, userId);
		yield put(userChangeStateSuccess(user));
		yield put(userResetStates());
		toastr.success(`Usuario ${user.estado.nombre}`, `El usuario ha sido ${user.estado.nombre} con exito.`);
	} catch (error) {
		yield put(userChangeStateError(error));
		showError(error);
	}
}

const usersSearchApi = (token, values) => {
	let body = {
		search: values.search
	};
	return fetch(`${userUrl}/search`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`
		},
		body: JSON.stringify(body)
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* usersSearchFlow(action) {
	try {
		const { token, values } = action;
		const users = yield call(usersSearchApi, token, values);
		yield put(usersSearchSuccess(users));
		yield put(userResetStates());
	} catch (error) {
		yield put(usersSearchError(error));
		showError(error);
	}
}

const usersFilterTableApi = (token, value, column) => {
	let body = {
		search: value,
		column
	};
	return fetch(`${userUrl}/searchFilter`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(body)
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* usersFilterTableFlow(action) {
	try {
		const {token, value, column} = action;
		const users = yield call(usersFilterTableApi, token, value, column);
		if (users.length === 0) {
			toastr.info("Sin resultados", "No se encontraron resultados para su parametro de busqueda.");
		}
		yield put(usersFilterTableSuccess(users));
		yield put(userResetStates());
	} catch (error) {
		yield put(usersFilterTableError(error));
		showError(error);
	}
}

function* userWatcher() {
	yield all([
		takeEvery(USERS_GET_REQUESTING, usersGetFlow),
		takeEvery(USER_CREATE_REQUESTING, userCreateFlow),
		takeEvery(USER_UPDATE_REQUESTING, userUpdateFlow),
		takeEvery(USER_CHANGE_STATE_REQUESTING, userChangeStateFlow),
		takeEvery(USERS_SEARCH_REQUESTING, usersSearchFlow),
		takeEvery(USERS_FILTER_TABLE_REQUESTING, usersFilterTableFlow)
	]);
}

export default userWatcher;