import {
  PAYMENT_PLAN_CONFIRMED_PAYMENT_ERROR,
  PAYMENT_PLAN_CONFIRMED_PAYMENT_REQUESTING,
  PAYMENT_PLAN_CONFIRMED_PAYMENT_SUCCESS,
  PAYMENT_PLAN_UPDATE_ERROR,
  PAYMENT_PLAN_UPDATE_REQUESTING,
  PAYMENT_PLAN_UPDATE_SUCCESS
} from "./constants";

const initialState = {
  requesting: false,
  success: false,
  error: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_PLAN_CONFIRMED_PAYMENT_REQUESTING:
      return {
        ...state,
        requesting: true,
        success: false,
        error: ""
      };
    case PAYMENT_PLAN_CONFIRMED_PAYMENT_SUCCESS:
      return {
        ...state,
        requesting: false,
        success: true
      };
    case PAYMENT_PLAN_CONFIRMED_PAYMENT_ERROR:
      return {
        ...state,
        requesting: false,
        error: action.error
      };
    case PAYMENT_PLAN_UPDATE_REQUESTING:
      return {
        ...state,
        requesting: true,
        success: false,
        error: ""
      };
    case PAYMENT_PLAN_UPDATE_SUCCESS:
      return {
        ...state,
        requesting: false,
        success: true
      };
    case PAYMENT_PLAN_UPDATE_ERROR:
      return {
        ...state,
        requesting: false,
        error: action.error
      };
    default:
      return state;
  }
};

export default reducer;