/*!
=========================================================
* Developer DigitalParchapp V.1
=========================================================
*/
import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import ShowPasswordIcon from '@material-ui/icons/Visibility';
import HidePasswordIcon from '@material-ui/icons/VisibilityOff';
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import Background from "./../../assets/img/19154-[Convertido].jpg";
import Logo from './../../assets/img/LogoNotLetter.png';
//import Logo from "./../../assets/img/LogoDanko.png";
//Integration
import { Field, Form, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { loginPostRequesting } from "../../redux/auth/login/actions";
import { Redirect } from "react-router-dom";

const customInputEmail = ({ input, label, id, name, classes, meta: { touched, error }, ...rest }) => {
	return (
		<CustomInput
			{...input}
			{...rest}
			labelText={label}
			id={id}
			name={name}
			formControlProps={{
				fullWidth: true
			}}
			inputProps={{
				onChange: input.onChange,
				endAdornment: (
					<InputAdornment position="end">
						<Email className={classes.inputAdornmentIcon}/>
					</InputAdornment>
				)
			}}
		/>
	);
};

const customInputPassword = ({ input, show, setShow, label, id, name, classes, meta: { touched, error }, ...rest }) => {
	return (
		<CustomInput
			{...input}
			{...rest}
			labelText={label}
			id={id}
			formControlProps={{
				fullWidth: true
			}}
			inputProps={{
				onChange: input.onChange,
				endAdornment: (
                  <InputAdornment position="end">
                    <a href="#">
                      {show && <ShowPasswordIcon onClick={() => {setShow(false)}} className={classes.inputAdornmentIcon}>
                        lock_outline
                      </ShowPasswordIcon>}
                      {!show && <HidePasswordIcon onClick={() => {setShow(true)}} className={classes.inputAdornmentIcon}>
                        lock_outline
                      </HidePasswordIcon>}
                    </a>
                  </InputAdornment>
                ),
                type: show ? "text" : "password",
				autoComplete: "off"
			}}
		/>
	);
};

class LoginPage extends React.Component {

	constructor(props) {
		super(props);
		// we use this to make the card to appear after the page has been rendered
		this.state = {
			cardAnimaton: "cardHidden"
		};

		this.handleSetShow = this.handleSetShow.bind(this);
	}

	componentDidMount() {
		// we add a hidden class to the card and after 700 ms we delete it and the transition appears
		this.timeOutFunction = setTimeout(
			function() {
				this.setState({ cardAnimaton: "" });
			}.bind(this),
			700
		);
	}

	componentWillUnmount() {
		clearTimeout(this.timeOutFunction);
		this.timeOutFunction = null;
	}

	handleSubmit = (values) => {
		const {
			loginPostRequesting
		} = this.props;
		loginPostRequesting(values);
	};

	handleSetShow = (value) => {
        this.setState({
          show: value
        });
    };

	render() {
		const {
			classes,
			handleSubmit,
			client: { token }
		} = this.props;
		if (token !== "") {
			return <Redirect to={"admin/cotizador"}/>;
		}
		// if (token !== "") {
		// 	return <Redirect to={"admin/asesor"}/>;
		// }
		return (
			<div className="login-back" style={{
				backgroundImage: `url(${Background})`, backgroundPosition: "center",
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat"
			}}>
				<div className={classes.container}>
					<GridContainer justify="center">
						<GridItem xs={12} sm={6} md={4}>
							<Form onSubmit={handleSubmit(this.handleSubmit)}>
								<Card login className={classes[this.state.cardAnimaton]}>

									<CardHeader
										className={`${classes.cardHeader} ${classes.textCenter}`}
										color="primary"
									>
										<GridContainer className={classes.logoContainer}>

											<GridItem className="style_img_login" xs={12} sm={6} md={12}>
												<img src={Logo} alt="Logo"/>
											</GridItem>

											<GridItem className="style_text" xs={12} sm={6} md={12}>
												{/*<h2 className={classes.cardTitle}>Danko</h2>*/}
												<h4 className={classes.cardTitle}>Login BackOffice</h4>
											</GridItem>
										</GridContainer>
									</CardHeader>
									<CardBody>


										<GridItem className="style_text" xs={12} sm={12} md={12}>
											<h3 className={classes.cardTitle}>Bienvenidos</h3>
										</GridItem>

										<Field classes={classes} id={"email"} name={"email"} label={"Email..."}
										       type={"text"} component={customInputEmail}/>
										<Field classes={classes} id={"password"} name={"password"}
										       label={"Password"}
										       show={this.state.show} setShow={this.handleSetShow}
										       type={"password"} component={customInputPassword}/>
									</CardBody>
									<CardFooter className={classes.justifyContentCenter}>
										<Button color="primary" block type={"submit"}>
											Ingresar
										</Button>
									</CardFooter>
									{/*<GridItem className="style_text" xs={12} sm={12} md={12}>*/}
										{/*<h3 className={classes.cardTitle}>Terminos y condicones</h3>*/}
									{/*</GridItem>*/}
								</Card>
							</Form>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		);
	}
}

LoginPage.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	client: state.client,
	login: state.login
});

const connected = connect(mapStateToProps, {
	loginPostRequesting
})(withStyles(loginPageStyle)(LoginPage));

const formed = reduxForm({
	form: "login"
})(connected);

export default formed;
