// /*!// =========================================================
// * Developer DigitalParchapp V.1
// =========================================================// */
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/core/SvgIcon/SvgIcon";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import notificationsStyle from "../../../assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
import Slide from "@material-ui/core/Slide";
// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
// core components
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
// Integration
import { clientUpdateRequesting } from "../../../redux/client/actions";
import { connect } from "react-redux";
import { Form, reduxForm, Field } from "redux-form";
import { customInputText } from "../../../components/inputs/typeText";
import { customInputSelect } from "../../../components/inputs/typeSelect";
import { citiesGetRequesting } from "../../../redux/city/actions";
import { identificationTypesGetRequesting } from "../../../redux/identificationType/actions";
import { customInputDate } from "../../../components/inputs/inputDate";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class EditCustomers extends React.Component {

	constructor(props) {
		super(props);
		const {
			citiesGetRequesting,
			identificationTypesGetRequesting,
		} = this.props;
		citiesGetRequesting();
		identificationTypesGetRequesting();
	};

	handleSubmit = (values) => {
		const {
			client: { token },
			clientUpdateRequesting
		} = this.props;
		clientUpdateRequesting(token, values);
	};

    render() {
	    const {
		    classes,
		    open,
		    controlModal,
		    handleSubmit,
		    city: { cities },
		    identificationType: { identificationTypes },
	    } = this.props;
        return <Dialog
            classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal
            }}
            open={open }
            TransitionComponent={Transition}
            keepMounted
            onClose={() => controlModal("editModal")}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
        >
            <CardHeader color="primary" icon>
                <CardIcon color="primary">
                    <MailOutline/>
                </CardIcon>
            </CardHeader>

            <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}>
                <Button
                    justIcon
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="transparent"
                    onClick={() => controlModal("editModal")}
                >
                    <Close className={classes.modalClose}/>
                </Button>
                <h4 className={classes.modalTitle}>Editar cliente</h4>
            </DialogTitle>
            <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}>
	            <Form onSubmit={handleSubmit(this.handleSubmit)}>
		            <Field id={"nombres"} name={"nombres"} type={"text"} label={"Nombres *"} autoComplete={"off"}
		                   component={customInputText}/>
		            <Field id={"apellidos"} name={"apellidos"} type={"text"} label={"Apellidos *"} autoComplete={"off"}
		                   component={customInputText}/>
		            {identificationTypes.length > 0 && (
			            <Field id={"tipo_identificacion"} name={"tipo_identificacion"} type={"select"}
			                   label={"Tipo de identificación *"}
			                   autoComplete={"on"} component={customInputSelect} classes={classes}
			                   data={identificationTypes}/>
		            )}
		            <Field id={"identificacion"} name={"identificacion"} type={"number"} label={"Identificación *"}
		                   autoComplete={"off"}
		                   component={customInputText}/>
		            <Field id={"correo"} name={"correo"} type={"email"} label={"Correo *"} autoComplete={"off"}
		                   component={customInputText}/>
		            <Field id={"telefono"} name={"telefono"} type={"number"} label={"Telefono *"} autoComplete={"off"}
		                   component={customInputText}/>
		            <Field id={"direccion"} name={"direccion"} type={"text"} label={"Dirección *"} autoComplete={"off"}
		                   component={customInputText}/>
		            {cities.length > 0 && (
			            <Field id={"ciudad"} name={"ciudad"} type={"select"} label={"Ciudad *"}
			                   autoComplete={"on"} component={customInputSelect} classes={classes} data={cities}/>
		            )}
		            <Field id={"fecha_nacimiento"} name={"fecha_nacimiento"}
		                   type={"date"}
		                   label={"Fecha de nacimiento *"} component={customInputDate}/>
		            <br/>
		            <div className={classes.formCategory}>
			            <small>*</small>
			            Campos requeridos
		            </div>
		            <DialogActions className={classes.modalFooter}>
			            <Button onClick={() => controlModal("editModal")} color="warning">
				            Cancelar
			            </Button>
			            <Button color="primary" className={classes.registerButton} type={"submit"}>
				            Aceptar
			            </Button>
		            </DialogActions>
	            </Form>

            </DialogContent>
        </Dialog>

    }
}

EditCustomers.propTypes = {
    classes: PropTypes.object
};

const formed = reduxForm({
	form: "editClient",
	enableReinitialize: true
})(withStyles(notificationsStyle)(EditCustomers));

const mapStateToProps = state => ({
	client: state.client,
	clients: state.clients,
	city: state.city,
	identificationType: state.identificationType,
	initialValues: state.clients.client
});

const connected = connect(mapStateToProps, {
	clientUpdateRequesting,
	citiesGetRequesting,
	identificationTypesGetRequesting,
})(formed);

export default connected;