import {
	USER_CHANGE_STATE_ERROR,
	USER_CHANGE_STATE_REQUESTING,
	USER_CHANGE_STATE_SUCCESS,
	USER_CONTROL_MODAL,
	USER_CREATE_ERROR,
	USER_CREATE_REQUESTING,
	USER_CREATE_SUCCESS,
	USER_FOCUS,
	USER_RESET_STATES,
	USER_UPDATE_ERROR,
	USER_UPDATE_REQUESTING,
	USER_UPDATE_SUCCESS, USERS_FILTER_TABLE_ERROR,
	USERS_FILTER_TABLE_REQUESTING, USERS_FILTER_TABLE_SUCCESS,
	USERS_GET_ERROR,
	USERS_GET_REQUESTING,
	USERS_GET_SUCCESS,
	USERS_SEARCH_ERROR,
	USERS_SEARCH_REQUESTING,
	USERS_SEARCH_SUCCESS
} from "./constants";

const initialState = {
	requesting: false,
	success: false,
	error: "",
	filter: "",
	search: "",
	paginate: 0,
	hasMore: false,
	hasLess: false,
	users: [],
	user: {},
	usersSearch: [],
	addModal: false,
	editModal: false,
	creditsModal: false,
	documentsModal: false,
	filtered: [],
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case USERS_GET_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: "",
				filter: action.filter,
				paginate: action.paginate
			};
		case USERS_GET_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				users: action.users,
				hasMore: action.users.length > 0,
				hasLess: state.paginate > 1
			};
		case USERS_GET_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error
			};
		case USER_CREATE_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: ""
			};
		case USER_CREATE_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				users: [action.user, ...state.users],
				usersSearch: state.usersSearch.length > 0 ? [action.user, ...state.usersSearch] : state.usersSearch
			};
		case USER_CREATE_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error
			};
		case USER_UPDATE_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: ""
			};
		case USER_UPDATE_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				users: state.users.map((user) => user.id === action.user.id ? action.user : user),
				usersSearch: state.usersSearch.length > 0 ? state.usersSearch.map((user) => user.id === action.user.id ? action.user : user) : state.usersSearch
			};
		case USER_UPDATE_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error
			};
		case USER_CHANGE_STATE_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: ""
			};
		case USER_CHANGE_STATE_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				users: state.users.map((user) => user.id === action.user.id ? action.user : user),
				usersSearch: state.usersSearch.length > 0 ? state.usersSearch.map((user) => user.id === action.user.id ? action.user : user) : state.usersSearch
			};
		case USER_CHANGE_STATE_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error
			};
		case USERS_SEARCH_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				search: action.values.search,
				error: ""
			};
		case USERS_SEARCH_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				usersSearch: action.users
			};
		case USERS_SEARCH_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error,
				search: ""
			};
		case USERS_FILTER_TABLE_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: ""
			};
		case USERS_FILTER_TABLE_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				users: action.users.length === 0 ? state.users : action.users,
			};
		case USERS_FILTER_TABLE_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error,
			};
		case USER_CONTROL_MODAL:
			return {
				...state,
				addModal: action.modal === "addModal" ? !state.addModal : state.addModal,
				editModal: action.modal === "editModal" ? !state.editModal : state.editModal,
				creditsModal: action.modal === "creditsModal" ? !state.creditsModal : state.creditsModal,
				documentsModal: action.modal === "documentsModal" ? !state.documentsModal : state.documentsModal,
			};
		case USER_FOCUS:
			return {
				...state,
				user: action.user
			};
		case USER_RESET_STATES:
			return {
				...state,
				requesting: false,
				success: false,
				error: ""
			};
		default:
			return state;
	}
};

export default reducer;