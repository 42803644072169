/*!
=========================================================
* Developer DigitalParchapp V.1
=========================================================
*/
import {
  cardTitle,
  grayColor,
  primaryColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const userProfileCompany = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    "& small": {
      fontSize: "80%",
      fontWeight: "400"
    }
  },
  TitleH1: {
    marginTop: "10px",
    color: grayColor[0] + " !important",
    textAlign: "center",
    fontSize:"18px",
    fontWeight:"600"
  },
  TitleH1Left: {
    marginTop: "10px",
    color: grayColor[0] + " !important",
    textAlign: "left",
    fontSize:"18px",
    fontWeight:"600"
  },
  TitleH2: {
    marginTop: "10px",
    color: grayColor[0] + " !important",
    textAlign: "center",
    fontSize:"15px"
  },
  TitleH3: {
    marginTop: "10px",
    color: primaryColor[1] + " !important",
    textAlign: "center",
    fontSize:"15px"
  },
  Textparagraph: {
  marginTop: "10px",
      color: grayColor[0] + " !important",
      textAlign: "center",
      fontSize:"14px"
},

  description: {
    color: grayColor[0]
  },
  updateProfileButton: {
    float: "right",
    marginLeft: 15,
    marginTop:10
  }
};
export default userProfileCompany;
