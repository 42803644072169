import React from "react";
import { call, put, all, takeEvery } from "redux-saga/effects";
import { handleApiErrors } from "../../commons/errors/apiErrors";
import { ErrorComponent } from "../../commons/errors/errors";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import {
  sendSmsSuccess,
  sendSmsError
} from "./actions";
import { SEND_SMS_REQUESTING } from "./constants";

const showError = (error) => {
  let toastOptions = {
    component: (
      <ErrorComponent message={error}/>
    )
  };
  toastr.error("Error", toastOptions);
};

const baseUrl = `${process.env.REACT_APP_API_URL}`;

const sendSmsApi = (token, paymentId) => {
  return fetch(`${baseUrl}/send/sms/${paymentId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  })
    .then(handleApiErrors)
    .then(response => response.json())
    .then(json => {
      if (json.code === 422)
        throw json.data;
      if (json.code === 400)
        throw [json.data];
      if (json.code === 200)
        return json.data.data;
      throw json.data;
    }).catch((error) => {
      throw error;
    });
};

function* sendSmsFlow(action) {
  try {
    const { token, paymentId } = action;
    const res = yield call(sendSmsApi, token, paymentId);
    yield put(sendSmsSuccess());
      toastr.success(`Enviado`, `Sms enviado exitosamente.`);
  } catch (error) {
    yield put(sendSmsError(error));
    showError(error);
  }
}

function* smsWatcher() {
  yield all([
    takeEvery(SEND_SMS_REQUESTING, sendSmsFlow),
  ]);
}

export default smsWatcher;
