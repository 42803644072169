import {
  CREDIT_AGENT_ERROR,
  CREDIT_AGENT_REQUESTING,
  CREDIT_AGENT_SUCCESS,
  CREDIT_CHANGE_STATE_ERROR,
  CREDIT_CHANGE_STATE_REQUESTING,
  CREDIT_CHANGE_STATE_SUCCESS,
  CREDIT_CLIENT_ERROR,
  CREDIT_CLIENT_REQUESTING,
  CREDIT_CLIENT_SUCCESS,
  CREDIT_CONTROL_MODAL, CREDIT_CREATE_ERROR,
  CREDIT_CREATE_REQUESTING, CREDIT_CREATE_SUCCESS,
  CREDIT_DOCUMENTS_ERROR,
  CREDIT_DOCUMENTS_REQUESTING,
  CREDIT_DOCUMENTS_SUCCESS, CREDITS_FILTER_REQUESTING,
  CREDIT_FOCUS, CREDIT_PAYMENT_PLAN_UPDATE,
  CREDIT_PAYMENT_PLANS_ERROR,
  CREDIT_PAYMENT_PLANS_REQUESTING,
  CREDIT_PAYMENT_PLANS_SUCCESS,
  CREDIT_POLICY_ERROR,
  CREDIT_POLICY_REQUESTING,
  CREDIT_POLICY_SUCCESS,
  CREDIT_RESET_STATES, CREDIT_UPDATE_ERROR, CREDIT_UPDATE_REQUESTING, CREDIT_UPDATE_SUCCESS,
  CREDITS_CLIENT_GET_ERROR,
  CREDITS_CLIENT_GET_REQUESTING,
  CREDITS_CLIENT_GET_SUCCESS,
  CREDITS_GET_ERROR,
  CREDITS_GET_REQUESTING,
  CREDITS_GET_SUCCESS,
  CREDITS_SEARCH_ERROR,
  CREDITS_SEARCH_REQUESTING,
  CREDITS_SEARCH_SUCCESS,
  CREDITS_USER_GET_ERROR,
  CREDITS_USER_GET_REQUESTING,
  CREDITS_USER_GET_SUCCESS, PLAN_PAYMENTS_CREDIT, CREDITS_FILTER_SUCCESS, CREDITS_FILTER_ERROR, PLAN_PAYMENTS_CREDIT_RESET,
  CREDIT_CLEAN_VALUES,
  CREDIT_PERSON_REQUESTING,
  CREDIT_PERSON_SUCCESS,
  CREDIT_PERSON_ERROR,
  CREDIT_FAMILY_REQUESTING,
  CREDIT_FAMILY_SUCCESS,
  CREDIT_FAMILY_ERROR,
  CREDIT_SUPPLEMENTARY_DATA_REQUESTING,
  CREDIT_SUPPLEMENTARY_DATA_SUCCESS,
  CREDIT_SUPPLEMENTARY_DATA_ERROR
} from "./constants";
import moment from "moment";

moment.locale("es");

const initialState = {
  requesting: false,
  success: false,
  error: "",
  creditsUser: [],
  creditsClient: [],
  hasMore: false,
  hasLess: false,
  filter: "",
  paginate: 0,
  credits: [],
  creditsSearch: [],
  search: "",
  credit: {},
  family: [],
  personModal: false,
  familyModal: false,
  supplementaryDataModal: false,
  clientModal: false,
  policyModal: false,
  paymentPlansModal: false,
  agentModal: false,
  documentsModal: false,
  detailCreditModal: false,
  statesCreditModal: false,
  client: {},
  policy: {},
  paymentPlans: [],
  agent: {},
  documents: [],
  planPayments: [],
  percentages: [
    { id: 0, nombre: "0%" },
    { id: 0.1, nombre: "10%" },
    { id: 0.2, nombre: "20%" },
    { id: 0.3, nombre: "30%" },
    { id: 0.4, nombre: "40%" }
  ],
  filtered: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREDITS_USER_GET_REQUESTING:
      return {
        ...state,
        requesting: true,
        success: false,
        error: "",
        creditsUser: []
      };
    case CREDITS_USER_GET_SUCCESS:
      return {
        ...state,
        requesting: false,
        success: true,
        creditsUser: action.credits
      };
    case CREDITS_USER_GET_ERROR:
      return {
        ...state,
        requesting: false,
        error: action.error
      };
    case CREDITS_CLIENT_GET_REQUESTING:
      return {
        ...state,
        requesting: true,
        success: false,
        error: "",
        creditsClient: []
      };
    case CREDITS_CLIENT_GET_SUCCESS:
      return {
        ...state,
        requesting: false,
        success: true,
        creditsClient: action.credits
      };
    case CREDITS_CLIENT_GET_ERROR:
      return {
        ...state,
        requesting: false,
        error: action.error
      };
    case CREDITS_GET_REQUESTING:
      return {
        ...state,
        requesting: true,
        success: false,
        error: "",
        paginate: action.paginate,
        filter: action.filter
      };
    case CREDITS_GET_SUCCESS:
      return {
        ...state,
        requesting: false,
        success: true,
        credits: action.credits,
        hasMore: action.credits.length > 0,
        hasLess: state.paginate > 1
      };
    case CREDITS_GET_ERROR:
      return {
        ...state,
        requesting: false,
        error: action.error
      };
    case CREDITS_SEARCH_REQUESTING:
      return {
        ...state,
        requesting: true,
        success: false,
        error: "",
        search: action.values.search
      };
    case CREDITS_SEARCH_SUCCESS:
      return {
        ...state,
        requesting: false,
        success: true,
        creditsSearch: action.credits
      };
    case CREDITS_SEARCH_ERROR:
      return {
        ...state,
        requesting: false,
        error: action.error,
        search: ""
      };
    case CREDIT_CHANGE_STATE_REQUESTING:
      return {
        ...state,
        requesting: true,
        success: false,
        error: ""
      };
    case CREDIT_CHANGE_STATE_SUCCESS:
      return {
        ...state,
        requesting: false,
        success: true,
        credits: state.credits.map((credit) => credit.id === action.credit.id ? action.credit : credit),
        creditsSearch: state.creditsSearch.length > 0 ? state.creditsSearch.map((credit) => credit.id === action.credit.id ? action.credit : credit) : state.creditsSearch,
        credit: action.credit
      };
    case CREDIT_CHANGE_STATE_ERROR:
      return {
        ...state,
        requesting: false,
        error: action.error
      };
    case CREDIT_CLIENT_REQUESTING:
      return {
        ...state,
        requesting: true,
        success: false,
        error: "",
        client: {}
      };
    case CREDIT_CLIENT_SUCCESS:
      return {
        ...state,
        requesting: false,
        success: true,
        client: action.client
      };
    case CREDIT_CLIENT_ERROR:
      return {
        ...state,
        requesting: false,
        error: action.error
      };
    case CREDIT_POLICY_REQUESTING:
      return {
        ...state,
        requesting: true,
        success: false,
        error: "",
        policy: {}
      };
    case CREDIT_POLICY_SUCCESS:
      return {
        ...state,
        requesting: false,
        success: true,
        policy: action.policy
      };
    case CREDIT_POLICY_ERROR:
      return {
        ...state,
        requesting: false,
        error: action.error
      };
    case CREDIT_PAYMENT_PLANS_REQUESTING:
      return {
        ...state,
        requesting: true,
        success: false,
        error: "",
        paymentPlans: []
      };
    case CREDIT_PAYMENT_PLANS_SUCCESS:
      return {
        ...state,
        requesting: false,
        success: true,
        paymentPlans: action.paymentPlans
      };
    case CREDIT_PAYMENT_PLANS_ERROR:
      return {
        ...state,
        requesting: false,
        error: action.error
      };
    case CREDIT_AGENT_REQUESTING:
      return {
        ...state,
        requesting: true,
        success: false,
        error: "",
        agent: {}
      };
    case CREDIT_AGENT_SUCCESS:
      return {
        ...state,
        requesting: false,
        success: true,
        agent: action.agent
      };
    case CREDIT_AGENT_ERROR:
      return {
        ...state,
        requesting: false,
        error: action.error
      };
    case CREDIT_DOCUMENTS_REQUESTING:
      return {
        ...state,
        requesting: true,
        success: false,
        error: "",
        documents: []
      };
    case CREDIT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        requesting: false,
        success: true,
        documents: action.documents
      };
    case CREDIT_DOCUMENTS_ERROR:
      return {
        ...state,
        requesting: false,
        error: action.error
      };
    case CREDIT_CREATE_REQUESTING:
      return {
        ...state,
        requesting: true,
        success: false,
        error: ""
      };
    case CREDIT_CREATE_SUCCESS:
      return {
        ...state,
        requesting: false,
        credit: action.credit
      };
    case CREDIT_CREATE_ERROR:
      return {
        ...state,
        requesting: false,
        error: action.error
      };
    case CREDIT_UPDATE_REQUESTING:
      return {
        ...state,
        requesting: true,
        success: false,
        error: ""
      };
    case CREDIT_UPDATE_SUCCESS:
      return {
        ...state,
        requesting: false,
        success: true
      };
    case CREDIT_UPDATE_ERROR:
      return {
        ...state,
        requesting: false,
        error: action.error
      };
    case CREDIT_PAYMENT_PLAN_UPDATE:
      return {
        ...state,
        paymentPlans: state.paymentPlans.map((paymentPlan) => paymentPlan.id === action.paymentPlan.id ? action.paymentPlan : paymentPlan)
      };
    case PLAN_PAYMENTS_CREDIT:
      let planPaymentsArray = [];
      let totalCredit = parseFloat(action.totalCredit);
      let pastDueRate = action.pastDueRate / 100;
      let valueFee = (Math.round((parseFloat(totalCredit * (pastDueRate / (1 - Math.pow((1 + pastDueRate), -action.fees)))) + action.adminFee)*1000)/1000);
      let valueFe = (parseFloat(totalCredit * (pastDueRate / (1 - Math.pow((1 + pastDueRate), -action.fees)))) + action.adminFee);
      console.log('inicial',valueFe,'redondeado',Math.ceil(valueFe*1000),'tres cifras',Math.ceil(valueFe*1000)/1000);
      for (let i = 1; i <= action.fees; i++) {
        let date = i === 1 ? moment(action.policy.fecha_inicio).format("DD-MM-YYYY") : moment(action.policy.fecha_inicio).add(i - 1, "months").format("DD-MM-YYYY");
        let oldCapitalBalance = i === 1 ? totalCredit : planPaymentsArray[planPaymentsArray.length - 1].capitalBalance;
        let interestValue = parseFloat((pastDueRate * oldCapitalBalance));
        let capitalValue = parseFloat((valueFee - interestValue) - action.adminFee);
        let capitalBalance = parseFloat((oldCapitalBalance - capitalValue));
        //
        let object = {
          fee: i,
          valueFee: valueFee,
          interestValue: interestValue,
          capitalValue: capitalValue,
          capitalBalance: capitalBalance,
          payDayLimit: date
        };
        planPaymentsArray.push(object);
      }
      return {
        ...state,
        planPayments: planPaymentsArray
      };
    case PLAN_PAYMENTS_CREDIT_RESET:
      return {
        ...state,
        planPayments: []
      };
    case CREDITS_FILTER_REQUESTING:
      return {
        ...state,
        requesting: true,
        success: false,
        error: ""
      };
    case CREDITS_FILTER_SUCCESS:
      return {
        ...state,
        requesting: false,
        success: true,
        credits: action.credits.length === 0 ? state.credits : action.credits,
      };
    case CREDITS_FILTER_ERROR:
      return {
        ...state,
        requesting: false,
        error: action.error,
      };
    case CREDIT_CONTROL_MODAL:
      return {
        ...state,
        clientModal: action.modal === "clientModal" ? !state.clientModal : state.clientModal,
        personModal: action.modal === "personModal" ? !state.personModal : state.personModal,
        familyModal: action.modal === "familyModal" ? !state.familyModal : state.familyModal,
        supplementaryDataModal: action.modal === "supplementaryDataModal" ? !state.supplementaryDataModal : state.supplementaryDataModal,
        policyModal: action.modal === "policyModal" ? !state.policyModal : state.policyModal,
        paymentPlansModal: action.modal === "paymentPlansModal" ? !state.paymentPlansModal : state.paymentPlansModal,
        agentModal: action.modal === "agentModal" ? !state.agentModal : state.agentModal,
        documentsModal: action.modal === "documentsModal" ? !state.documentsModal : state.documentsModal,
        detailCreditModal: action.modal === "detailCreditModal" ? !state.detailCreditModal : state.detailCreditModal,
        statesCreditModal: action.modal === "statesCreditModal" ? !state.statesCreditModal : state.statesCreditModal
      };
    case CREDIT_FOCUS:
      return {
        ...state,
        credit: action.credit
      };
    case CREDIT_RESET_STATES:
      return {
        ...state,
        requesting: false,
        success: false,
        error: ""
      };
    case CREDIT_CLEAN_VALUES:
            return {
                ...state,
                requesting: false,
                success: false,
                error: "",
                credit: {},
                person: {},
                family: [],
                supplementaryData: {},
                exists: false
            };  
         case CREDIT_PERSON_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ""
            };     
        case CREDIT_PERSON_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: ""
            };  
        case CREDIT_PERSON_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error
            };    
        case CREDIT_FAMILY_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ""
            }; 
        case CREDIT_FAMILY_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error
            };      
        case CREDIT_FAMILY_SUCCESS:
            const familyMembers = action.family.map((f, i) => f.index = i);
            return {
                ...state,
                requesting: false,
                success: true,
                error: "",
                family: action.family,
                credit: state.credits.find((credit) => credit.id == action.creditId)
            };    
        case CREDIT_SUPPLEMENTARY_DATA_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ""
            };    
        case CREDIT_SUPPLEMENTARY_DATA_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: ""
            };
        case CREDIT_SUPPLEMENTARY_DATA_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error
            };
    default:
      return state;
  }
};

export default reducer;
