import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import { reducer as toastrReducer } from "react-redux-toastr";
import login from "./auth/login/reducer";
import logout from "./auth/logout/reducer";
import client from "./auth/client/reducer";
import auth from "./auth/user/reducer";
import state from "./state/reducer";
import user from "./user/reducer";
import city from "./city/reducer";
import identificationType from "./identificationType/reducer";
import documentType from "./documentType/reducer";
import oneroso from './oneroso/reducer';
import document from "./document/reducer";
import credit from "./credit/reducer";
import clients from "./client/reducer";
import configuration from "./configuration/reducer";
import sureTypeFeature from "./sureTypeFeature/reducer";
import insurance from "./insurance/reducer";
import sureType from "./sureType/reducer";
import feature from "./feature/reducer";
import policy from "./policy/reducer";
import sms from "./sms/reducer";
import paymentPlan from "./paymentPlan/reducer";
import audit from "./audit/reducer";
import emailNotification from "./emailNotification/reducer";
import naturalPerson from './naturalPerson/reducer';
import legalPerson from './legalPerson/reducer';
import familyMember from './familyMember/reducer';
import supplementaryData from './supplementaryData/reducer';

const IndexReducer = combineReducers({
	form,
	toastr: toastrReducer,
	login,
	logout,
	client,
	auth,
	state,
	user,
	city,
	identificationType,
	documentType,
	oneroso,
	naturalPerson,
	legalPerson,
	familyMember,
	supplementaryData,
	document,
	credit,
	clients,
	configuration,
	sureTypeFeature,
	insurance,
	sureType,
	feature,
	policy,
	sms,
	paymentPlan,
	audit,
	emailNotification
});

export default IndexReducer;