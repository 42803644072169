import { AUDITS_CLASS_GET_ERROR, AUDITS_CLASS_GET_REQUESTING, AUDITS_CLASS_GET_SUCCESS } from "./constants";

export const auditsClassGetRequesting = (token, className) => ({
	type: AUDITS_CLASS_GET_REQUESTING,
	token, className,
});

export const auditsClassGetSuccess = (audits) => ({
	type: AUDITS_CLASS_GET_SUCCESS,
	audits,
});

export const auditsClassGetError = (error) => ({
	type: AUDITS_CLASS_GET_ERROR,
	error,
});