export const DOCUMENTS_USER_GET_REQUESTING = 'DOCUMENTS_USER_GET_REQUESTING';
export const DOCUMENTS_USER_GET_SUCCESS = 'DOCUMENTS_USER_GET_SUCCESS';
export const DOCUMENTS_USER_GET_ERROR = 'DOCUMENTS_USER_GET_ERROR';

export const DOCUMENTS_CREATE_REQUESTING = 'DOCUMENTS_CREATE_REQUESTING';
export const DOCUMENTS_CREATE_SUCCESS = 'DOCUMENTS_CREATE_SUCCESS';
export const DOCUMENTS_CREATE_ERROR = 'DOCUMENTS_CREATE_ERROR';
export const DOCUMENT_FINANCING_PACKAGE_CREATE_REQUESTING = "DOCUMENT_FINANCING_PACKAGE_CREATE_REQUESTING";
export const DOCUMENT_FINANCING_PACKAGE_CREATE_SUCCESS = "DOCUMENT_FINANCING_PACKAGE_CREATE_SUCCESS";
export const DOCUMENT_FINANCING_PACKAGE_CREATE_ERROR = "DOCUMENT_FINANCING_PACKAGE_CREATE_ERROR";
export const DOCUMENT_AUTHORIZE_CREATE_REQUESTING = "DOCUMENT_AUTHORIZE_CREATE_REQUESTING";
export const DOCUMENT_AUTHORIZE_CREATE_SUCCESS = "DOCUMENT_AUTHORIZE_CREATE_SUCCESS";
export const DOCUMENT_AUTHORIZE_CREATE_ERROR = "DOCUMENT_AUTHORIZE_CREATE_ERROR";
export const DOCUMENT_CHECK_DIGITAL_SIGNATURE_REQUESTING = "DOCUMENT_CHECK_DIGITAL_SIGNATURE_REQUESTING";
export const DOCUMENT_CHECK_DIGITAL_SIGNATURE_SUCCESS = "DOCUMENT_CHECK_DIGITAL_SIGNATURE_SUCCESS";
export const DOCUMENT_CHECK_DIGITAL_SIGNATURE_ERROR = "DOCUMENT_CHECK_DIGITAL_SIGNATURE_ERROR";
export const DOCUMENT_SENT_MAIL_SIGNATURE_REQUESTING = "DOCUMENT_SENT_MAIL_SIGNATURE_REQUESTING";
export const DOCUMENT_SENT_MAIL_SIGNATURE_SUCCESS = "DOCUMENT_SENT_MAIL_SIGNATURE_SUCCESS";
export const DOCUMENT_SENT_MAIL_SIGNATURE_ERROR = "DOCUMENT_SENT_MAIL_SIGNATURE_ERROR";