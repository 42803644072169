/*!=========================================================
* Developer DigitalParchapp V.1
=========================================================*/

// Component
import MyRequests from "views/myRequests/myRequests";
import adviser from "views/adviser/adviser";
import customers from "views/customers/customers";
import setting from "views/setting/setting";
// Page
import quote from "views/Pages/Cuote.jsx";
// core
// @material-ui/icons
import UserIcon from "@material-ui/icons/AssignmentInd";
import ConfigIcon from "@material-ui/icons/SettingsApplications";
import Done from "@material-ui/icons/Done";
import Date from "@material-ui/icons/DateRange";
import Face from "@material-ui/icons/Face";



var dashRoutes = [

    ///////////
    {
        path: "/cotizador",
        name: "Cotizador",
        component: quote,
        icon: Done,
        layout: "/admin",
        visible: true
    },
    {
        path: "/misSolicitudes",
        name: "Mis Solicitudes",
        icon: Date,
        component: MyRequests,
        layout: "/admin",
        visible: true
    },
    {
        path: "/asesor",
        name: "Asesor",
        icon: Face,
        component: adviser,
        layout: "/admin",
        visible: true
    },
    {
        path: "/clientes",
        name: "Clientes",
        icon: UserIcon,
        component: customers,
        layout: "/admin",
        visible: true
    },
    {
        path: "/configuracion",
        name: "Configuración",
        icon: ConfigIcon,
        component: setting,
        layout: "/admin",
        visible: true
    },


];
export default dashRoutes;
