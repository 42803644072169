/*!
=========================================================
* Developer DigitalParchapp V.1
=========================================================
*/
import React from "react";
import PropTypes from "prop-types";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import Create from "@material-ui/icons/Create";
import Date from "@material-ui/icons/DateRange";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { dataTable } from "variables/general.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// Modals
import DateCreditCustomersModal from "../customers/Modal/dateCreditCustomers";
import EditCustomersModal from "../customers/Modal/editCustomers";
import AddCustomersModal from "../customers/Modal/addCustomers";
import DetailCreditModal from "../myRequests/Modal/detailCredit";
// Integration
import {
  clientsGetRequesting,
  clientChangeStateRequesting,
  clientControlModal,
  clientFocus,
  clientsFilterTableRequesting,
  clientsSearchError
} from "../../redux/client/actions";
import { statesGeneralGetRequesting } from "../../redux/state/actions";
import { creditsClientGetRequesting } from "../../redux/credit/actions";
import { creditFocus, creditControlModal } from "../../redux/credit/actions";
import { connect } from "react-redux";
import { NotFound } from "../../commons/errors/notFound";
import { Redirect } from "react-router-dom";
//
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    fontWeight: 700
  },
  textTable: {
    fontWeight: 600
  }
};

// Cambiar el nombre de la clase segun el que se vaya a usar
class Product extends React.Component {

  constructor(props) {
    super(props);
    const {
      client: { token },
      clientsGetRequesting,
      statesGeneralGetRequesting,
      clientsSearchError
    } = this.props;
    clientsSearchError("");
    clientsGetRequesting(token, "Todos", 1);
    statesGeneralGetRequesting(token);
    this.state = {
      data: [],
      dataSearch: [],
      columns: [
        {
          Header: "Nombres",
          accessor: "Nombres",
          width: 200,
          className: "text-center",
          id: "nombres",
          Filter: ({ filter, onChange }) => <input type="text"
                                                   onChange={event => {
                                                     this.handleFilterTable(event.target.value, "first_name");
                                                   }}
          />
        },
        {
          Header: "Apellidos",
          accessor: "Apellidos",
          width: 200,
          className: "text-center",
          id: "apellidos",
          Filter: ({ filter, onChange }) => <input type="text"
                                                   onChange={event => {
                                                     this.handleFilterTable(event.target.value, "last_name");
                                                   }}
          />
        },
        {
          Header: "Identificación",
          accessor: "Identificación",
          width: 200,
          className: "text-center",
          id: "identificacion",
          Filter: ({ filter, onChange }) => <input type="text"
                                                   onChange={event => {
                                                     this.handleFilterTable(event.target.value, "identification");
                                                   }}
          />
        },
        {
          Header: "Tipo identificación",
          accessor: "TipoIdentificación",
          sortable: false,
          filterable: false,
          width: 200,
          className: "text-center"
        },
        {
          Header: "Correo",
          accessor: "Correo",
          width: 200,
          className: "text-center",
          id: "correo",
          Filter: ({ filter, onChange }) => <input type="text"
                                                   onChange={event => {
                                                     this.handleFilterTable(event.target.value, "email");
                                                   }}
          />
        },
        {
          Header: "Telefono",
          accessor: "Telefono",
          sortable: false,
          filterable: false,
          width: 200,
          className: "text-center"
        },
        {
          Header: "Ciudad",
          accessor: "Ciudad",
          sortable: false,
          filterable: false,
          width: 200,
          className: "text-center"
        },
        {
          Header: "Acciones",
          accessor: "actions",
          sortable: false,
          filterable: false,
          width: 600,
          className: "text-center"
        }
      ]
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      clients: { clients, clientsSearch }
    } = this.props;
    if (prevProps.clients.clients !== clients) {
      this.setState({
        data: clients.map((client, index) => {
          const { classes } = this.props;
          return {
            id: index,
            Nombres: client.nombres,
            Apellidos: client.apellidos,
            Identificación: client.identificacion,
            TipoIdentificación: client.tipo_identificacion.nombre,
            Correo: client.correo,
            Telefono: client.telefono,
            Ciudad: client.ciudad,
            actions: (

              // we've added some custom button actions
              <div className="actions-right">
                {/* use this button to add a like kind of action */}
                <GridContainer>
                  <GridItem className={"cursor-pointer"} xs={3} sm={3} md={4} onClick={() => {
                    this.handleGetCredits(client.id);
                    this.handleControlModal("creditsModal");
                  }}>
                    <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="like"
                    >
                      <Date/>
                    </Button>
                    Creditos
                  </GridItem>

                  <GridItem className={"cursor-pointer"} xs={3} sm={3} md={3} onClick={() => {
                    this.handleFocus(client);
                    this.handleControlModal("editModal");
                  }}>
                    <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="like"
                    >
                      <Create/>
                    </Button>
                    Editar
                  </GridItem>

                  <GridItem xs={3} sm={3} md={4}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={client.estado.nombre === "Habilitado"}
                          onChange={() => this.handleChangeState(client.id)}
                          color={"primary"}
                          classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={client.estado.nombre}
                    />
                  </GridItem>
                </GridContainer>
              </div>
            )
          };
        })
      });
    }
    if (prevProps.clients.clientsSearch !== clientsSearch) {
      this.setState({
        dataSearch: clients.map((client, index) => {
          const { classes } = this.props;
          return {
            id: index,
            Nombres: client.nombres,
            Apellidos: client.apellidos,
            Identificación: client.identificacion,
            TipoIdentificación: client.tipo_identificacion.nombre,
            Correo: client.correo,
            Telefono: client.telefono,
            Ciudad: client.ciudad,
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                {/* use this button to add a like kind of action */}
                <GridContainer>
                  <GridItem className={"cursor-pointer"} xs={3} sm={3} md={4} onClick={() => {
                    this.handleGetCredits(client.id);
                    this.handleControlModal("creditsModal");
                  }}>
                    <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="like"
                    >
                      <Date/>
                    </Button>{" Creditos"}
                  </GridItem>

                  <GridItem className={"cursor-pointer"} xs={3} sm={3} md={4} onClick={() => {
                    this.handleFocus(client);
                    this.handleControlModal("editModal");
                  }}>
                    <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="like"
                    >
                      <Create/>
                    </Button>{"Editar"}
                  </GridItem>

                  <GridItem xs={3} sm={3} md={4}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={client.estado.nombre === "Habilitado"}
                          onChange={() => this.handleChangeState(client.id)}
                          color={"primary"}
                          classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={client.estado.nombre}
                    />
                  </GridItem>
                </GridContainer>
              </div>
            )
          };
        })
      });
    }
  }

  handleGetCsv = () => {
    const {
      auth: { user }
    } = this.props;
    window.open(`${process.env.REACT_APP_API_URL}/clients/export?email=${user.correo}`, "_self");
  };

  handleFocus = (object) => {
    const {
      clientFocus
    } = this.props;
    clientFocus(object);
  };

  handleGetCredits = (objectId) => {
    const {
      client: { token },
      creditsClientGetRequesting
    } = this.props;
    creditsClientGetRequesting(token, objectId);
  };

  handleFilter = (value) => {
    const {
      client: { token },
      clients: { filter },
      clientsGetRequesting
    } = this.props;
    if (value !== filter)
      clientsGetRequesting(token, value, 1);
  };

  handleGetData = (newPaginate) => {
    const {
      client: { token },
      clients: { filter, paginate, hasMore, hasLess },
      clientsGetRequesting
    } = this.props;
    if (newPaginate === 1 && hasMore)
      clientsGetRequesting(token, filter, paginate + newPaginate);
    if (newPaginate === -1 && hasLess && paginate > 1)
      clientsGetRequesting(token, filter, paginate + newPaginate);
  };

  handleChangeState = (objectId) => {
    const {
      client: { token },
      clientChangeStateRequesting
    } = this.props;
    clientChangeStateRequesting(token, objectId);
  };

  handleControlModal = (modal) => {
    const {
      clientControlModal,
      creditControlModal
    } = this.props;
    clientControlModal(modal);
    creditControlModal(modal);
  };

  handleCreditFocus = (credit) => {
    const {
      creditFocus
    } = this.props;
    creditFocus(credit);
  };

  handleFilterTable = (value, column) => {
    const {
      clientsFilterTableRequesting,
      client: { token }
    } = this.props;
    clientsFilterTableRequesting(token, value, column);
  };

  render() {
    const { columns, data, dataSearch } = this.state;
    const {
      classes,
      state: { states },
      client: { token },
      clients: { clients, filter, addModal, editModal, creditsModal, search, clientsSearch, hasMore, hasLess },
      credit: { creditsClient, detailCreditModal }
    } = this.props;
    if (token === "") {
      return (
        <Redirect to={"/"}/>
      );
    }
    return (
      <GridContainer>
        {creditsModal && creditsClient.length > 0 && (
          <DateCreditCustomersModal
            controlModal={this.handleControlModal.bind(this)}
            credits={creditsClient}
            focusCredit={this.handleCreditFocus.bind(this)}
            open={creditsModal}/>
        )}
        {editModal && (
          <EditCustomersModal
            controlModal={this.handleControlModal.bind(this)}
            open={editModal}/>
        )}

        {addModal && (
          <AddCustomersModal
            controlModal={this.handleControlModal.bind(this)}
            open={addModal}/>
        )}
        {detailCreditModal && (
          <DetailCreditModal
            controlModal={this.handleControlModal.bind(this)}
            open={detailCreditModal}
          />
        )}
        {search !== "" && (
          clientsSearch.length > 0
            ? <GridItem xs={12}>
              <Card>
                <CardBody>
                  <ReactTable
                    data={dataSearch}
                    filterable
                    columns={columns}
                    defaultPageSize={5}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    previousText={"Anterior"}
                    nextText={"Siguiente"}
                    pageText={"Pagina"}
                    ofText={"de"}
                    rowsText={"datos"}
                    onFilteredChange={(value) => this.handleFilterTable(value)}
                    //loading para cargar
                  />
                </CardBody>
              </Card>
            </GridItem>
            : <NotFound/>
        )}

        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AssignmentInd/>
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Clientes</h4>
              <br/>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      {filter}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={filter}
                      onChange={(input) => this.handleFilter(input.target.value)}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select"
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="Todos"
                      >
                        Todos
                      </MenuItem>
                      {states.length > 0 && states.map((state, index) => (
                        <MenuItem key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}

                                  value={state.nombre}
                        >
                          {state.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={3} md={3}>
                  <FormControl fullWidth>
                    <Button
                      color="primary" className={classes.marginRight}
                      onClick={() => {
                        this.handleControlModal("addModal");
                      }}>
                      Agregar Nuevo Cliente
                    </Button>
                  </FormControl>
                </GridItem>


                <GridItem xs={12} sm={3} md={3}>
                  <FormControl fullWidth>
                    <Button color="primary" className={classes.marginRight}
                            onClick={() => this.handleGetCsv()}>
                      Descargar csv
                    </Button>
                  </FormControl>
                </GridItem>
              </GridContainer>

            </CardHeader>
            <CardBody>
              {clients.length > 0 && (
                <ReactTable
                  data={data}
                  filterable
                  columns={columns}
                  defaultPageSize={data.length}
                  showPaginationBottom={true}
                  className="-striped -highlight"
                  previousText={"Anterior"}
                  nextText={"Siguiente"}
                  pageText={"Pagina"}
                  ofText={"de"}
                  rowsText={"datos"}
                  //loading para cargar
                  manual
                  NextComponent={(nextText) => {
                    return (
                      <button disabled={!hasMore} className={""}
                              onClick={() => this.handleGetData(+1)}>
                        {nextText.children}
                      </button>
                    );
                  }}
                  PreviousComponent={(previousText) => {
                    return (
                      <button disabled={!hasLess} className={""}
                              onClick={() => this.handleGetData(-1)}>
                        {previousText.children}
                      </button>
                    );
                  }}
                />
              )}
              {clients.length === 0 && (
                <NotFound/>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

//Usar el nombre que se puso arriba aca
Product.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  client: state.client,
  clients: state.clients,
  auth: state.auth,
  state: state.state,
  credit: state.credit
});

const connected = connect(mapStateToProps, {
  clientsGetRequesting,
  clientChangeStateRequesting,
  clientControlModal,
  statesGeneralGetRequesting,
  clientFocus,
  creditsClientGetRequesting,
  creditFocus,
  creditControlModal,
  clientsFilterTableRequesting,
  clientsSearchError
})(withStyles(styles)(Product));
// Y aca ...
export default connected;
