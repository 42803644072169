import React from "react";
// MaterialIU
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
// Components Core
import Card from "../Card/Card.jsx";
import CardBody from "../Card/CardBody";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Button from "../CustomButtons/Button.jsx";
// Styles
import userProfileStyles from "../../assets/jss/material-dashboard-pro-react/views/userProfileCompanyStyles";
// Integration
import { connect } from "react-redux";
import { Form, reduxForm, Field,reset } from "redux-form";
import { customInputText } from "../inputs/typeText";
import { customInputSelect } from "../inputs/typeSelect";
import { customInputCheckBox } from "../inputs/checkbox";
import { customInputDate } from "../inputs/inputDate";
import { insurancesGetRequesting } from "../../redux/insurance/actions";
import { sureTypesGetRequesting } from "../../redux/sureType/actions";
import { featuresSureTypeGetRequesting } from "../../redux/feature/actions";
import { policyCreateRequesting, policyUpdatePriceRequesting , policyCleanValues } from "../../redux/policy/actions";
import moment from "moment";

class FormPolicy extends React.Component {

  constructor(props) {
    super(props);
    const {
      client: { token },
      insurancesGetRequesting,
      sureTypesGetRequesting
    } = this.props;
    insurancesGetRequesting(token);
    sureTypesGetRequesting(token);
  }

  componentWillUnmount(){
    this.props.policyCleanValues();
  }

  handleSubmit = (values) => {
    const {
      client: { token },
      policy : {policy},
      policyCreateRequesting,
      policyUpdatePriceRequesting
    } = this.props;
     if (policy.hasOwnProperty("id")) {
       policyUpdatePriceRequesting(token,policy.id,values);
      } else {
      policyCreateRequesting(token, values);
     }
  };

  handleGetFeatures = (e) => {
    const {
      client: { token },
      featuresSureTypeGetRequesting
    } = this.props;
    featuresSureTypeGetRequesting(token, e.target.value);
  };

  render() {
    const {
      classes,
      handleSubmit,
      insurance: { insurances },
      clients: { client },
      sureType: { sureTypes },
      feature: { features },
      policy: { policy },
      stateForm
    } = this.props;
    let initialDate = stateForm.hasOwnProperty("policy") && stateForm.policy.hasOwnProperty("values") && stateForm.policy.values.hasOwnProperty("fecha_inicio") ? stateForm.policy.values.fecha_inicio : "";
    let dateInitial = initialDate !== "" ? moment(initialDate).add(1, "years") : null;
    return (
      <Card profile>
        <CardBody>
          <h1 className={classes.TitleH1Left}>Información de la Poliza</h1>
          <Form onSubmit={handleSubmit(this.handleSubmit)}>
            <GridContainer>
              {/*<GridItem xs={12} sm={6} md={6}>*/}
                {/*{insurances.length > 0 && (*/}
                  {/*<Field id={"aseguradora"}*/}
                         {/*name={"aseguradora"} type={"select"}*/}
                         {/*label={"Aseguradora *"}*/}
                         {/*autoComplete={"on"} component={customInputSelect} classes={classes}*/}
                         {/*data={insurances}/>*/}
                {/*)}*/}
              {/*</GridItem>*/}
              <GridItem xs={12} sm={6} md={6}>
                <Field id={"beneficiario_honeroso"}
                       name={"beneficiario_honeroso"} type={"checkbox"}
                       label={"Beneficiario oneroso *"} component={customInputCheckBox}
                       classes={classes} color={"primary"}/>
              </GridItem>
              {/*<GridItem xs={12} sm={6} md={6}>*/}
                {/*<Field id={"numero"} name={"numero"}*/}
                       {/*type={"number"} label={"Numero de poliza *"}*/}
                       {/*autoComplete={"off"}*/}
                       {/*component={customInputText}/>*/}
              {/*</GridItem>*/}

              {/*<GridItem xs={12} sm={6} md={6}>*/}
                {/*<Field id={"nombre_beneficiario"}*/}
                       {/*name={"nombre_beneficiario"} type={"text"} label={"Nombre del beneficiario *"}*/}
                       {/*autoComplete={"off"}*/}
                       {/*component={customInputText}/>*/}
              {/*</GridItem>*/}

              <GridItem xs={12} sm={6} md={6}>
                <Field id={"valor"} name={"valor"}
                       type={"number"} label={"Valor de la poliza *"}
                       autoComplete={"off"}
                       component={customInputText}/>
              </GridItem>

              {/*<GridItem xs={12} sm={6} md={6}>*/}
                {/*{sureTypes.length > 0 && (*/}
                  {/*<Field id={"tipo_seguro"}*/}
                         {/*name={"tipo_seguro"} type={"select"}*/}
                         {/*label={"Tipo de seguro *"}*/}
                         {/*onChange={(e) => this.handleGetFeatures(e)}*/}
                         {/*autoComplete={"on"} component={customInputSelect} classes={classes}*/}
                         {/*data={sureTypes}/>*/}
                {/*)}*/}
              {/*</GridItem>*/}
              {/*{features.length > 0 && features.map((feature, index) => (*/}
                {/*<GridItem key={index} xs={12} sm={6} md={6}>*/}
                  {/*<Field id={feature.nombre}*/}
                         {/*name={`feature_${feature.nombre}`} type={"text"}*/}
                         {/*label={`${feature.nombre} *`}*/}
                         {/*autoComplete={"off"}*/}
                         {/*component={customInputText}/>*/}
                {/*</GridItem>*/}
              {/*))}*/}
              <GridItem xs={12} sm={6} md={3}>
                <Field id={"fecha_inicio"} name={"fecha_inicio"}
                       type={"date"}
                       label={"Fecha de inicio *"} dateInitial={null} component={customInputDate}/>
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <Field id={"fecha_final"} name={"fecha_final"}
                       type={"date"}
                       label={"Fecha de vencimiento *"} dateInitial={dateInitial}
                       component={customInputDate}/>
              </GridItem>
            </GridContainer>
            <Button color="primary"
                    className={classes.updateProfileButton} type={"submit"}>
              {policy.hasOwnProperty("id") ? "Actualizar" : "Siguiente"}
            </Button>
          </Form>
        </CardBody>
      </Card>
    );
  }
}

FormPolicy.propTypes = {
  classes: PropTypes.object
};

const formed = reduxForm({
  form: "policy",
  reset
})(withStyles(userProfileStyles)(FormPolicy));

const mapStateToProps = state => ({
  client: state.client,
  insurance: state.insurance,
  clients: state.clients,
  sureType: state.sureType,
  feature: state.feature,
  policy: state.policy,
  initialValues: state.policy.policy,
  stateForm: state.form
});

const connected = connect(mapStateToProps, {
  insurancesGetRequesting,
  sureTypesGetRequesting,
  featuresSureTypeGetRequesting,
  policyCreateRequesting,
  policyUpdatePriceRequesting,
  policyCleanValues
})(formed);

export default connected;

