import React from "react";
import { call, put, all, takeEvery } from "redux-saga/effects";
import { handleApiErrors } from "../../commons/errors/apiErrors";
import { ErrorComponent } from "../../commons/errors/errors";
import { toastr } from "react-redux-toastr";
import {
  paymentPlanConfirmedPaymentError,
  paymentPlanConfirmedPaymentSuccess, paymentPlanUpdateError,
  paymentPlanUpdateSuccess
} from "./actions";
import { creditControlModal, creditPaymentPlansSuccess, creditPaymentPlanUpdate } from "../credit/actions";
import { PAYMENT_PLAN_CONFIRMED_PAYMENT_REQUESTING, PAYMENT_PLAN_UPDATE_REQUESTING } from "./constants";

const baseUrl = `${process.env.REACT_APP_API_URL}/paymentPlans`;

const showError = (error) => {
  let toastOptions = {
    component: (
      <ErrorComponent message={error}/>
    )
  };
  toastr.error("Error", toastOptions);
};

const paymentPlanConfirmedPaymentApi = (token, paymentPlan) => {
  return fetch(`${baseUrl}/confirmed/${paymentPlan.id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(handleApiErrors)
    .then(response => response.json())
    .then(json => {
      if (json.code === 422)
        throw json.data;
      if (json.code === 400)
        throw [json.data];
      if (json.code === 200)
        return json.data.data;
      throw json.data;
    }).catch((error) => {
      throw error;
    });
};

function* paymentPlanConfirmedPaymentFlow(action) {
  try {
    const { token, paymentPlan } = action;
    const paymentPlans = yield call(paymentPlanConfirmedPaymentApi, token, paymentPlan);
    yield put(creditPaymentPlansSuccess(paymentPlans));
    yield put(paymentPlanConfirmedPaymentSuccess());
    toastr.success("Cuota actualizada", `El pago de la cuota #${paymentPlan.numero_cuota} del credito ${paymentPlan.credito.id} ha sido confirmada con exito.`);
    // yield put(creditControlModal("paymentPlansModal"));
  } catch (error) {
    yield put(paymentPlanConfirmedPaymentError(error));
    showError(error);
  }
}

const paymentPlanUpdateApi = (token, paymentPlan, values) => {
  let body = {
    defaultInterestValue: values.valor_interes_mora
  };
  return fetch(`${baseUrl}/${paymentPlan.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(body)
  })
    .then(handleApiErrors)
    .then(response => response.json())
    .then(json => {
      if (json.code === 422)
        throw json.data;
      if (json.code === 400)
        throw [json.data];
      if (json.code === 200)
        return json.data.data;
      throw json.data;
    }).catch((error) => {
      throw error;
    });
};

function* paymentPlanUpdateFlow(action) {
  try {
    const { token, paymentPlan, value } = action;
    const paymentPlanGet = yield call(paymentPlanUpdateApi, token, paymentPlan, value);
    yield put(paymentPlanUpdateSuccess());
    yield put(creditPaymentPlanUpdate(paymentPlanGet));
    yield put(creditControlModal("editPaymentPlanModal"));
  } catch (error) {
    yield put(paymentPlanUpdateError(error));
    showError(error);
  }
}

function* paymentPlanWatcher() {
  yield all([
    takeEvery(PAYMENT_PLAN_CONFIRMED_PAYMENT_REQUESTING, paymentPlanConfirmedPaymentFlow),
    takeEvery(PAYMENT_PLAN_UPDATE_REQUESTING, paymentPlanUpdateFlow)
  ]);
}

export default paymentPlanWatcher;