// /*!// =========================================================
// * Developer DigitalParchapp V.1
// =========================================================// */
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/core/SvgIcon/SvgIcon";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import notificationsStyle from "./../../../assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
import Slide from "@material-ui/core/Slide";
// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
// core components
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import GridItem from "../../../components/Grid/GridItem";
import { emailNotificationCreateRequesting } from '../../../redux/emailNotification/actions';
import { sendSmsRequesting } from '../../../redux/sms/actions';
import { connect } from 'react-redux';

import { FormattedNumber } from "react-intl";

const formattedNumber = (value) => {
  return (
    <FormattedNumber value={value} style={"currency"} currency={"COP"}
                     minimumFractionDigits={0} maximumFractionDigits={0}/>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const styleButton = { fontSize: "10px", textTransform: "none", width: "120px" };

class DateCredit extends React.Component {

  handleGetCsv = () => {
    const {
      auth: { user },
      credit: { credit }
    } = this.props;
    window.open(`${process.env.REACT_APP_API_URL}/paymentPlan/export/${credit.id}?email=${user.correo}`, "_self");
  };

  handleCreateNotification = (subject, paymentPlanId) => {
     const {
        client: { token },
        emailNotificationCreateRequesting
     } = this.props;
     emailNotificationCreateRequesting(token, subject, paymentPlanId);
  }

  render() {
    const {
      client: { token },
      classes,
      open,
      controlModal,
      paymentPlans,
      confirmedPayment,
      sendNotification,
      getEmailNotifications,
      paymentPlanId,
      warningEmails,
      pendingEmails,
      cancellationEmail,
      emailNotification: { requesting },
      sendSmsRequesting
    } = this.props;
    let paymentPlan = paymentPlans.find((paymentPlan) => paymentPlan.estado.nombre === "Próxima cuota");
    if (paymentPlanId !== paymentPlan.id) {
      getEmailNotifications(paymentPlan.id);
    }
    return <Dialog
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modalLarge
      }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => controlModal("paymentPlansModal")}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <CardHeader color="primary" icon>
        <CardIcon color="primary">
          <MailOutline/>
        </CardIcon>
      </CardHeader>

      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}>
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={() => controlModal("paymentPlansModal")}
        >
          <Close className={classes.modalClose}/>
        </Button>
        <h4 className={classes.modalTitle}>Datos de Credito</h4>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}>
          {paymentPlans.length > 0 && (
            <GridItem xs={12} sm={12} md={12}>
              <div className="Conteiner-table">
                <table className="table-pyments-page-price-4">
                  <thead>
                  <tr>
                    <th>Cuota</th>
                    <th>Valor cuota</th>
                    <th>Intereses moratorios</th>
                    <th>Total a pagar</th>
                    <th>Valor interes</th>
                    <th>Valor capital</th>
                    <th>Saldo capital</th>
                    <th>Fecha de pago</th>
                    <th>Confirmar</th>
                    <th>Correos</th>
                  </tr>
                  </thead>
                  <tbody>
                  {paymentPlans.map((planPayment, index) => (
                    <tr key={index}>
                      <td>{planPayment.numero_cuota}</td>
                      <td>{formattedNumber(planPayment.valor_cuota)}</td>
                      <td>{formattedNumber(planPayment.intereses)}</td>
                      <td>{formattedNumber(planPayment.total)}</td>
                      <td>{formattedNumber(planPayment.valor_interes)}</td>
                      <td>{formattedNumber(planPayment.valor_capital)}</td>
                      <td>{formattedNumber(planPayment.saldo_capital)}</td>
                      <td>{planPayment.fecha_limite_pago}</td>
                      <td>
                        <Button style={styleButton}
                                onClick={() => confirmedPayment(planPayment)}
                                color={planPayment.estado.nombre === "Próxima cuota" ? "primary" : "disabled"}
                                disabled={planPayment.estado.nombre !== "Próxima cuota"}>
                          {planPayment.estado.nombre === "Próxima cuota" ? "Confirmar pago" : planPayment.estado.nombre === "Pagado" ? "Pagado" : "Sin confirmar"}
                        </Button>
                        <Button style={styleButton}
                                color={"primary"}
                                disabled={false}
                                onClick={() => sendSmsRequesting(token, planPayment.id)}>
                          Enviar Sms
                        </Button>
                      </td>
                      <td>
                        <Button style={styleButton}
                                color={planPayment.estado.nombre === "Próxima cuota" ? "success" : "disabled"}
                                disabled={planPayment.estado.nombre !== "Próxima cuota" || warningEmails.length > 0}
                                onClick={() => this.handleCreateNotification('warning', planPayment.id)}>
                          Extracto de pago
                          {planPayment.estado.nombre === "Próxima cuota" ? ` (${warningEmails.length})` : null}
                        </Button>
                        <Button style={styleButton}
                                color={planPayment.estado.nombre === "Próxima cuota" ? "warning" : "disabled"}
                                disabled={planPayment.estado.nombre !== "Próxima cuota" || pendingEmails.length > 0}
                                onClick={() => this.handleCreateNotification('pending', planPayment.id)}>
                          Aviso revocación
                          {planPayment.estado.nombre === "Próxima cuota" ? ` (${pendingEmails.length})` : null}
                        </Button>
                        <Button style={styleButton}
                                color={planPayment.estado.nombre === "Próxima cuota" ? "danger" : "disabled"}
                                disabled={planPayment.estado.nombre !== "Próxima cuota" || cancellationEmail.length > 0}
                                onClick={() => this.handleCreateNotification('canceled', planPayment.id)}>
                          Cancelación póliza
                          {planPayment.estado.nombre === "Próxima cuota" ? ` (${cancellationEmail.length})` : null}
                        </Button>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </GridItem>
          )}
          <br/>
          <DialogActions className={classes.modalFooter}>
            <Button disabled={requesting} onClick={() => controlModal("paymentPlansModal")} color="warning">
              Cerrar
            </Button>
            <Button disabled={requesting} onClick={() => this.handleGetCsv()} color="primary">
              Descargar CSV
            </Button>
          </DialogActions>
        </DialogContent>
      </DialogContent>
    </Dialog>;
  }
}

DateCredit.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => ({
   client: state.client,
   auth: state.auth,
   credit: state.credit,
   emailNotification: state.emailNotification
});

const mapDispatchToProps = {
   emailNotificationCreateRequesting,
   sendSmsRequesting
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(notificationsStyle)(DateCredit));

//export default withStyles(notificationsStyle)(DateCredit);