export const USERS_GET_REQUESTING = 'USERS_GET_REQUESTING';
export const USERS_GET_SUCCESS = 'USERS_GET_SUCCESS';
export const USERS_GET_ERROR = 'USERS_GET_ERROR';

export const USER_CREATE_REQUESTING = 'USER_CREATE_REQUESTING';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';
export const USER_CREATE_ERROR = 'USER_CREATE_ERROR';

export const USER_UPDATE_REQUESTING = 'USER_UPDATE_REQUESTING';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR';

export const USER_CHANGE_STATE_REQUESTING = 'USER_CHANGE_STATE_REQUESTING';
export const USER_CHANGE_STATE_SUCCESS = 'USER_CHANGE_STATE_SUCCESS';
export const USER_CHANGE_STATE_ERROR = 'USER_CHANGE_STATE_ERROR';

export const USERS_SEARCH_REQUESTING = 'USERS_SEARCH_REQUESTING';
export const USERS_SEARCH_SUCCESS = 'USERS_SEARCH_SUCCESS';
export const USERS_SEARCH_ERROR = 'USERS_SEARCH_ERROR';

export const USERS_FILTER_TABLE_REQUESTING = "USERS_FILTER_TABLE_REQUESTING";
export const USERS_FILTER_TABLE_SUCCESS = "USERS_FILTER_TABLE_SUCCESS";
export const USERS_FILTER_TABLE_ERROR = "USERS_FILTER_TABLE_ERROR";

export const USER_CONTROL_MODAL = 'USER_CONTROL_MODAL';
export const USER_FOCUS = 'USER_FOCUS';
export const USER_RESET_STATES = 'USER_RESET_STATES';