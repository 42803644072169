import {
	ONEROSO_GET_ERROR,
	ONEROSO_GET_REQUESTING,
	ONEROSO_GET_SUCCESS,
	ONEROSO_FIND_BY_ID,
	ONEROSO_SET_CREDIT_ID_REQUESTING,
	ONEROSO_SET_CREDIT_ID_SUCCESS,
	ONEROSO_SET_CREDIT_ID_ERROR
} from "./constants";

export const onerosoGetRequesting = () => ({
	type: ONEROSO_GET_REQUESTING,
});

export const onerosoGetSuccess = (oneroso) => ({
	type: ONEROSO_GET_SUCCESS,
	oneroso,
});

export const onerosoGetError = (error) => ({
	type: ONEROSO_GET_ERROR,
	error,
});

export const onerosoFindById = (onerosoId) => ({
	type: ONEROSO_FIND_BY_ID,
	onerosoId
});

export const onerosoSetCreditIdRequesting = (onerosoId,creditId) => ({
	type: ONEROSO_SET_CREDIT_ID_REQUESTING,
	onerosoId,
	creditId
});

export const onerosoSetCreditIdSuccess = () => ({
	type: ONEROSO_SET_CREDIT_ID_SUCCESS
});

export const onerosoSetCreditIdError = (error) => ({
	type: ONEROSO_SET_CREDIT_ID_ERROR,
	error 
});