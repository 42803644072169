import {
	FAMILY_MEMBER_CREATE_REQUESTING,
	FAMILY_MEMBER_CREATE_SUCCESS,
	FAMILY_MEMBER_CREATE_ERROR,
	FAMILY_MEMBER_UPDATE_REQUESTING,
	FAMILY_MEMBER_UPDATE_SUCCESS,
	FAMILY_MEMBER_UPDATE_ERROR,
	FAMILY_MEMBER_RESET_STATES,
	FAMILY_MEMBER_FILL_DATA,
	FAMILY_MEMBER_FILL_DATA_SUCCESS,
	FAMILY_MEMBER_FILL_DATA_ERROR,
	FAMILY_MEMBER_CLEAN_VALUES,
	FAMILY_MEMBER_DELETE_REQUESTING
} from './constants';

export const familyMemberCreateRequesting = (token, values, client) => ({
	type: FAMILY_MEMBER_CREATE_REQUESTING,
	token,
	values,
	client
});

export const familyMemberCreateSuccess = (familyMember) => ({
	type: FAMILY_MEMBER_CREATE_SUCCESS,
	familyMember
});

export const familyMemberCreateError = (error) => ({
	type: FAMILY_MEMBER_CREATE_ERROR,
	error
});

export const familyMemberUpdateRequesting = (token, values, client, id) => ({
	type: FAMILY_MEMBER_UPDATE_REQUESTING,
	token,
	values,
	client,
	id
});

export const familyMemberUpdateSuccess = () => ({
	type: FAMILY_MEMBER_UPDATE_SUCCESS
});

export const familyMemberUpdateError = (error) => ({
	type: FAMILY_MEMBER_UPDATE_ERROR,
	error
});

export const familyMemberResetStates = () => ({
	type: FAMILY_MEMBER_RESET_STATES
}); 

export const familyMemberFillData = (family) => ({
	type: FAMILY_MEMBER_FILL_DATA,
	family
});

export const familyMemberFillDataSuccess = (family) => ({
	type: FAMILY_MEMBER_FILL_DATA_SUCCESS,
	family
});

export const familyMemberFillDataError = (error) => ({
	type: FAMILY_MEMBER_FILL_DATA_ERROR,
	error
});	

export const familyMemberCleanValues = () => ({
	type: FAMILY_MEMBER_CLEAN_VALUES
});

export const familyMemberDeleteRequesting = () => ({
	type: FAMILY_MEMBER_DELETE_REQUESTING
})