export const NATURAL_PERSON_CREATE_REQUESTING = 'NATURAL_PERSON_CREATE_REQUESTING';
export const NATURAL_PERSON_CREATE_SUCCESS = 'NATURAL_PERSON_CREATE_SUCCESS';
export const NATURAL_PERSON_CREATE_ERROR = 'NATURAL_PERSON_CREATE_ERROR';
export const NATURAL_PERSON_UPDATE_REQUESTING = 'NATURAL_PERSON_UPDATE_REQUESTING';
export const NATURAL_PERSON_UPDATE_SUCCESS = 'NATURAL_PERSON_UPDATE_SUCCESS';
export const NATURAL_PERSON_UPDATE_ERROR = 'NATURAL_PERSON_UPDATE_ERROR';
export const NATURAL_PERSON_RESET_STATES = 'NATURAL_PERSON_RESET_STATES';
export const NATURAL_PERSON_FILL_DATA = "NATURAL_PERSON_FILL_DATA";
export const NATURAL_PERSON_FILL_DATA_SUCCESS = "NATURAL_PERSON_FILL_DATA_SUCCESS";
export const NATURAL_PERSON_FILL_DATA_ERROR = "NATURAL_PERSON_FILL_DATA_ERROR";
export const NATURAL_PERSON_CLEAN_VALUES = "NATURAL_PERSON_CLEAN_VALUES";