import {
  USER_CHANGE_STATE_ERROR,
  USER_CHANGE_STATE_REQUESTING,
  USER_CHANGE_STATE_SUCCESS,
  USER_CONTROL_MODAL,
  USER_CREATE_ERROR,
  USER_CREATE_REQUESTING,
  USER_CREATE_SUCCESS,
  USER_FOCUS,
  USER_RESET_STATES,
  USER_UPDATE_ERROR,
  USER_UPDATE_REQUESTING,
  USER_UPDATE_SUCCESS, USERS_FILTER_TABLE_ERROR,
  USERS_FILTER_TABLE_REQUESTING, USERS_FILTER_TABLE_SUCCESS,
  USERS_GET_ERROR,
  USERS_GET_REQUESTING,
  USERS_GET_SUCCESS,
  USERS_SEARCH_ERROR,
  USERS_SEARCH_REQUESTING,
  USERS_SEARCH_SUCCESS
} from "./constants";

export const usersGetRequesting = (token, filter, paginate) => ({
  type: USERS_GET_REQUESTING,
  token, filter, paginate
});

export const usersGetSuccess = (users) => ({
  type: USERS_GET_SUCCESS,
  users
});

export const usersGetError = (error) => ({
  type: USERS_GET_ERROR,
  error
});

export const userCreateRequesting = (token, values) => ({
  type: USER_CREATE_REQUESTING,
  token, values
});

export const userCreateSuccess = (user) => ({
  type: USER_CREATE_SUCCESS,
  user
});

export const userCreateError = (error) => ({
  type: USER_CREATE_ERROR,
  error
});

export const userUpdateRequesting = (token, values) => ({
  type: USER_UPDATE_REQUESTING,
  token, values
});

export const userUpdateSuccess = (user) => ({
  type: USER_UPDATE_SUCCESS,
  user
});

export const userUpdateError = (error) => ({
  type: USER_UPDATE_ERROR,
  error
});

export const userChangeStateRequesting = (token, userId) => ({
  type: USER_CHANGE_STATE_REQUESTING,
  token, userId
});

export const userChangeStateSuccess = (user) => ({
  type: USER_CHANGE_STATE_SUCCESS,
  user
});

export const userChangeStateError = (error) => ({
  type: USER_CHANGE_STATE_ERROR,
  error
});

export const usersSearchRequesting = (token, values) => ({
  type: USERS_SEARCH_REQUESTING,
  token, values
});

export const usersSearchSuccess = (users) => ({
  type: USERS_SEARCH_SUCCESS,
  users
});

export const usersSearchError = (error) => ({
  type: USERS_SEARCH_ERROR,
  error
});

export const usersFilterTableRequesting = (token, value, column) => ({
  type: USERS_FILTER_TABLE_REQUESTING,
  token, value, column
});

export const usersFilterTableSuccess = (users) => ({
  type: USERS_FILTER_TABLE_SUCCESS,
  users,
});

export const usersFilterTableError = (error) => ({
  type: USERS_FILTER_TABLE_ERROR,
  error,
});

export const userControlModal = (modal) => ({
  type: USER_CONTROL_MODAL,
  modal
});

export const userFocus = (user) => ({
  type: USER_FOCUS,
  user
});

export const userResetStates = () => ({
  type: USER_RESET_STATES
});