/*!
=========================================================
* Developer DigitalParchapp V.1
=========================================================
*/
import React from "react";
import PropTypes from "prop-types";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import Create from "@material-ui/icons/Create";
import Date from "@material-ui/icons/DateRange";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { dataTable } from "variables/general.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Avatar from "@material-ui/core/Avatar";
// Modals
import DateCreditAModalModal from "../adviser/Modal/dateCreditA";
import EditAdviserModal from "../adviser/Modal/editAdviser";
import AddAdviserModal from "../adviser/Modal/addAdviser";
import DetailCreditModal from "../myRequests/Modal/detailCredit";
// Integration
import {
  usersGetRequesting,
  userChangeStateRequesting,
  userControlModal,
  userFocus,
  usersFilterTableRequesting,
  usersSearchError
} from "../../redux/user/actions";
import { statesGeneralGetRequesting } from "../../redux/state/actions";
import { documentsUserGetRequesting } from "../../redux/document/actions";
import { creditsUserGetRequesting } from "../../redux/credit/actions";
import { creditFocus, creditControlModal } from "../../redux/credit/actions";
import { connect } from "react-redux";
import { NotFound } from "../../commons/errors/notFound";
import { Redirect } from "react-router-dom";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    fontWeight: 700
  },
  textTable: {
    fontWeight: 600
  }
};

// Cambiar el nombre de la clase segun el que se vaya a usar
class Product extends React.Component {
  constructor(props) {
    super(props);
    const {
      client: { token },
      usersGetRequesting,
      statesGeneralGetRequesting,
      usersSearchError,
    } = this.props;
    usersSearchError("");
    usersGetRequesting(token, "Todos", 1);
    statesGeneralGetRequesting(token);
    this.state = {
      data: [],
      dataSearch: [],
      columns: [
        {
          Header: "Nombre",
          accessor: "Nombre",
          width: 200,
          className: "text-center",
          id: "nombre",
          Filter: ({ filter, onChange }) => <input type="text"
                                                   onChange={event => {
                                                     this.handleFilterTable(event.target.value, "name");
                                                   }}
          />
        },
        {
          Header: "Cedula",
          accessor: "Cedula",
          width: 200,
          className: "text-center",
          id: "identificacion",
          Filter: ({ filter, onChange }) => <input type="text"
                                                   onChange={event => {
                                                     this.handleFilterTable(event.target.value, "identification");
                                                   }}
          />
        },
        {
          Header: "Correo",
          accessor: "Correo",
          width: 200,
          className: "text-center",
          id: "correo",
          Filter: ({ filter, onChange }) => <input type="text"
                                                   onChange={event => {
                                                     this.handleFilterTable(event.target.value, "email");
                                                   }}
          />
        },
        {
          Header: "Telefono",
          accessor: "Telefono",
          sortable: false,
          filterable: false,
          width: 200,
          className: "text-center"
        },
        {
          Header: "Ciudad",
          accessor: "Ciudad",
          sortable: false,
          filterable: false,
          width: 200,
          className: "text-center"
        },
        {
          Header: "Dirección",
          accessor: "Dirección",
          sortable: false,
          filterable: false,
          width: 200,
          className: "text-center"
        },
        {
          Header: "Documentos",
          accessor: "Documentos",
          sortable: false,
          filterable: false,
          width: 150,
          className: "text-center"
        },
        {
          Header: "Foto/Logo",
          accessor: "FotoLogo",
          sortable: false,
          filterable: false,
          width: 150,
          className: "text-center"
        },
        {
          width: 450,
          Header: "Acciónes",
          accessor: "actions",
          sortable: false,
          filterable: false,
          className: "text-center"
        }
      ]
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      user: { users, usersSearch }
    } = this.props;
    if (prevProps.user.users !== users) {
      this.setState({
        data: users.map((user, index) => {
          const { classes } = this.props;
          return {
            id: index,
            Nombre: user.nombre,
            Cedula: user.identificacion,
            Correo: user.correo,
            Telefono: user.telefono,
            Ciudad: user.ciudad.nombre,
            Dirección: user.direccion ? user.direccion : "Sin dirección",
            Documentos: (<Button color="primary" onClick={() => {
              this.handleControlModal("documentsModal");
              this.handleGetDocuments(user.id);
            }}>Ver</Button>),
            FotoLogo: (<Avatar alt={user.nombre} src={user.foto} className={classes.bigAvatar}/>),
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                {/* use this button to add a like kind of action */}
                <GridContainer>
                  <GridItem className={"cursor-pointer"} xs={3} sm={3} md={4} onClick={() => {
                    this.handleGetCredits(user.id);
                    this.handleControlModal("creditsModal");
                  }}>
                    <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="like"
                    >
                      <Date/>
                    </Button>
                    Creditos
                  </GridItem>

                  <GridItem className={"cursor-pointer"} xs={3} sm={3} md={3} onClick={() => {
                    this.handleFocus(user);
                    this.handleControlModal("editModal");
                  }}>
                    <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="like"
                    >
                      <Create/>
                    </Button>
                    Editar
                  </GridItem>

                  <GridItem xs={3} sm={3} md={5}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={user.estado.nombre === "Habilitado"}
                          onChange={() => this.handleChangeState(user.id)}
                          color={"primary"}
                          classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={user.estado.nombre}
                    />
                  </GridItem>
                </GridContainer>
              </div>
            )
          };
        })
      });
    }
    if (prevProps.user.usersSearch !== usersSearch) {
      this.setState({
        dataSearch: usersSearch.map((user, index) => {
          const { classes } = this.props;
          return {
            id: index,
            Nombre: user.nombre,
            Cedula: user.identificacion,
            Correo: user.correo,
            Telefono: user.telefono,
            Ciudad: user.ciudad.nombre,
            Dirección: user.direccion ? user.direccion : "Sin dirección",
            Documentos: (<Button color="primary" onClick={() => {
              this.handleControlModal("documentsModal");
              this.handleGetDocuments(user.id);
            }}>Ver</Button>),
            FotoLogo: (<Avatar alt={user.nombre} src={user.foto} className={classes.bigAvatar}/>),
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                {/* use this button to add a like kind of action */}
                <GridContainer>
                  <GridItem className={"cursor-pointer"} xs={3} sm={3} md={4} onClick={() => {
                    this.handleGetCredits(user.id);
                    this.handleControlModal("creditsModal");
                  }}>
                    <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="like"
                    >
                      <Date/>
                    </Button>
                    Creditos
                  </GridItem>

                  <GridItem className={"cursor-pointer"} xs={3} sm={3} md={3} onClick={() => {
                    this.handleFocus(user);
                    this.handleControlModal("editModal");
                  }}>
                    <Button
                      justIcon
                      round
                      simple
                      color="info"
                      className="like"
                    >
                      <Create/>
                    </Button>
                    Editar
                  </GridItem>

                  <GridItem xs={3} sm={3} md={5}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={user.estado.nombre === "Habilitado"}
                          onChange={() => this.handleChangeState(user.id)}
                          color={"primary"}
                          classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={user.estado.nombre}
                    />
                  </GridItem>
                </GridContainer>
              </div>
            )
          };
        })
      });
    }
  }

  handleGetCsv = () => {
    const {
      auth: { user }
    } = this.props;
    window.open(`${process.env.REACT_APP_API_URL}/users/export?email=${user.correo}`, "_self");
  };

  handleFocus = (object) => {
    const {
      userFocus
    } = this.props;
    userFocus(object);
  };

  handleGetCredits = (objectId) => {
    const {
      client: { token },
      creditsUserGetRequesting
    } = this.props;
    creditsUserGetRequesting(token, objectId);
  };

  handleGetDocuments = (objectId) => {
    const {
      client: { token },
      documentsUserGetRequesting
    } = this.props;
    documentsUserGetRequesting(token, objectId);

  };

  handleFilter = (value) => {
    const {
      client: { token },
      user: { filter },
      usersGetRequesting
    } = this.props;
    if (value !== filter)
      usersGetRequesting(token, value, 1);
  };

  handleGetData = (newPaginate) => {
    const {
      client: { token },
      user: { filter, paginate, hasMore, hasLess },
      usersGetRequesting
    } = this.props;
    if (newPaginate === 1 && hasMore)
      usersGetRequesting(token, filter, paginate + newPaginate);
    if (newPaginate === -1 && hasLess && paginate > 1)
      usersGetRequesting(token, filter, paginate + newPaginate);
  };

  handleChangeState = (objectId) => {
    const {
      client: { token },
      userChangeStateRequesting
    } = this.props;
    userChangeStateRequesting(token, objectId);
  };

  handleControlModal = (modal) => {
    const {
      userControlModal,
      creditControlModal
    } = this.props;
    userControlModal(modal);
    creditControlModal(modal);
  };

  handleCreditFocus = (credit) => {
    const {
      creditFocus
    } = this.props;
    creditFocus(credit);
  };

  handleFilterTable = (value, column) => {
    const {
      usersFilterTableRequesting,
      client: { token }
    } = this.props;
    usersFilterTableRequesting(token, value, column);
  };

  render() {
    const { columns, data, dataSearch } = this.state;
    const {
      classes,
      state: { states },
      client: { token },
      user: { users, filter, addModal, editModal, documentsModal, creditsModal, search, usersSearch, hasMore, hasLess },
      credit: { creditsUser, detailCreditModal },
      document: { documentsUser }
    } = this.props;
    if (token === "") {
      return (
        <Redirect to={"/"}/>
      );
    }
    return (
      <GridContainer>
        {addModal && (
          <AddAdviserModal
            controlModal={this.handleControlModal.bind(this)}
            open={addModal}/>
        )}
        {creditsModal && creditsUser.length > 0 && (
          <DateCreditAModalModal
            controlModal={this.handleControlModal.bind(this)}
            focusCredit={this.handleCreditFocus.bind(this)}
            credits={creditsUser}
            open={creditsModal}
          />
        )}
        {editModal && (
          <EditAdviserModal
            controlModal={this.handleControlModal.bind(this)}
            open={editModal}/>
        )}
        {documentsModal && documentsUser.length > 0 && (
          <div></div>
        )}
        {detailCreditModal && (
          <DetailCreditModal
            controlModal={this.handleControlModal.bind(this)}
            open={detailCreditModal}
          />
        )}
        {search !== "" && (
          usersSearch.length > 0
            ? <GridItem xs={12}>
              <Card>
                <CardBody>
                  <ReactTable
                    data={dataSearch}
                    filterable
                    columns={columns}
                    defaultPageSize={5}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    previousText={"Anterior"}
                    nextText={"Siguiente"}
                    pageText={"Pagina"}
                    ofText={"de"}
                    rowsText={"datos"}
                    onFilteredChange={(value) => this.handleFilterTable(value)}
                    //loading para cargar
                  />
                </CardBody>
              </Card>
            </GridItem>
            : <NotFound/>
        )}

        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AssignmentInd/>
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Info Asesor</h4>
              <br/>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      {filter}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={filter}
                      onChange={(input) => this.handleFilter(input.target.value)}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select"
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="Todos"
                      >
                        Todos
                      </MenuItem>
                      {states.length > 0 && states.map((state, index) => (
                        <MenuItem key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}

                                  value={state.nombre}
                        >
                          {state.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                  <FormControl fullWidth>
                    <Button
                      color="primary" className={classes.marginRight}
                      onClick={() => {
                        this.handleControlModal("addModal");
                      }}>
                      Agregar Nuevo Asesor
                    </Button>
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={3} md={3}>
                  <FormControl fullWidth>
                    <Button color="primary" className={classes.marginRight}
                            onClick={() => this.handleGetCsv()}>
                      Descargar csv
                    </Button>
                  </FormControl>
                </GridItem>
              </GridContainer>

            </CardHeader>
            <CardBody>
              {users.length > 0 && (
                <ReactTable
                  data={data}
                  filterable
                  columns={columns}
                  defaultPageSize={users.length}
                  showPaginationBottom={true}
                  className="-striped -highlight"
                  previousText={"Anterior"}
                  nextText={"Siguiente"}
                  pageText={"Pagina"}
                  ofText={"de"}
                  rowsText={"datos"}
                  //loading para cargar
                  manual
                  NextComponent={(nextText) => {
                    return (
                      <button disabled={!hasMore} className={""}
                              onClick={() => this.handleGetData(+1)}>
                        {nextText.children}
                      </button>
                    );
                  }}
                  PreviousComponent={(previousText) => {
                    return (
                      <button disabled={!hasLess} className={""}
                              onClick={() => this.handleGetData(-1)}>
                        {previousText.children}
                      </button>
                    );
                  }}
                />
              )}
              {users.length === 0 && (
                <NotFound/>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

//Usar el nombre que se puso arriba aca
Product.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  client: state.client,
  user: state.user,
  auth: state.auth,
  state: state.state,
  document: state.document,
  credit: state.credit
});

const connected = connect(mapStateToProps, {
  usersGetRequesting,
  userChangeStateRequesting,
  userControlModal,
  statesGeneralGetRequesting,
  userFocus,
  documentsUserGetRequesting,
  creditsUserGetRequesting,
  creditFocus,
  creditControlModal,
  usersFilterTableRequesting,
  usersSearchError
})(withStyles(styles)(Product));
// Y aca ...
export default connected;