import React from 'react';
// Components Core
import Card from '../../Card/Card.jsx';
import CardBody from '../../Card/CardBody';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import Button from '../../CustomButtons/Button.jsx';
import {Form, reduxForm, Field, reset} from "redux-form";
import {customInputText} from "../../inputs/typeText";
import {customInputSelect} from "../../inputs/typeSelect";
import {customInputDate} from '../../inputs/inputDate';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {handleFormattedNumber} from "../../../helpers/number";
// Styles
import userProfileStyles from "../../../assets/jss/material-dashboard-pro-react/views/userProfileCompanyStyles";
// integration
import {connect} from "react-redux";
// Acciones
import { naturalPersonCreateRequesting , naturalPersonUpdateRequesting , naturalPersonCleanValues } from './../../../redux/naturalPerson/actions';

class FormNaturalPerson extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            activos: '',
            pasivos: '',
            egresos: '',
            ingresos: ''
        };
        this.handleCheckClientData = this.handleCheckClientData.bind(this);
        this.handleCheckClientData();
    }

    componentWillUnmount(){
        this.props.naturalPersonCleanValues();
    }

     handleCheckClientData(){
        const { 
            stateForm,
            clients: { client },
            naturalPerson: { person }
        } = this.props;
        if (person.hasOwnProperty("id")){
            this.props.stateForm.naturalPerson = {
                values: person
            };
        }
    }

    handleSubmit = (values) => {
        const {
            client: { token },
            clients: { client },
            naturalPersonCreateRequesting,
            naturalPersonUpdateRequesting,
            naturalPerson,
            personType
        } = this.props;
        values.personType = personType;

        if (naturalPerson.person.hasOwnProperty("id"))
            naturalPersonUpdateRequesting(token,values,client.id,naturalPerson.person.id);
        else
            naturalPersonCreateRequesting(token,values,client.id);
    };

    render(){
        const {
            classes,
            handleSubmit,
            naturalPerson
        } = this.props;
        return (
            <Form ref={this.formRef} onSubmit={handleSubmit(this.handleSubmit)}>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>
                        <Field
                            id={"nacionalidad"}
                            name={"nacionalidad"}
                            type={"text"}
                            label={"Nacionalidad *"}
                            autoComplete={"on"} 
                            component={customInputText} classes={classes}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                        <Field
                        id={"fechaDeExpedicion"}
                        name={"fechaDeExpedicion"}
                        label={"Fecha de expedición cédula *"}
                        type={"date"}
                        autoComplete={"on"}
                        component={customInputDate} classes={classes}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                        <Field
                            id={"genero"}
                            name={"genero"}
                            type={"select"}
                            label={"Genero *"}
                            autoComplete={"on"} 
                            component={customInputSelect} classes={classes}
                            data={[
                                {id: "Masculino", nombre: 'Masculino'},
                                {id: "Femenino", nombre: 'Femenino'}
                            ]}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Field
                        id={"estadoCivil"}
                        name={"estadoCivil"}
                        type={"text"}
                        label={"Estado civil *"}
                        autoComplete={"on"} 
                        component={customInputText} classes={classes}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Field
                        id={"telefono"}
                        name={"telefono"}
                        type={"number"}
                        label={"Telefono fijo *"}
                        autoComplete={"on"} 
                        component={customInputText} classes={classes}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Field
                        id={"ocupacion"}
                        name={"ocupacion"}
                        type={"text"}
                        label={"Ocupación *"}
                        autoComplete={"on"} 
                        component={customInputSelect} classes={classes}
                        data={[
                            {id: 'Independiente',nombre: 'Independiente'},
                            {id: 'Empleado', nombre: 'Empleado'},
                            {id: 'Otro', nombre: 'Otro'}
                        ]}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Field
                        id={"tipoDeVivienda"}
                        name={"tipoDeVivienda"}
                        type={"text"}
                        label={"Tipo de vivienda *"}
                        autoComplete={"on"} 
                        component={customInputText} classes={classes}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Field
                        id={"barrio"}
                        name={"barrio"}
                        type={"text"}
                        label={"Barrio *"}
                        autoComplete={"on"} 
                        component={customInputText} classes={classes}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Field
                        id={"nombreDeLaEmpresa"}
                        name={"nombreDeLaEmpresa"}
                        type={"text"}
                        label={"Nombre de la empresa *"}
                        autoComplete={"on"} 
                        component={customInputText} classes={classes}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Field
                        id={"actividadDeLaEmpresa"}
                        name={"actividadDeLaEmpresa"}
                        type={"text"}
                        label={"Actividad de la empresa *"}
                        autoComplete={"on"} 
                        component={customInputText} classes={classes}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Field
                        d={"cargoActual"}
                        name={"cargoActual"}
                        type={"text"}
                        label={"Cargo Actual *"}
                        autoComplete={"on"} 
                        component={customInputText} classes={classes}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Field
                        id={"nivelDeEstudios"}
                        name={"nivelDeEstudios"}
                        type={"text"}
                        label={"Nivel de estudios *"}
                        autoComplete={"on"} 
                        component={customInputText} classes={classes}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Field
                        id={"direccionOficina"}
                        name={"direccionOficina"}
                        type={"text"}
                        label={"Dirección *"}
                        autoComplete={"on"} 
                        component={customInputText} classes={classes}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Field
                        id={"telefonoOficina"}
                        name={"telefonoOficina"}
                        type={"text"}
                        label={"Telefono oficina *"}
                        autoComplete={"on"} 
                        component={customInputText} classes={classes}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Field
                        id={"totalIngresosMensuales"}
                        name={"totalIngresosMensuales"}
                        type={"number"}
                        label={"Total ingresos mensuales *"}
                        autoComplete={"on"} 
                        customvalue={this.state.ingresos}
                        onChange={(e) => {
                            this.setState({ingresos: handleFormattedNumber(e.target.value)})
                        }}
                        component={customInputText} classes={classes}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Field
                        id={"totalEgresosMensuales"}
                        name={"totalEgresosMensuales"}
                        type={"number"}
                        label={"Total egresos mensuales *"}
                        autoComplete={"on"} 
                        customvalue={this.state.egresos}
                        onChange={(e) => {
                            this.setState({egresos: handleFormattedNumber(e.target.value)})
                        }}
                        component={customInputText} classes={classes}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Field
                        id={"totalActivos"}
                        name={"totalActivos"}
                        type={"number"}
                        label={"Total activos *"}
                        autoComplete={"on"} 
                        customvalue={this.state.activos}
                        onChange={(e) => {
                            this.setState({activos: handleFormattedNumber(e.target.value)})
                        }}
                        component={customInputText} classes={classes}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Field
                        id={"totalPasivos"}
                        name={"totalPasivos"}
                        type={"number"}
                        label={"Total pasivos *"}
                        autoComplete={"on"} 
                        customvalue={this.state.pasivos}
                        onChange={(e) => {
                            this.setState({pasivos: handleFormattedNumber(e.target.value)})
                        }}
                        component={customInputText} classes={classes}
                    />
                </GridItem>
            </GridContainer>
            {!naturalPerson.person.hasOwnProperty("id") &&
            <Button
                    type={"submit"}
                    color="primary"
                    className={classes.updateProfileButton}
            >
                Siguiente
            </Button>}

            {naturalPerson.person.hasOwnProperty("id") &&
            <Button
                type={"submit"}
                color="success"
                className={classes.updateProfileButton}
            >
                Actualizar
            </Button>}
        </Form>
        );
    }
}

const mapStateToProps = state => ({
    client: state.client,
    naturalPerson: state.naturalPerson,
    clients: state.clients,
    stateForm: state.form
});

const connected = connect(mapStateToProps, {
    naturalPersonCreateRequesting,
    naturalPersonUpdateRequesting,
    naturalPersonCleanValues
})(withStyles(userProfileStyles)(FormNaturalPerson));

const formed = reduxForm({
    form: "naturalPerson",
    reset
})(connected);

export default formed;