import {
  EMAIL_NOTIFICATION_CREATE_ERROR,
  EMAIL_NOTIFICATION_CREATE_REQUESTING,
  EMAIL_NOTIFICATION_CREATE_SUCCESS, EMAIL_NOTIFICATION_RESET_PAYMENT_PLAN_ID,
  EMAIL_NOTIFICATIONS_GET_ERROR,
  EMAIL_NOTIFICATIONS_GET_REQUESTING,
  EMAIL_NOTIFICATIONS_GET_SUCCESS
} from "./constants";

const initialState = {
  requesting: false,
  success: false,
  error: null,
  paymentPlanId: "",
  emailNotifications: [],
  warningEmails: [],
  pendingEmails: [],
  cancellationEmail: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case EMAIL_NOTIFICATION_CREATE_REQUESTING:
      return {
        ...state,
        requesting: true,
        success: false,
        error: null
      };
    case EMAIL_NOTIFICATION_CREATE_SUCCESS:
      return {
        ...state,
        requesting: false,
        success: true
      };
    case EMAIL_NOTIFICATION_CREATE_ERROR:
      return {
        ...state,
        requesting: false,
        error: action.error
      };
    case EMAIL_NOTIFICATIONS_GET_REQUESTING:
      return {
        ...state,
        requesting: true,
        success: false,
        paymentPlanId: action.paymentPlanId,
        error: null
      };
    case EMAIL_NOTIFICATIONS_GET_SUCCESS:
      return {
        ...state,
        requesting: false,
        success: true,
        emailNotifications: action.emailNotifications,
        warningEmails: action.emailNotifications.filter((email) => email.asunto === "Notificación de su credito"),
        pendingEmails: action.emailNotifications.filter((email) => email.asunto === "Notificación de su credito en mora"),
        cancellationEmail: action.emailNotifications.filter((email) => email.asunto === "Notificación de su credito fue cancelado"),
      };
    case EMAIL_NOTIFICATIONS_GET_ERROR:
      return {
        ...state,
        requesting: false,
        error: action.error
      };
    case EMAIL_NOTIFICATION_RESET_PAYMENT_PLAN_ID:
      return {
        ...state,
        paymentPlanId: ""
      };
    default:
      return state;
  }
};

export default reducer;