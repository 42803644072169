export const POLICY_CREATE_REQUESTING = "POLICY_CREATE_REQUESTING";
export const POLICY_CREATE_SUCCESS = "POLICY_CREATE_SUCCESS";
export const POLICY_CREATE_ERROR = "POLICY_CREATE_ERROR";

export const POLICY_UPDATE_REQUESTING = "POLICY_UPDATE_REQUESTING";
export const POLICY_UPDATE_SUCCESS = "POLICY_UPDATE_SUCCESS";
export const POLICY_UPDATE_ERROR = "POLICY_UPDATE_ERROR";

export const POLICY_UPDATE_PRICE_REQUESTING = "POLICY_UPDATE_PRICE_REQUESTING";
export const POLICY_UPDATE_PRICE_SUCCESS = "POLICY_UPDATE_PRICE_SUCCESS";
export const POLICY_UPDATE_PRICE_ERROR = "POLICY_UPDATE_PRICE_ERROR";

export const POLICY_DELETE_REQUESTING = "POLICY_DELETE_REQUESTING";
export const POLICY_DELETE_SUCCESS = "POLICY_DELETE_SUCCESS";
export const POLICY_DELETE_ERROR = "POLICY_DELETE_ERROR";

export const POLICY_RESET_FOCUS = "POLICY_RESET_FOCUS";
export const POLICY_CLEAN_VALUES = "POLICY_CLEAN_VALUES";