import React from "react";
import { toastr } from "react-redux-toastr";
import { call, put, all, takeEvery } from "redux-saga/effects";
import { handleApiErrors } from "../../commons/errors/apiErrors";
import { ErrorComponent } from "../../commons/errors/errors";
import {
	emailNotificationCreateError,
	emailNotificationCreateSuccess,
	emailNotificationsGetError,
	emailNotificationsGetSuccess,
	emailNotificationResetPaymentPlanId
} from "./actions";
import { creditControlModal } from "../credit/actions";
import { EMAIL_NOTIFICATION_CREATE_REQUESTING, EMAIL_NOTIFICATIONS_GET_REQUESTING } from "./constants";

const baseUrl = `${process.env.REACT_APP_API_URL}/emailNotifications`;

const showError = (error) => {
	let toastOptions = {
		component: (
			<ErrorComponent message={error}/>
		)
	};
	toastr.error("Error", toastOptions);
};

const emailNotificationCreateApi = (token, subject, planPaymentId) => {
	return fetch(`${baseUrl}/${subject}/${planPaymentId}`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`
		}
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* emailNotificationCreateFlow(action) {
	try {
		const { token, subject, planPaymentId } = action;
		const emailNotification = yield call(emailNotificationCreateApi, token, subject, planPaymentId);
		yield put(emailNotificationCreateSuccess());
		yield put(creditControlModal("paymentPlansModal"));
		toastr.success("Notificación enviada", `La notificación fue enviada con exito al correo ${emailNotification.cliente.correo}.`);
		yield put(emailNotificationResetPaymentPlanId());
	} catch (error) {
		yield put(emailNotificationCreateError(error));
		showError(error);
	}
}

const emailNotificationsGetApi = (token, paymentPlanId) => {
	return fetch(`${baseUrl}/${paymentPlanId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`
		}
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* emailNotificationsGetFlow(action) {
	try {
		const {token, paymentPlanId} = action;
		const emailNotifications = yield call(emailNotificationsGetApi, token, paymentPlanId);
		yield put(emailNotificationsGetSuccess(emailNotifications));
	} catch (error) {
		yield put(emailNotificationsGetError(error));
	}
}

function* emailNotificationWatcher() {
	yield all([
		takeEvery(EMAIL_NOTIFICATION_CREATE_REQUESTING, emailNotificationCreateFlow),
		takeEvery(EMAIL_NOTIFICATIONS_GET_REQUESTING, emailNotificationsGetFlow),
	])
}

export default emailNotificationWatcher;