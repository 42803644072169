import { call, put, all, takeEvery } from "redux-saga/effects";
import { handleApiErrors } from "../../commons/errors/apiErrors";
import { auditsClassGetError, auditsClassGetSuccess } from "./actions";
import { AUDITS_CLASS_GET_REQUESTING } from "./constants";

const baseUrl = `${process.env.REACT_APP_API_URL}/audits`;

const auditsClassGetApi = (token, className) => {
	return fetch(`${baseUrl}/${className}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`
		}
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* auditsClassGetFlow(action) {
	try {
		const {token, className} = action;
		const audits = yield call(auditsClassGetApi, token, className);
		yield put(auditsClassGetSuccess(audits));
	} catch (error) {
		yield put(auditsClassGetError(error));
	}
}

function* auditWatcher() {
	yield all([
		takeEvery(AUDITS_CLASS_GET_REQUESTING, auditsClassGetFlow)
	])
}

export default auditWatcher;