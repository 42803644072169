import {call, put, all, takeEvery} from 'redux-saga/effects';
import {CHECK_AUTH} from "./constans";
import {checkAuthError, checkAuthSuccess} from "./actions";
import {handleApiErrors} from "../../../commons/errors/apiErrors";
import {meGetRequesting} from "../user/actions";
import {statesGeneralGetRequesting} from "../../state/actions";
import {logoutGetRequesting} from "../logout/actions";

const checkJWT = `${process.env.REACT_APP_API_URL}/verifyAdmin`;

const checkTokenJWT = () => {
    let token = localStorage.getItem('@danko:admin');
    return fetch(checkJWT, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 200)
                return token;
	        if (json.code === 400) {
		        localStorage.removeItem('@danko:admin');
		        throw json.data.data;
	        }
        }).catch((error) => {
            throw error;
        })
};


function* checkAuthFlow() {
    try {
        const token = yield call(checkTokenJWT);
        yield put(checkAuthSuccess(token));
        yield put(meGetRequesting(token));
        yield put(statesGeneralGetRequesting(token));
    } catch (error) {
        yield put(checkAuthError(error));
    }
}

function* checkAuthWatcher() {
    yield all([
        takeEvery(CHECK_AUTH, checkAuthFlow)
    ])
}

export default checkAuthWatcher;