/*!
=========================================================
* Developer DigitalParchapp V.1
=========================================================
*/
import React from "react";
import PropTypes from "prop-types";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import HistoryIcon from '@material-ui/icons/History';
import Create from "@material-ui/icons/Create";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { dataTable } from "variables/general.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
// Modals
import EditAnnualRateModal from "./Modal/annualRate";
// Integration
import {
	configurationsGetRequesting,
	configurationsHistoryGetRequesting,
	configurationControlModal,
	configurationFocus
} from "../../redux/configuration/actions";
import { auditsClassGetRequesting } from "../../redux/audit/actions";
import { connect } from "react-redux";
import { NotFound } from "../../commons/errors/notFound";
import { Redirect } from "react-router-dom";
import { FormattedNumber } from "react-intl";

const formattedNumber = (value) => {
  return (
    <FormattedNumber value={value} style={"currency"} currency={"COP"}
                     minimumFractionDigits={0} maximumFractionDigits={2}/>
  );
};

const styles = {
	cardIconTitle: {
		...cardTitle,
		marginTop: "15px",
		marginBottom: "0px",
		fontWeight: 700
	},
	textTable: {
		fontWeight: 600
	}
};

// Cambiar el nombre de la clase segun el que se vaya a usar
class Product extends React.Component {

	constructor(props) {
		super(props);
		const {
			client: { token },
			configurationsGetRequesting,
			auditsClassGetRequesting
		} = this.props;
		configurationsGetRequesting();
		auditsClassGetRequesting(token, "configuration");
		this.state = {
			data: [],
			dataHistory: [],
			columnsData: [
				{
					Header: "Nombre",
					accessor: "name",
					width: 200,
					sortable: false,
					filterable: false,
					className: "text-center"
				},
				{
					Header: "Valor",
					accessor: "value",
					width: 200,
					sortable: false,
					filterable: false,
					className: "text-center"
				},
				{
					width: 400,
					Header: "Acciones",
					accessor: "actions",
					sortable: false,
					filterable: false,
					className: "text-center"
				}
			],
			columnsHistory: [
				{
					Header: "Nombre",
					accessor: "name",
					width: 200,
					sortable: false,
					filterable: false,
					className: "text-center"
				},
				{
					Header: "Valor anterior",
					accessor: "oldValue",
					width: 200,
					sortable: false,
					filterable: false,
					className: "text-center"
				},
				{
					Header: "Valor nuevo",
					accessor: "newValue",
					width: 200,
					sortable: false,
					filterable: false,
					className: "text-center"
				},
				{
					Header: "Fecha",
					accessor: "date",
					width: 200,
					sortable: false,
					filterable: false,
					className: "text-center"
				},
				{
					Header: "Usuario",
					accessor: "user",
					width: 200,
					sortable: false,
					filterable: false,
					className: "text-center"
				}
			]
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {
			configuration: { configurations },
			audit: { audits }
		} = this.props;
		if (prevProps.configuration.configurations !== configurations) {
			this.setState({
				data: configurations.map((configuration, index) => {
					return {
						id: index,
						name: configuration.nombre,
						value: configuration.nombre !== "Tasa administración" ? configuration.valor + " " + "%" : formattedNumber(configuration.valor),
						actions: (
							// we've added some custom button actions
							<div className="actions-right">
								{/* use this button to add a like kind of action */}
								<GridContainer>
									<GridItem className={"cursor-pointer"} xs={3} sm={3} md={6} onClick={() => {
										this.handleFocus(configuration);
										this.handleControlModal("editModal");
									}}>
										<Button
											justIcon
											round
											simple
											color="info"
											className="like"
										>
											<Create/>
										</Button>
										Editar
									</GridItem>
								</GridContainer>
							</div>
						)
					};
				})
			});
		}
		if (prevProps.audit.audits !== audits) {
			this.setState({
				dataHistory: audits.map((audit, index) => {
					return {
						id: index,
						name: audit.configuracion.nombre,
						oldValue: audit.configuracion.nombre !== "Tasa administración" ? audit.valor_anterior + " " + "%": formattedNumber(audit.valor_anterior),
						newValue: audit.configuracion.nombre !== "Tasa administración" ? audit.valor_nuevo + " " + "%" : formattedNumber(audit.valor_nuevo),
						date: audit.fecha_creacion,
						user: audit.usuario.nombre
					};
				})
			});
		}
	}

	handleControlModal = (modal) => {
		const {
			configurationControlModal
		} = this.props;
		configurationControlModal(modal);
	};

	handleFocus = (object) => {
		const {
			configurationFocus
		} = this.props;
		configurationFocus(object);
	};

	render() {
		const { columnsData, columnsHistory, data, dataHistory } = this.state;
		const {
			classes,
			client: { token },
			configuration: { configurations, editModal },
			audit: { audits }
		} = this.props;
		if (token === "") {
			return (
				<Redirect to={"/"}/>
			);
		}
		return (
			<GridContainer>
				{editModal && (
					<EditAnnualRateModal
						controlModal={this.handleControlModal.bind(this)}
						open={editModal}/>
				)}

				<GridItem xs={12}>
					<Card>
						<CardHeader color="primary" icon>
							<CardIcon color="primary">
								<SettingsApplicationsIcon/>
							</CardIcon>
							<h4 className={classes.cardIconTitle}>Configuración</h4>
							<br/>

						</CardHeader>
						<CardBody>
							{configurations.length > 0 && (
								<ReactTable
									data={data}
									filterable
									columns={columnsData}
									defaultPageSize={configurations.length}
									showPaginationBottom={false}
									className="-striped -highlight"
									previousText={"Anterior"}
									nextText={"Siguiente"}
									pageText={"Pagina"}
									ofText={"de"}
									rowsText={"datos"}
									//loading para cargar
								/>
							)}
							{configurations.length === 0 && (
								<NotFound/>
							)}
						</CardBody>
					</Card>
				</GridItem>

				<GridItem xs={12}>
					<Card>
						<CardHeader color="primary" icon>
							<CardIcon color="primary">
								<HistoryIcon/>
							</CardIcon>
							<h4 className={classes.cardIconTitle}>Historico</h4>
							<br/>
						</CardHeader>
						<CardBody>
							{audits.length > 0 && (
								<ReactTable
									data={dataHistory}
									filterable
									columns={columnsHistory}
									defaultPageSize={audits.length}
									showPaginationBottom={false}
									className="-striped -highlight"
									previousText={"Anterior"}
									nextText={"Siguiente"}
									pageText={"Pagina"}
									ofText={"de"}
									rowsText={"datos"}
									//loading para cargar
								/>
							)}
							{audits.leading === 0 && (
								<NotFound/>
							)}
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

//Usar el nombre que se puso arriba aca
Product.propTypes = {
	classes: PropTypes.object
};

const mapStateToProps = state => ({
	client: state.client,
	auth: state.auth,
	configuration: state.configuration,
	audit: state.audit
});

const connected = connect(mapStateToProps, {
	configurationsGetRequesting,
	configurationControlModal,
	configurationFocus,
	auditsClassGetRequesting
})(withStyles(styles)(Product));

// Y aca ...
export default connected;
