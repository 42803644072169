// /*!// =========================================================
// * Developer DigitalParchapp V.1
// =========================================================// */
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/core/SvgIcon/SvgIcon";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import notificationsStyle from "../../../assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
import Slide from "@material-ui/core/Slide";
// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
// core components
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import { FormattedNumber } from "react-intl";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const formattedNumber = (value) => {
	return (
		<FormattedNumber value={value} style={"currency"} currency={"COP"}
		                 minimumFractionDigits={0} maximumFractionDigits={0}/>
	);
};

class DateCreditCustomers extends React.Component {

	render() {
		const {
			classes,
			open,
			controlModal,
			credits,
			focusCredit
		} = this.props;
		return <Dialog
			classes={{
				root: classes.center + " " + classes.modalRoot,
				paper: classes.modal
			}}
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => controlModal("creditsModal")}
			aria-labelledby="classic-modal-slide-title"
			aria-describedby="classic-modal-slide-description"
		>
			<CardHeader color="primary" icon>
				<CardIcon color="primary">
					<MailOutline/>
				</CardIcon>
			</CardHeader>

			<DialogTitle
				id="classic-modal-slide-title"
				disableTypography
				className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key="close"
					aria-label="Close"
					color="transparent"
					onClick={() => controlModal("creditsModal")}
				>
					<Close className={classes.modalClose}/>
				</Button>
				<h4 className={classes.modalTitle}>Datos de Credito</h4>
			</DialogTitle>
			<DialogContent
				id="classic-modal-slide-description"
				className={classes.modalBody}>
				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody}>

					<div className="Conteiner-table">
						<table className="table-messenger">
							<thead>
							<tr>
								<th>Fecha Crédito</th>
								<th>Numero de Crédito</th>
								<th>Nombre Cliente</th>
								<th>Valor Credito</th>
								<th>Acción</th>
							</tr>
							</thead>
							<tbody>
							{credits.map((credit, index) => (
								<tr key={index}>
									<th>{credit.fecha_creacion}</th>
									<th>{credit.id}</th>
									<th>{credit.cliente.nombres} {credit.cliente.apellidos}</th>
									<th>{formattedNumber(credit.poliza.valor)}</th>
									<th>
										<Button
											color="primary" className={classes.marginRight}
											onClick={() => {
												focusCredit(credit);
												controlModal("detailCreditModal");
											}}>
											Ver
										</Button>
									</th>
								</tr>
							))}
							</tbody>
						</table>
					</div>
					<br/>
					<DialogActions className={classes.modalFooter}>
						<Button onClick={() => controlModal("creditsModal")} color="warning">
							Cerrar
						</Button>
					</DialogActions>
				</DialogContent>
			</DialogContent>
		</Dialog>;
	}
}

DateCreditCustomers.propTypes = {
	classes: PropTypes.object
};

export default withStyles(notificationsStyle)(DateCreditCustomers);