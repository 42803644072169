import {
	EMAIL_NOTIFICATION_CREATE_ERROR,
	EMAIL_NOTIFICATION_CREATE_REQUESTING,
	EMAIL_NOTIFICATION_CREATE_SUCCESS, EMAIL_NOTIFICATION_RESET_PAYMENT_PLAN_ID,
	EMAIL_NOTIFICATIONS_GET_ERROR,
	EMAIL_NOTIFICATIONS_GET_REQUESTING,
	EMAIL_NOTIFICATIONS_GET_SUCCESS
} from "./constants";

export const emailNotificationCreateRequesting = (token, subject, planPaymentId) => ({
	type: EMAIL_NOTIFICATION_CREATE_REQUESTING,
	token, subject, planPaymentId,
});

export const emailNotificationCreateSuccess = () => ({
	type: EMAIL_NOTIFICATION_CREATE_SUCCESS,
});

export const emailNotificationCreateError = (error) => ({
	type: EMAIL_NOTIFICATION_CREATE_ERROR,
	error,
});

export const emailNotificationsGetRequesting = (token, paymentPlanId) => ({
	type: EMAIL_NOTIFICATIONS_GET_REQUESTING,
	token, paymentPlanId,
});

export const emailNotificationsGetSuccess = (emailNotifications) => ({
	type: EMAIL_NOTIFICATIONS_GET_SUCCESS,
	emailNotifications,
});

export const emailNotificationsGetError = (error) => ({
	type: EMAIL_NOTIFICATIONS_GET_ERROR,
	error,
});

export const emailNotificationResetPaymentPlanId = () => ({
	type: EMAIL_NOTIFICATION_RESET_PAYMENT_PLAN_ID,
});