import {
	CONFIGURATION_CONTROL_MODAL, CONFIGURATION_FOCUS,
	CONFIGURATION_UPDATE_ERROR,
	CONFIGURATION_UPDATE_REQUESTING, CONFIGURATION_UPDATE_SUCCESS,
	CONFIGURATIONS_GET_ERROR,
	CONFIGURATIONS_GET_REQUESTING,
	CONFIGURATIONS_GET_SUCCESS, CONFIGURATIONS_HISTORY_GET_ERROR,
	CONFIGURATIONS_HISTORY_GET_REQUESTING, CONFIGURATIONS_HISTORY_GET_SUCCESS
} from "./constants";

export const configurationsGetRequesting = () => ({
	type: CONFIGURATIONS_GET_REQUESTING,
});

export const configurationsGetSuccess = (configurations) => ({
	type: CONFIGURATIONS_GET_SUCCESS,
	configurations
});

export const configurationsGetError = (error) => ({
	type: CONFIGURATIONS_GET_ERROR,
	error
});

export const configurationsHistoryGetRequesting = (token) => ({
	type: CONFIGURATIONS_HISTORY_GET_REQUESTING,
	token
});

export const configurationsHistoryGetSuccess = (configurations) => ({
	type: CONFIGURATIONS_HISTORY_GET_SUCCESS,
	configurations
});

export const configurationsHistoryGetError = (error) => ({
	type: CONFIGURATIONS_HISTORY_GET_ERROR,
	error
});

export const configurationUpdateRequesting = (token, values) => ({
	type: CONFIGURATION_UPDATE_REQUESTING,
	token, values
});

export const configurationUpdateSuccess = (configuration) => ({
	type: CONFIGURATION_UPDATE_SUCCESS,
	configuration
});

export const configurationUpdateError = (error) => ({
	type: CONFIGURATION_UPDATE_ERROR,
	error
});

export const configurationControlModal = (modal) => ({
	type: CONFIGURATION_CONTROL_MODAL,
	modal
});

export const configurationFocus = (configuration) => ({
	type: CONFIGURATION_FOCUS,
	configuration
});