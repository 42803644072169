import {
	SEND_SMS_REQUESTING,
	SEND_SMS_SUCCESS,
	SEND_SMS_ERROR
} from './constants';

const initialState = {
	requesting: false,
	success: false,
	error: ""
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SEND_SMS_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: ""
			};
		case SEND_SMS_SUCCESS:
		    return {
		    	...state,
		    	requesting: false,
		    	success: true,
		    };
		case SEND_SMS_ERROR:
		    return {
		    	...state,
		    	requesting: false,
		    	success: false,
		    	error: action.error
		    };
		default:
			return state;
	}
};

export default reducer;