import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  CardHeader,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import { MailOutline, Close } from "@material-ui/icons";
import CardIcon from "../../../components/Card/CardIcon";
import Button from "../../../components/CustomButtons/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import notificationsStyle from "../../../assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem";
import OnerosoNotification from './onerosoNotification';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class StatesModal extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      onerosoNotificationModal : false
    };
  }

  handleToggleOnerosoNotificationModal(){
    if (!this.props.credit.oneroso){
        this.props.handleGetInfoProp();
        this.setState((state,props) => ({
            onerosoNotificationModal: !state.onerosoNotificationModal
        }));
    }
  }

  handleUpdateOnerosoState(){
      this.props.credit.oneroso = true;
  }

  render() {
    const {
      classes,
      controlModal,
      open,
      credit,
      changeState
    } = this.props;
    const { onerosoNotificationModal } = this.state;
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal
        }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => controlModal("statesCreditModal")}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <MailOutline/>
          </CardIcon>
        </CardHeader>
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => controlModal("statesCreditModal")}
          >
            <Close className={classes.modalClose}/>
          </Button>
          <h4 className={classes.modalTitle}>Estados del credito</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}>


          <OnerosoNotification 
             open={onerosoNotificationModal}
             controlModal={this.handleToggleOnerosoNotificationModal.bind(this)}
             handleUpdateOnerosoState={this.handleUpdateOnerosoState.bind(this)}
          />


          <GridItem className="style-sw">
            <GridItem xs={12} sm={12} md={12}>
                <h4> Validación </h4>  <hr/>
              <FormControlLabel
                control={
                  <Switch
                    checked={credit.centrales_riesgo}
                    onChange={(e) => changeState(credit.id, e)}
                    color={"primary"}
                    name={"centralRisk"}
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      thumb: classes.switchIcon,
                      track: classes.switchBar
                    }}
                  />
                }
                classes={{
                  label: classes.label
                }}
                label="Aceptado"
              />

                <FormControlLabel
                    control={
                        <Switch
                            checked={credit.rechazado}
                            onChange={(e) => changeState(credit.id, e)}
                            name="rejected"
                            color={"primary"}
                            classes={{
                                switchBase: classes.switchBase,
                                checked: classes.switchChecked,
                                thumb: classes.switchIcon,
                                track: classes.switchBar
                            }}
                        />
                    }
                    classes={{
                        label: classes.label
                    }}
                    label="Rechazado"
                />
            </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                  <FormControlLabel
                      control={
                          <Switch
                              checked={credit.documentos}
                              //onChange={(e) => changeState(credit.id, e)}
                              color={"primary"}
                              name={"documents"}
                              classes={{
                                  switchBase: classes.switchBase,
                                  checked: classes.switchChecked,
                                  thumb: classes.switchIcon,
                                  track: classes.switchBar
                              }}
                          />
                      }
                      classes={{
                          label: classes.label
                      }}
                      label="Asesor subiendo documentos"
                  />
              </GridItem>

              {/*<GridItem xs={12} sm={12} md={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={credit.paquete_financiacion}
                    //onChange={(e) => changeState(credit.id, e)}
                    color={"primary"}
                    name={"documents"}
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      thumb: classes.switchIcon,
                      track: classes.switchBar
                    }}
                  />
                }
                classes={{
                  label: classes.label
                }}
                label="Paquete de financiación"
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={credit.diligencia_poliza}
                    onChange={(e) => changeState(credit.id, e)}
                    color={"primary"}
                    name="policy"
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      thumb: classes.switchIcon,
                      track: classes.switchBar
                    }}
                  />
                }
                classes={{
                  label: classes.label
                }}
                label="Dilig. Poliza"
              />
            </GridItem>


            <GridItem xs={12} sm={12} md={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={credit.cuota_inicial}
                    //onChange={(e) => changeState(credit.id, e)}
                    name="initialPayment"
                    color={"primary"}
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      thumb: classes.switchIcon,
                      track: classes.switchBar
                    }}
                  />
                }
                classes={{
                  label: classes.label
                }}
                label="Cuota inicial"
              />
            </GridItem>*/}


              <GridItem xs={12} sm={12} md={12}>
                  <FormControlLabel
                      control={
                          <Switch
                              checked={credit.firma_digital}
                              //onChange={(e) => changeState(credit.id, e)}
                              name="rejected"
                              color={"primary"}
                              classes={{
                                  switchBase: classes.switchBase,
                                  checked: classes.switchChecked,
                                  thumb: classes.switchIcon,
                                  track: classes.switchBar
                              }}
                          />
                      }
                      classes={{
                          label: classes.label
                      }}
                      label="Firma electronica"
                  />
              </GridItem>


              <GridItem xs={12} sm={12} md={12}>
                  <FormControlLabel
                      control={
                          <Switch
                              checked={credit.comprobante_de_pago}
                              //onChange={(e) => changeState(credit.id, e)}
                              name="initialPayment"
                              color={"primary"}
                              classes={{
                                  switchBase: classes.switchBase,
                                  checked: classes.switchChecked,
                                  thumb: classes.switchIcon,
                                  track: classes.switchBar
                              }}
                          />
                      }
                      classes={{
                          label: classes.label
                      }}
                      label="Comprobante de pago"
                  />
              </GridItem>


            <GridItem xs={12} sm={12} md={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={credit.desembolso}
                    onChange={(e) => changeState(credit.id, e)}
                    name="disbursement"
                    color={"primary"}
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      thumb: classes.switchIcon,
                      track: classes.switchBar
                    }}
                  />
                }
                classes={{
                  label: classes.label
                }}
                label="Desembolso"
              />
            </GridItem>


            <GridItem xs={12} sm={12} md={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={credit.oneroso}
                    onChange={this.handleToggleOnerosoNotificationModal.bind(this)}
                    name="onerosoNotification"
                    color={"primary"}
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      thumb: classes.switchIcon,
                      track: classes.switchBar
                    }}
                  />
                }
                classes={{
                  label: classes.label
                }}
                label="Notificación oneroso"
              />
            </GridItem>
          </GridItem>
          <br/>
        </DialogContent>
      </Dialog>
    );
  }
}

StatesModal.propTypes = {
  classes: PropTypes.object
};

export default withStyles(notificationsStyle)(StatesModal);
