import { call, put, all, takeEvery } from "redux-saga/effects";
import { handleApiErrors } from "../../commons/errors/apiErrors";
import { ErrorComponent } from "../../commons/errors/errors";
import { toastr } from "react-redux-toastr";
import React from "react";
import {
	documentsCreateError,
	documentsCreateSuccess,
	documentsUserGetError,
	documentsUserGetSuccess,
	documentFinancingPackageCreateSuccess,
    documentFinancingPackageCreateError,
    documentAuthorizeCreateSuccess,
    documentAuthorizeCreateError,
    documentCheckDigitalSignatureSuccess,
    documentCheckDigitalSignatureError,
    documentSendMailSignatureSuccess,
    documentSendMailSignatureError
} from "./actions";
import { creditControlModal , creditDocumentsRequesting } from "../credit/actions";
import { userControlModal } from "../user/actions";
import { DOCUMENTS_CREATE_REQUESTING,
         DOCUMENTS_USER_GET_REQUESTING,
         DOCUMENT_FINANCING_PACKAGE_CREATE_REQUESTING,
         DOCUMENT_CHECK_DIGITAL_SIGNATURE_REQUESTING,
         DOCUMENT_AUTHORIZE_CREATE_REQUESTING,
         DOCUMENT_SENT_MAIL_SIGNATURE_REQUESTING
       } from "./constants";
import {creditChangeStateSuccess} from "../credit/actions";

const baseUrl = `${process.env.REACT_APP_API_URL}/documents`;

const showError = (error) => {
	let toastOptions = {
		component: (
			<ErrorComponent message={error}/>
		)
	};
	toastr.error("Error", toastOptions);
};

const documentsUserGetApi = (token, userId) => {
	return fetch(`${baseUrl}/user/${userId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`
		}
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 400)
				throw [json.data];
			if (json.code === 422)
				throw json.data;
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* documentsUserGetFlow(action) {
	try {
		const { token, userId } = action;
		const documents = yield call(documentsUserGetApi, token, userId);
		if (documents.length === 0)
			toastr.info("Sin documentos", "El agente no cuenta con documentos.");
		yield put(documentsUserGetSuccess(documents));
	} catch (error) {
		yield put(documentsUserGetError(error));
		showError(error);
	}
}

const documentsCreateApi = (token, objectName, objectId, values) => {
	let body = new FormData();
	Object.keys(values).map(key => {
		if (key.indexOf("document_") >= 0) {
			body.append(key.split("document_")[1].replace(/\s/g, ""), values[key][0].file);
		}
	});

    if (values.pagare)
        body.append("pagare", values.pagare);
    
	const requestUri = objectName === 'onerosoNotification' ? `${baseUrl}/${objectName}/${objectId}/${values.id}` : `${baseUrl}/${objectName}/${objectId}`;
	return fetch(requestUri, {
		'Content-Type': 'application/json',
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`
		},
		body: body
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 400)
				throw [json.data];
			if (json.code === 422)
				throw json.data;
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* documentsCreateFlow(action) {
	try {
		const { token, objectName, objectId, values } = action;

        if (!values.pagare && values["document_Comprobante de pago"]){
            toastr.error("Debes ingresar el pagaré antes de continuar");
            yield put(documentsCreateError(""));
            return;
        }

		const credit = yield call(documentsCreateApi, token, objectName, objectId, values);
		yield put(documentsCreateSuccess());
		toastr.success('Documentos creados', 'Los documentos fueron creados con exito.');
		yield put(creditChangeStateSuccess(credit));
		if (objectName === "credit")
			yield put(creditControlModal("documentsModal"));
		if (objectName === "user")
			yield put(userControlModal("documentsModal"));
	} catch (error) {
		yield put(documentsCreateError(error));
		showError(error);
	}
}

const documentFinancingPackageApi = (token, creditId) => {
    return fetch(`${baseUrl}/financingPackage/${creditId}`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
      .then(response => response.json())
      .then(json => {
        if (json.code === 400)
            throw [json.data];
        if (json.code === 422)
            throw json.data;
        if (json.code === 200)
            return json.data.data;
        throw json.data;
      })
      .catch((error) => {
        throw error;
      });
};


function* documentFinancingPackageFlow(action){
    try{
        const { token , creditId } = action;
        const message = yield call(documentFinancingPackageApi , token , creditId);
        yield put(creditDocumentsRequesting(token, creditId));
        yield put(documentFinancingPackageCreateSuccess());
        toastr.success("Documento creado", message);
    }catch (error){
        yield put(documentFinancingPackageCreateError(error));
        showError(error);
    }
}

const documentCheckDigitalSignatureApi = (token , documentId) => {
    return fetch(`${baseUrl}/check/digitalSignature/${documentId}`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
      .then(response => response.json())
      .then(json => {
         if (json.code === 400)
            throw [json.data];
        if (json.code === 422)
            throw json.data;
        if (json.code === 200)
            return json.data.data;
        if (json.data){
            console.log(JSON.parse(json.data));
            return JSON.parse(json.data).responses[0];
        }
        throw json.data;
      });
};

function* documentCheckDigitalSignatureFlow(action){
    try{
        const { token , documentId } = action;
        const verifyData = yield call(documentCheckDigitalSignatureApi, token , documentId);
        yield put(documentCheckDigitalSignatureSuccess(verifyData,documentId));
    }catch (error){
        yield put(documentCheckDigitalSignatureError(error));
        showError(error);
    }
}

const documentAuthorizeCreateApi = (token, creditId) => {
    return fetch(`${baseUrl}/authorize/${creditId}` , {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
      .then(response => response.json())
      .then(json => {
         if (json.code === 400)
            throw [json.data];
        if (json.code === 422)
            throw json.data;
        if (json.code === 200)
            return json.data.data;
        throw json.data;
      });
};

function* documentAuthorizeCreateFlow(action){
    try{
        const { token , creditId } = action;
        const message = yield call(documentAuthorizeCreateApi , token , creditId);
        yield put(creditDocumentsRequesting(token, creditId));
        yield put(documentAuthorizeCreateSuccess());
        toastr.success("Documento creado", message);
    }catch (error){
        yield put(documentAuthorizeCreateError(error));
        showError(error);
    }
}

const documentSendMailSignatureAPI = (token, creditId) => {
    return fetch(`${baseUrl}/send/mail/signature/${creditId}`, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
      .then(response => response.json())
      .then(json => {
        if (json.code === 400)
            throw [json.data];
        if (json.code === 422)
            throw json.data;
        if (json.code === 200)
            return json.data.data;
        throw json.data;
      })
      .catch((error) => {
        throw error;
      });
};


function* documentSendMailSignatureFlow(action){
    try{
        const { token , creditId } = action;
        const message = yield call(documentSendMailSignatureAPI , token , creditId);
        yield put(creditDocumentsRequesting(token, creditId));
        yield put(documentSendMailSignatureSuccess());
        toastr.success("Enviado", message);
    }catch (error){
        yield put(documentSendMailSignatureError(error));
        showError(error);
    }
}

function* documentWatcher() {
	yield all([
		takeEvery(DOCUMENTS_USER_GET_REQUESTING, documentsUserGetFlow),
		takeEvery(DOCUMENTS_CREATE_REQUESTING, documentsCreateFlow),
		takeEvery(DOCUMENT_FINANCING_PACKAGE_CREATE_REQUESTING, documentFinancingPackageFlow),
		takeEvery(DOCUMENT_CHECK_DIGITAL_SIGNATURE_REQUESTING, documentCheckDigitalSignatureFlow),
		takeEvery(DOCUMENT_AUTHORIZE_CREATE_REQUESTING, documentAuthorizeCreateFlow),
        takeEvery(DOCUMENT_SENT_MAIL_SIGNATURE_REQUESTING, documentSendMailSignatureFlow),
	]);
}

export default documentWatcher;