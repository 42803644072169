import {
	SUPPLEMENTARY_DATA_CREATE_REQUESTING,
	SUPPLEMENTARY_DATA_CREATE_SUCCESS,
	SUPPLEMENTARY_DATA_CREATE_ERROR,
	SUPPLEMENTARY_DATA_UPDATE_REQUESTING,
	SUPPLEMENTARY_DATA_UPDATE_SUCCESS,
	SUPPLEMENTARY_DATA_UPDATE_ERROR,
	SUPPLEMENTARY_DATA_RESET_STATES,
	SUPPLEMENTARY_DATA_FILL_DATA,
	SUPPLEMENTARY_DATA_FILL_DATA_SUCCESS,
	SUPPLEMENTARY_DATA_FILL_DATA_ERROR,
	SUPPLEMENTARY_DATA_CLEAN_VALUES
} from './constants';

const initialState = {
	requesting: false,
	success: false,
	error: "",
	values: {},
	supplementaryData: {}
};

const reducer = (state = initialState, action) => {
	switch (action.type){
		case SUPPLEMENTARY_DATA_CREATE_REQUESTING:
		    return {
		   	    ...state,
		   	    requesting: false,
		   	    success: false,
		   	    error: ""
		    };
		case SUPPLEMENTARY_DATA_CREATE_SUCCESS:
		    return {
		    	...state,
		    	requesting: false,
		    	success: true,
		    	error: "",
		    	supplementaryData: action.supplementaryData
		    };  
		case SUPPLEMENTARY_DATA_CREATE_ERROR:
		    return {
		    	...state,
		    	requesting: false,
		    	success: false,
		    	error: action.error
		    };
		case SUPPLEMENTARY_DATA_UPDATE_REQUESTING:
		    return {
		   	    ...state,
		   	    requesting: true,
		   	    success: false,
		   	    error: ""
		    };
		case SUPPLEMENTARY_DATA_UPDATE_SUCCESS:
		    return {
		    	...state,
		    	requesting: false,
		    	success: true,
		    	error: "",
		    	supplementaryData: action.supplementaryData
		    };  
		case SUPPLEMENTARY_DATA_UPDATE_ERROR:
		    return {
		    	...state,
		    	requesting: false,
		    	success: false,
		    	error: action.error
		    };    
		case SUPPLEMENTARY_DATA_RESET_STATES:
		    return {
		    	...state,
                requesting: false,
                success: false,
                error: ""
		    }; 
		case SUPPLEMENTARY_DATA_FILL_DATA:
		    return {
		    	...state,
		    	requesting: false,
		    	success: false,
		    	error: ""
		    };     
		case SUPPLEMENTARY_DATA_FILL_DATA_SUCCESS:
		    return {
		    	...state,
		    	requesting: false,
		    	success: true,
		    	error: "",
		    	supplementaryData: action.supplementaryData
		    }; 
		case SUPPLEMENTARY_DATA_FILL_DATA_ERROR:
		    return {
		    	...state,
		    	requesting: false,
		    	success: false,
		    	error: action.error
		    }; 
		case SUPPLEMENTARY_DATA_CLEAN_VALUES:
		    return {
		    	...state,
		    	requesting: false,
		    	success: false,
		    	error: "",
		    	supplementaryData: {},
		    	values: {}
		    };      
		default: 
		    return state;      
	}
}

export default reducer