import {
	SEND_SMS_REQUESTING,
	SEND_SMS_SUCCESS,
	SEND_SMS_ERROR
} from './constants';

export const sendSmsRequesting = (token, paymentId) => ({
	type: SEND_SMS_REQUESTING,
	paymentId,
	token
});

export const sendSmsSuccess = () => ({
	type: SEND_SMS_SUCCESS
});

export const sendSmsError = (error) => ({
	type: SEND_SMS_ERROR,
	error
});