import {call, put, all, takeEvery} from 'redux-saga/effects';
import {ErrorComponent} from "../../commons/errors/errors";
import {toastr} from "react-redux-toastr";
import React from "react";
import {Redirect} from "react-router-dom";
import {reset} from "redux-form";
import {
	supplementaryDataCreateSuccess,
	supplementaryDataCreateError,
	supplementaryDataResetStates,
	supplementaryDataUpdateSuccess,
	supplementaryDataUpdateError,
	supplementaryDataFillDataSuccess,
	supplementaryDataFillDataError
} from './actions';

import {
	SUPPLEMENTARY_DATA_CREATE_REQUESTING,
	SUPPLEMENTARY_DATA_UPDATE_REQUESTING,
	SUPPLEMENTARY_DATA_FILL_DATA
} from './constants';

const baseUrl = `${process.env.REACT_APP_API_URL}/supplementaryData`;

const showError = (error) => {
    let toastOptions = {
        component: (
            <ErrorComponent message={error}/>
        )
    };
    toastr.error("Error", toastOptions);
};

const supplementaryDataCreateApi = (token, values, client) => {
	let body = {
		'resource_provider_from': values.recursosProvienenDe,
		'public_knowledge_person': values.personaDeConocimientoPublico === undefined ? false : values.personaDeConocimientoPublico,
		'contract_with_state': values.contrataConElEstado === undefined ? false : values.contrataConElEstado,
		'charge_with_state': values.cargoConElEstado === undefined ? false : values.cargoConElEstado,
		'city': values.ciudad,
		'client_id': client
	};

	return fetch(`${baseUrl}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`
		},
		body: JSON.stringify(body)
	})
	.then(response => response.json())
	.then(json => {
		if (json.code === 422)
			throw json.data;
		if (json.code === 400)
			throw [json.data];
		if (json.code === 200)
			return json.data.data;
		if (!json.code)
			return json.data;
		throw json.data;
	}).catch((error) => {
		throw error;
	});

};


const supplementaryDataUpdateApi = (token, values, client, id) => {
	let body = {
		'resource_provider_from': values.recursosProvienenDe,
		'public_knowledge_person': values.personaDeConocimientoPublico === undefined ? false : values.personaDeConocimientoPublico,
		'contract_with_state': values.contrataConElEstado === undefined ? false : values.contrataConElEstado,
		'charge_with_state': values.cargoConElEstado === undefined ? false : values.cargoConElEstado,
		'city': values.ciudad,
		'client_id': client,
	};

	return fetch(`${baseUrl}/${id}`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`
		},
		body: JSON.stringify(body)
	})
	.then(response => response.json())
	.then(json => {
		if (json.code === 422)
			throw json.data;
		if (json.code === 400)
			throw [json.data];
		if (json.code === 200)
			return json.data.data;
		if (!json.code)
			return json.data;
		throw json.data;
	}).catch((error) => {
		throw error;
	});

};

function* supplementaryDataCreateFlow(action){
	try{
		const {token, values, client} = action;
		const supplementaryData = yield call(supplementaryDataCreateApi, token, values, client);
		yield put(supplementaryDataResetStates());
		yield put(supplementaryDataCreateSuccess(supplementaryData));
		toastr.success("Registrado", "Información personal registrada exitosamente.");
	}catch (error) {
		yield put(supplementaryDataCreateError(error));
		showError(error);
	}
}

function* supplementaryDataUpdateFlow(action){
	try{
		const {token, values, client, id} = action;
		const supplementaryData = yield call(supplementaryDataUpdateApi, token, values, client, id);
		yield put(supplementaryDataResetStates());
		//yield put(supplementaryDataUpdateSuccess(supplementaryData));
		toastr.success("Actualizado", "Información personal actualizada exitosamente.");
	}catch (error) {
		yield put(supplementaryDataUpdateError(error));
		showError(error);
	}
}


function* supplementaryDataFillDataFlow(action){
	try{
		const { supplementaryData } = action;
		const supplementaryDataResult = {
			id: supplementaryData.id,
			recursosProvienenDe: supplementaryData.recursos_provienen_de,
			personaDeConocimientoPublico: supplementaryData.persona_de_conocimiento_publico,
			cargoConElEstado: supplementaryData.cargo_con_el_estado,
			contrataConElEstado: supplementaryData.contrata_con_el_estado,
			haceTransaccionesConMe: supplementaryData.hace_transacciones_con_me,
			tieneProductso: supplementaryData.tiene_productos,
			cargo: supplementaryData.cargo,
			entidiad: supplementaryData.entity,
			identificacion: supplementaryData.identificacion,
			monto: supplementaryData.monto,
			pais: supplementaryData.pais,
			ciudad: supplementaryData.ciudad,
			moneda: supplementaryData.moneda
		};
		yield put(supplementaryDataFillDataSuccess(supplementaryDataResult));
	}catch (error){
		yield put(supplementaryDataFillDataError(error));
	}
}


function* supplementaryDataWatcher(){
	yield all([
		takeEvery(SUPPLEMENTARY_DATA_CREATE_REQUESTING, supplementaryDataCreateFlow),
		takeEvery(SUPPLEMENTARY_DATA_UPDATE_REQUESTING, supplementaryDataUpdateFlow),
		takeEvery(SUPPLEMENTARY_DATA_FILL_DATA, supplementaryDataFillDataFlow)
	]);
}

export default supplementaryDataWatcher;