/*!
=========================================================
* Developer DigitalParchapp V.1
=========================================================
*/
/*!
=========================================================
* Developer DigitalParchapp V.1
=========================================================
*/
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/core/SvgIcon/SvgIcon";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import notificationsStyle from "./../../../assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
import Slide from "@material-ui/core/Slide";
// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
// core components
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
// Integration
import { customInputText } from "../../../components/inputs/typeText";
import { connect } from "react-redux";
import { Form, reduxForm, Field } from "redux-form";
import { configurationUpdateRequesting } from "../../../redux/configuration/actions";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

class AnnualRate extends React.Component {

	handleSubmit = (values) => {
		const {
			client: { token },
			configurationUpdateRequesting
		} = this.props;
		configurationUpdateRequesting(token, values);
	};

	render() {
		const {
			classes,
			open,
			controlModal,
			handleSubmit
		} = this.props;
		return <Dialog
			classes={{
				root: classes.center + " " + classes.modalRoot,
				paper: classes.modal
			}}
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => controlModal("editModal")}
			aria-labelledby="classic-modal-slide-title"
			aria-describedby="classic-modal-slide-description"
		>
			<CardHeader color="primary" icon>
				<CardIcon color="primary">
					<MailOutline/>
				</CardIcon>
			</CardHeader>

			<DialogTitle
				id="classic-modal-slide-title"
				disableTypography
				className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key="close"
					aria-label="Close"
					color="transparent"
					onClick={() => controlModal("editModal")}
				>
					<Close className={classes.modalClose}/>
				</Button>
				<h4 className={classes.modalTitle}>Editar configuración</h4>
			</DialogTitle>
			<DialogContent
				id="classic-modal-slide-description"
				className={classes.modalBody}>
				<Form onSubmit={handleSubmit(this.handleSubmit)}>
					<Field id={"valor"} name={"valor"} type={"text"} label={"Valor *"} autoComplete={"off"}
					       component={customInputText}/>
					<br/>
					<div className={classes.formCategory}>
						<small>*</small>
						Campos requeridos
					</div>
					<DialogActions className={classes.modalFooter}>
						<Button onClick={() => controlModal("editModal")} color="warning">
							Cancelar
						</Button>
						<Button color="primary" className={classes.registerButton} type={"submit"}>
							Aceptar
						</Button>
					</DialogActions>
				</Form>
			</DialogContent>
		</Dialog>;

	}
}

AnnualRate.propTypes = {
	classes: PropTypes.object
};

const formed = reduxForm({
	form: "editConf",
	enableReinitialize: true
})(withStyles(notificationsStyle)(AnnualRate));

const mapStateToProps = state => ({
	client: state.client,
	configuration: state.configuration,
	initialValues: state.configuration.configuration
});

const connected = connect(mapStateToProps, {
	configurationUpdateRequesting
})(formed);

export default connected;