// /*!// =========================================================
// * Developer DigitalParchapp V.1
// =========================================================// */
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/core/SvgIcon/SvgIcon";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import notificationsStyle from "./../../../assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
import Slide from "@material-ui/core/Slide";
// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
// core components
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

class DateAdviser extends React.Component {

	render() {
		const {
			classes,
			open,
			controlModal,
			agent
		} = this.props;
		return <Dialog
			classes={{
				root: classes.center + " " + classes.modalRoot,
				paper: classes.modal
			}}
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => controlModal("agentModal")}
			aria-labelledby="classic-modal-slide-title"
			aria-describedby="classic-modal-slide-description"
		>
			<CardHeader color="primary" icon>
				<CardIcon color="primary">
					<MailOutline/>
				</CardIcon>
			</CardHeader>

			<DialogTitle
				id="classic-modal-slide-title"
				disableTypography
				className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key="close"
					aria-label="Close"
					color="transparent"
					onClick={() => controlModal("agentModal")}
				>
					<Close className={classes.modalClose}/>
				</Button>
				<h4 className={classes.modalTitle}>Datos del asesor</h4>
			</DialogTitle>
			<DialogContent
				id="classic-modal-slide-description"
				className={classes.modalBody}>
				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody}>

					<div className="Conteiner-table">
						<table className="table-date">
							<p>Datos registrados</p>
							<tbody>
							<tr>
								<th>Nombre</th>
								<td>{agent.nombre}</td>
							</tr>
							<tr>
								<th>Tipo de identificación</th>
								<td>{agent.tipo_identificacion.nombre}</td>
							</tr>
							<tr>
								<th>Nit o cedula</th>
								<td>{agent.identificacion}</td>
							</tr>
							<tr>
								<th>Dirección</th>
								<td>{agent.direccion ? agent.direccion : "Sin dirección"}</td>
							</tr>
							<tr>
								<th>Ciudad</th>
								<td>{agent.ciudad.nombre}</td>
							</tr>
							<tr>
								<th>Telefono</th>
								<td>{agent.telefono}</td>
							</tr>
							<tr>
								<th>Correo electronico</th>
								<td>{agent.correo}</td>
							</tr>
							</tbody>
						</table>
					</div>
					<DialogActions className={classes.modalFooter}>
						<Button
							onClick={() => controlModal("agentModal")}
							color="warning"
						>
							Cerrar
						</Button>
					</DialogActions>
				</DialogContent>
			</DialogContent>
		</Dialog>;
	}
}

DateAdviser.propTypes = {
	classes: PropTypes.object
};

export default withStyles(notificationsStyle)(DateAdviser);