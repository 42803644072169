/*!
=========================================================
* Developer DigitalParchapp V.1
=========================================================
*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.jsx";
import LoginPagefrom from "./views/Pages/LoginPage";
import "assets/scss/material-dashboard-pro-react.scss?v=1.7.0";

import { applyMiddleware, createStore, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { IndexSagas } from "./redux/index-sagas";
import IndexReducer from "./redux/index-reducer";
import ReduxToastr from "react-redux-toastr";
import Routes from "./commons/routes/routes";
import { Provider } from "react-redux";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import { IntlProvider } from "react-intl";

const composeSetup =
	process.env.NODE_ENV !== "production" &&
	typeof window === "object" &&
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
	IndexReducer,
	composeSetup(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(IndexSagas);

const hist = createBrowserHistory();

if (window.location.pathname.split("/")[1] === "verify") {
	console.log(window.location.pathname.split("/")[2]);
	// return <Redirect to={"/"}/>;
}

ReactDOM.render(
	<IntlProvider locale={"es-CO"}>
		<Provider store={store}>
			<Router history={hist}>
				<div className="App">
					<ReduxToastr
						timeOut={10000}
						newestOnTop={false}
						preventDuplicates
						position="top-right"
						transitionIn="bounceInDown"
						transitionOut="bounceOutUp"
						progressBar
						closeOnToastrClick
					/>
					<Routes/>
					<Switch>
						<Route exact path="/" component={LoginPagefrom}/>
						<Route path="/admin" component={AdminLayout}/>
					</Switch>
				</div>
			</Router>
		</Provider>
	</IntlProvider>,
	document.getElementById("root")
);
