export const LEGAL_PERSON_CREATE_REQUESTING = 'LEGAL_PERSON_CREATE_REQUESTING';
export const LEGAL_PERSON_CREATE_SUCCESS = 'LEGAL_PERSON_CREATE_SUCCESS';
export const LEGAL_PERSON_CREATE_ERROR = 'LEGAL_PERSON_CREATE_ERROR';
export const LEGAL_PERSON_UPDATE_REQUESTING = 'LEGAL_PERSON_UPDATE_REQUESTING';
export const LEGAL_PERSON_UPDATE_SUCCESS = 'LEGAL_PERSON_UPDATE_SUCCESS';
export const LEGAL_PERSON_UPDATE_ERROR = 'LEGAL_PERSON_UPDATE_ERROR';
export const LEGAL_PERSON_RESET_STATES = 'LEGAL_PERSON_RESET_STATES';
export const LEGAL_PERSON_FILL_DATA = "LEGAL_PERSON_FILL_DATA";
export const LEGAL_PERSON_FILL_DATA_SUCCESS = "LEGAL_PERSON_FILL_DATA_SUCCESS";
export const LEGAL_PERSON_FILL_DATA_ERROR = "LEGAL_PERSON_FILL_DATA_ERROR";
export const LEGAL_PERSON_CLEAN_VALUES = "LEGAL_PERSON_CLEAN_VALUES"; 