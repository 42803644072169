import {
  PAYMENT_PLAN_CONFIRMED_PAYMENT_ERROR,
  PAYMENT_PLAN_CONFIRMED_PAYMENT_REQUESTING,
  PAYMENT_PLAN_CONFIRMED_PAYMENT_SUCCESS,
  PAYMENT_PLAN_UPDATE_ERROR,
  PAYMENT_PLAN_UPDATE_REQUESTING,
  PAYMENT_PLAN_UPDATE_SUCCESS
} from "./constants";

export const paymentPlanConfirmedPaymentRequesting = (token, paymentPlan) => ({
  type: PAYMENT_PLAN_CONFIRMED_PAYMENT_REQUESTING,
  token, paymentPlan
});

export const paymentPlanConfirmedPaymentSuccess = () => ({
  type: PAYMENT_PLAN_CONFIRMED_PAYMENT_SUCCESS
});

export const paymentPlanConfirmedPaymentError = (error) => ({
  type: PAYMENT_PLAN_CONFIRMED_PAYMENT_ERROR,
  error
});

export const paymentPlanUpdateRequesting = (token, paymentPlan, values) => ({
  type: PAYMENT_PLAN_UPDATE_REQUESTING,
  token, paymentPlan, values
});

export const paymentPlanUpdateSuccess = () => ({
  type: PAYMENT_PLAN_UPDATE_SUCCESS,
});

export const paymentPlanUpdateError = (error) => ({
  type: PAYMENT_PLAN_UPDATE_ERROR,
  error
});