// /*!// =========================================================
// * Developer DigitalParchapp V.1
// =========================================================// */
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/core/SvgIcon/SvgIcon";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import notificationsStyle from "./../../../assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
import Slide from "@material-ui/core/Slide";
// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
// core components
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
// Integration
import { userCreateRequesting } from "../../../redux/user/actions";
import { connect } from "react-redux";
import { Form, reduxForm, Field } from "redux-form";
import ImagesInput from "../../../components/inputs/images";
import DocumentsInput from "../../../components/inputs/documents";
import { customInputText } from "../../../components/inputs/typeText";
import { customInputSelect } from "../../../components/inputs/typeSelect";
import { citiesGetRequesting } from "../../../redux/city/actions";
import { identificationTypesGetRequesting } from "../../../redux/identificationType/actions";
import { documentTypesGetRequesting } from "../../../redux/documentType/actions";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

const customInputPassword = ({ input, label, id, name, classes, meta: { touched, error }, ...rest }) => {
	return (
		<CustomInput
			{...input}
			{...rest}
			labelText={label}
			id={id}
			formControlProps={{
				fullWidth: true
			}}
			inputProps={{
				onChange: input.onChange,
				type: "password",
				autoComplete: "off"
			}}
		/>
	);
};

class AddAdviser extends React.Component {

	constructor(props) {
		super(props);
		const {
			citiesGetRequesting,
			identificationTypesGetRequesting,
			documentTypesGetRequesting
		} = this.props;
		citiesGetRequesting();
		identificationTypesGetRequesting();
		documentTypesGetRequesting();
	};

	handleSubmit = (values) => {
		const {
			client: { token },
			userCreateRequesting
		} = this.props;
		userCreateRequesting(token, values);
	};

	render() {
		const {
			classes,
			open,
			controlModal,
			handleSubmit,
			city: { cities },
			identificationType: { identificationTypes },
			documentType: { documentTypes }
		} = this.props;
		return <Dialog
			classes={{
				root: classes.center + " " + classes.modalRoot,
				paper: classes.modal
			}}
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => controlModal("addModal")}
			aria-labelledby="classic-modal-slide-title"
			aria-describedby="classic-modal-slide-description"
		>
			<CardHeader color="primary" icon>
				<CardIcon color="primary">
					<MailOutline/>
				</CardIcon>
			</CardHeader>

			<DialogTitle
				id="classic-modal-slide-title"
				disableTypography
				className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key="controlModal"
					aria-label="Close"
					color="transparent"
					onClick={() => controlModal("addModal")}
				>
					<Close className={classes.modalClose}/>
				</Button>
				<h4 className={classes.modalTitle}>Agregar Asesor</h4>
			</DialogTitle>
			<DialogContent
				id="classic-modal-slide-description"
				className={classes.modalBody}>
				<Form onSubmit={handleSubmit(this.handleSubmit)}>
					<p>Foto/Logo </p>
					<Field name={"photos"} maxFiles={1} className={"filepond"} component={ImagesInput}/>
					<Field id={"nombre"} name={"nombre"} type={"text"} label={"Nombre *"} autoComplete={"off"}
					       component={customInputText}/>
					{identificationTypes.length > 0 && (
						<Field id={"tipo_identificacion"} name={"tipo_identificacion"} type={"select"}
						       label={"Tipo de identificación *"}
						       autoComplete={"on"} component={customInputSelect} classes={classes}
						       data={identificationTypes}/>
					)}
					<Field id={"identificacion"} name={"identificacion"} type={"number"} label={"Identificación *"}
					       autoComplete={"off"}
					       component={customInputText}/>
					<Field id={"correo"} name={"correo"} type={"email"} label={"Correo *"} autoComplete={"off"}
					       component={customInputText}/>
					<Field id={"contraseña"} name={"contraseña"} label={"Contraseña *"} autoComplete={"off"}
					       component={customInputPassword}/>
					<Field id={"telefono"} name={"telefono"} type={"number"} label={"Telefono *"} autoComplete={"off"}
					       component={customInputText}/>
					{cities.length > 0 && (
						<Field id={"ciudad"} name={"ciudad"} type={"select"} label={"Ciudad *"}
						       autoComplete={"on"} component={customInputSelect} classes={classes} data={cities}/>
					)}
					<Field id={"direccion"} name={"direccion"} type={"number"} label={"Dirección"} autoComplete={"off"}
					       component={customInputText}/>
					<p>Documentos</p>
					{documentTypes.length > 0 && (
						<div className="Conteiner-table">
							<table className="table-pyments-page-price3">
								<tbody>
								{documentTypes.map((documentType, index) => {
									if (documentType.nombre === "Cédula de identificación") {
										return (
											<tr key={index}>
												<td>{documentType.nombre}{documentType.requerido ? " *" : ""}</td>
												<td>
													<Field name={`document_${documentType.nombre}`} maxFiles={1}
													       className={"filepond"}
													       component={DocumentsInput}
													       label={"<span class=\"filepond--label-action\">Subir</span>"}/>
												</td>
											</tr>
										);
									}
								})}
								</tbody>
							</table>
						</div>
					)}
					<br/>
					<div className={classes.formCategory}>
						<small>*</small>
						Campos requeridos
					</div>
					<DialogActions className={classes.modalFooter}>
						<Button onClick={() => controlModal("addModal")} color="warning">
							Cancelar
						</Button>
						<Button color="primary" className={classes.registerButton} type={"submit"}>
							Aceptar
						</Button>
					</DialogActions>
				</Form>
			</DialogContent>
		</Dialog>;
	}
}

AddAdviser.propTypes = {
	classes: PropTypes.object
};

const mapStateToProps = state => ({
	client: state.client,
	user: state.user,
	city: state.city,
	identificationType: state.identificationType,
	documentType: state.documentType
});

const connected = connect(mapStateToProps, {
	userCreateRequesting,
	citiesGetRequesting,
	identificationTypesGetRequesting,
	documentTypesGetRequesting
})(withStyles(notificationsStyle)(AddAdviser));

const formed = reduxForm({
	form: "addUser"
})(connected);

export default formed;