import { AUDITS_CLASS_GET_ERROR, AUDITS_CLASS_GET_REQUESTING, AUDITS_CLASS_GET_SUCCESS } from "./constants";

const initialState = {
	requesting: false,
	success: false,
	error: "",
	audits: []
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case AUDITS_CLASS_GET_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: "",
				audits: [],
			};
		case AUDITS_CLASS_GET_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				audits: action.audits
			};
		case AUDITS_CLASS_GET_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error
			};
		default:
			return state;
	}
};

export default reducer;

